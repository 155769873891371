.Container {
  background-color: #f5f3f3;
  color: #817b7b;
  width: 100%;
  display: flex;
  padding-bottom: 20px;
  height: auto;
}

/* styles.css */

.padding-container {
  /* padding-left: 21px;
  padding-bottom: 10px; */
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

/* styles.css */

.center-margin-container {
  text-align: center;
  margin: 15px;
}
/* styles.css */

.flex-align-center-container {
  display: flex;
  align-items: center;
}

/* styles.css */

/* styles.css */

.relative-overflow-container {
  position: relative;
  overflow: hidden;
}

.center-margin-vertical-container {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.LeftContainer {
  background-color: #f5f3f3;
  position: relative;
  margin-left: 1%;
  width: 25%;
  height: auto;
}

.targetbox {
  display: block;
  border: 1px solid rgb(243, 241, 241);
  padding-bottom: 20px;
  padding-top: 5px;
}
.container-fluid {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);

  padding-top: 5px;
}
.targetbox.openbox {
  display: none;
}
.clientlabel {
  margin-top: 15px;
}
.IoAddCircleOutline {
  font-size: 20px;
  color: black;
  cursor: pointer;
}

.prescreenLayout {
  display: flex;
  justify-content: end;
  padding-right: 25px !important;
  grid-gap: 12px;
}
.containerContentSize {
  margin: 0px;
  padding: 9px;
}

.databoxTarget .RightContainer {
  background-color: white;
  color: #817b7b;
  width: 100%;
  margin-top: 15px;
  height: auto;
  padding-bottom: 20px;
}

.RightChild {
  background-color: white;
  color: #817b7b;
  /* padding: 15px; */
  padding-left: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
}
.LeftChild {
  padding-top: 15px;
  background-color: #f5f3f3;
  color: #817b7b;
}
.saveclsebtnA {
  margin-top: 10px;
  margin-bottom: 10px;
}
.Project3 {
  display: flex;
  text-align: center;
  background-color: #f5f3f3;
  padding-left: 320px;
  padding-top: 30px;
  font-size: 13px;
}
.projectarrow {
  margin-left: 88%;
}
.Project3 {
  padding-top: 20px;
}
.zipCode {
  background-color: #1a1a1a;
  text-align: center;
  padding-top: 4px;

  font-size: 14px;
  color: white;
  margin-top: 16px;
  border-radius: 4px;
  border: none;
  height: 30px;
  line-height: 20px;
  padding: 5px 10px;
}

.formlabel {
  width: 100%;
  font-size: 12px;
  color: #555;
  margin-bottom: 5px;
  line-height: 1;
}
.redcss {
  font-size: 12px;
  color: #555;
  width: 100%;
}

.formtextviewzone {
  width: 100%;
  height: 28px;
  padding: 3px 10px;
  border: 1px solid #aaa;
  color: #999;
  border-radius: 1px;
  margin-bottom: 1px;
  font-size: 14px;
  font-family: "Ubuntu", sans-serif !important;
}
.formtextviewzone.open {
  height: 78px;
}
.eyeopen {
  display: block;
}
.eyeopen.open {
  display: none;
}
.eyeclose {
  display: block;
}
.eyeclose.open {
  display: none;
}

.eyeopenl {
  display: block;
}
.eyeopenl.open {
  display: none;
}
.eyeclosel {
  display: block;
}
.eyeclosel.open {
  display: none;
}

.eye-icon {
  position: absolute;
  top: 40px;
  bottom: 14px;
  right: 10px;
  cursor: pointer;
  width: 30px;
  color: #140c0c;
}
.Live {
  background: linear-gradient(to right, #34b140, #55bf35);
  color: white;
  height: 20px;
  width: 40px;
  font-size: 10px;
  text-align: center;
  padding-top: 2px;
  border-radius: 19px;
  margin-top: 2px;
}
.ProjectDetail {
  display: flex;
  font-size: 10px;
}
.P_comman {
  margin-left: 20px;
}
.CLoseButton {
  background-color: #929594;
  border-radius: 3px;
  border: none;
  margin-left: 63%;
  font-size: 11px;
  color: white;
}
.line-1 {
  height: 1px;
  background-color: rgb(241, 241, 241);
  margin-top: 10px;
}
.ProjectSetting {
  background-color: #f5f3f3;
  margin-top: 10px;
  height: 30px;
  padding: 5px 10px;
  position: relative;
}
.ptoogle {
  display: flex;
  /* padding-top: 10px; */
  font-size: 10px;
  /* padding-left: 10px; */
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  height: auto;
  color: #828283;
  /* padding-bottom: 20px; */
  margin: 1px;
}
.redhearing {
  display: grid;
  font-size: 10px;
  height: 50px;
  width: max-content;
}

.screen {
  display: grid;
  height: 50px;
  width: max-content;
  margin-left: 30px;
}
.reserch {
  display: grid;
  height: 50px;
  width: max-content;
  margin-left: 30px;
}
.soft {
  display: grid;
  margin-left: 20px;
  height: 50px;
  width: max-content;
  margin-left: 45px;
}
.tooglecenter {
  text-align: left;
}

#customrow {
  margin-left: 0.3%;
}
.ussave {
  border-radius: 30px;
  border: none;
  background-color: #1a1a1a;
  color: white;
  margin-bottom: 2px;
  margin-left: 80%;
}
.saveptag {
  margin-left: -5px;
  margin-left: 2px;
  margin-top: -3px;
}
.uslive {
  background: linear-gradient(to right, #34b140, #55bf35);
  border-radius: 30px;
  border: none;
  color: white;
  margin-bottom: 2px;
  margin-left: 10px;
}
.usgeninput {
  height: auto;
  padding-bottom: 10px;
  border: 1px solid #f1f1f1;
}
.adddef_sected {
  height: 50px;
  border: 1px solid black;
  font-size: 12px;
  padding-top: 15px;
  margin-top: 10px;
}
.adddef_empty {
  height: 50px;
  background-color: whitesmoke;
  font-size: 12px;
  padding-top: 15px;
  margin-top: 10px;
}
.surveyinputParents {
  border: 1px solid red;
  font-size: 10px;
  display: flex;
  flex-direction: column;
}

.formlabelcss {
  width: 100%;
  font-size: 10px;
  color: rgb(177, 174, 174);
  margin-bottom: 5px;
  line-height: 1;
}

.surveyinput {
  margin-left: 10px;
}

.surveyinputSoft {
  margin-left: 10px;
}
.surveyinput1 {
  margin-left: 10px;
}
.clientest {
  display: flex;

  margin-left: 10px;
}
.clentInput {
  height: 22px;
  font-size: 10px;
}
.clientInput2 {
  margin-left: 150px;
  font-size: 10px;
}
.targetcontainer {
  height: auto;
  border: 1px solid #f1f1f1;
  padding-bottom: 10px;
}
.plus {
  margin-top: 5px;
}
.plussss {
  position: absolute;
  margin-top: 25px;
  margin-left: 4px;
}

#clentInput {
  margin-left: 21px;
}
.clientP {
  border: 1px solid red;
  height: auto;
  display: flex;
  flex-direction: row;
  width: auto;
}
.clientP > p {
  height: 0px;
  display: inline-block;
  font-size: x-small;

  border: 1px solid yellow;
}
.clientPOne {
  float: left;
  margin-top: 25px;
  border: 1px solid green;
}
.clientPTwo {
  margin-top: 25px;
  border: 1px solid blue;
  float: right;
}

.Targetinput {
  height: 18px;
  width: 150px;
  margin-left: 20px;
  margin-top: 1px;
}
.new {
  font-size: 10px;
  border-style: none;
  border: 1px solid black;
  width: 50px;
  background-color: white;
}
.tClose {
  text-align: center;
  font-size: 10px;
  /* margin-left: 10px; */
  border-style: none;
  border: 1px solid black;
  width: 50px;
  background-color: white;
}
.toggleleft {
  margin-left: 10px;
}
.tClosesave {
  width: 50px;
  border-radius: 30px;
  border: none;
  background-color: #1a1a1a;
  color: white;
  margin-left: 10px;
  margin-bottom: 2px;
  margin-right: 10px;
}
.ageClass {
  display: flex;
  margin-top: 10px;
}
.agec {
  display: grid;
  margin-left: 10px;
  font-size: 10px;
  margin-bottom: 12px;
  margin-top: 2px;
}
.agech {
  display: grid;
  margin-left: 10px;
  font-size: 10px;
  width: 220px;
  margin-top: 10px;
}
.adddefinition {
  display: flex;
  font-size: 10px;
}
.boxadd {
  height: 50px;
  border: 1px solid rgb(243, 241, 241);
  background-color: #f6f6f6;
  margin-left: 20px;
  width: 200px;
  text-align: center;
  padding-top: 15px;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.boxadd1 {
  height: 50px;
  border: 1px solid rgb(4, 4, 4);
  margin-left: 10px;
  width: 200px;
  text-align: center;
  padding-top: 15px;
  margin-bottom: 10px;
}

.adddefinitionbox {
  height: auto;
  border: 1px solid #e6e6e6;
  padding-bottom: 10px;
}
.colbtn {
  /* border: 1px solid red; */
  margin-top: 25px;
}
#customsave {
  margin-right: 1%;
  margin-left: 77%;
}
#customsaveagain {
  margin-left: 79%;
  margin-right: 1%;
}
.sl_btn {
  margin-top: 0px;
  padding-right: 0px;
}
.screening_errormsg {
  display: block;
  font-size: 10px;
  line-height: 1;
  width: 100%;
  color: #fff;
  /* margin: 0px 5%; */
  padding: 2px;
  top: auto;
  /* bottom: -6px; */
  bottom: 5px;
  background-color: #900;
  left: 0px;
  /* position: absolute; */
  position: relative;
}
.filename_row {
  margin-top: 10px;
}
/******************************** Switch toggle ********************/
/* .pswtchbtn .switch.btn-outline-success,
.pswtchbtn .switch .btn.btn-outline-success.btn:active {
  border-radius: 20px !important;
  color: #1a1a1a !important;
  height: 24px;
  border-color: #1a1a1a;
  box-shadow: none;
}
.pswtchbtn .switch .btn.btn-outline-success.btn,
.pswtchbtn .switch .btn.btn-outline-success.btn:hover,
.pswtchbtn .switch .btn.btn-outline-success.btn:active {
  border: none;
}

.pswtchbtn .switch .btn.btn-outline-danger:hover,
.pswtchbtn .switch .btn.btn-outline-danger,
.pswtchbtn .switch .btn.btn-outline-danger:active {
  border: none;
}
.pswtchbtn .switch .switch-handle {
  border-radius: 50px !important;
  border: 5px solid #ffffff;
  padding-left: 0px !important;
  width: 22px;
  position: relative;
  left: 12px;
  top: 0px;
}
.pswtchbtn .switch.btn-outline-danger .switch-handle {
  background-color: #555555;
  border: 3px solid #fff !important;
  position: relative;
  left: 12px;
}

.pswtchbtn .switch.btn-outline-success .switch-handle {
  background-color: #1a1a1a;
  border: 3px solid #fff !important;
  position: relative;
  right: 12px;
  left: auto;
}
.pcolorbtn .switch.btn-outline-danger .switch-handle {
  background-color: #f10707;
}
.pcolorbtn .switch.btn-outline-success .switch-handle {
  background-color: #7ff204;
}

.pswtchbtn .btn-outline-success:hover,
.pswtchbtn .btn-outline-danger:hover {
  background-color: #fff;
}
.pswtchbtn .switch.btn-outline-success:hover .switch-handle {
  background-color: #1a1a1a;
}
.pswtchbtn .switch.btn-outline-danger:hover .switch-handle {
  background-color: #999;
}
.pswtchbtn .switch-on.btn-xs {
  padding-right: 25px !important;
  font-weight: normal !important;
  line-height: 1;
  font-size: 12px;
  letter-spacing: 0.5px;
}

.pswtchbtn .switch-off.btn-xs {
  padding-left: 25px !important;
  font-weight: normal !important;
  line-height: 1;
  font-size: 12px;
  letter-spacing: 0.5px;
} */

.rankPainA {
  width: 60%;
  float: left;
  text-align: right;
  padding-right: 20px;
  margin-top: -2px;
}
.liveBtnA {
  float: right;
  border-radius: 30px;
  width: 50px;
  height: 20px;
  border: none;
  background: linear-gradient(to right, #34b140, #55bf35);
  color: white;
  margin-right: 10px;
  font-size: smaller;
  margin-top: 3px;
  margin-right: 15px;
}
.PSaveBtnA {
  border-radius: 30px;
  width: 60px;
  border: none;
  background: black;
  color: white;

  height: 20px;
  margin-top: 3px;
  font-size: smaller;
  padding: auto;
}
#closebuttoncss {
  text-align: center;
  margin-right: -21px;
  margin-bottom: 10px;
}
.ProjectSetting > p {
  width: 40%;
  float: left;
  margin-bottom: 5px;
  margin-left: 0px;
  font-size: small;
}

.TwoContainerHa {
  display: flex;
}
.PleftContainerHa {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-top: 20px;

  width: 26%;
}
.PrightContainerHa {
  display: flex;

  margin-top: 20px;
  margin-right: 0px;
  margin-left: 40px;
  width: 100%;
  justify-content: space-between;
}
.pheaderRowa {
  display: flex;
  flex-direction: row;
}
.PtopHeadera {
  display: flex;
  flex-direction: row;
  width: 35%;
}
.PtopHeadera > p {
  border-bottom: 2px solid black;
  font-weight: 400;
}
.PtopHeadera > button {
  margin-top: 3px;
  text-align: center;
  color: white;
  height: fit-content;
  width: 50px;
  border-radius: 15px;
  margin-left: 20px;
  font-size: 12px;
  font-weight: 400;
  border: none;

  background: linear-gradient(to right, #34b140, #55bf35);
}
.saveclsebtna {
  display: flex;
  padding-right: 30px;
}
.usgenpopinput {
  margin-left: 10px;
  height: 20px;
  width: 30%;
}

.addBackgrondBox {
  border: 1px solid black;
  font-size: 12px;
  padding-top: 15px;
  margin-top: 10px;
  height: 50px;
}

.addDefaultBox {
  height: 50px;
  background-color: whitesmoke;
  font-size: 12px;
  padding-top: 15px;
  margin-top: 10px;
}
.screenswitchbtn {
  margin-top: 0px;
}
.screenswitch_col {
  margin-top: 10px;
}
#myAttachment {
  width: 100%;
}

#uploadMargin {
  margin-right: 0px;
}

#myAttachment .attachmentContent .invoice_fileInfo {
  display: block;
  float: left;
  width: 25%;
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 0px;
}
#myAttachment .attachmentContent .progress {
  width: 55%;
  display: inline-block;
  margin: 0px;
  height: 13px;
  font-size: 10px;
  position: relative;
  top: 2px;
}

@media (max-width: 1064px) {
  .LeftContainer {
    width: 27%;
  }
  .PleftContainerHa {
    width: 28%;
  }
  #plusicon {
    margin-left: -10px;
  }
  .usgenpopinput {
    width: 40%;
  }
}
@media (max-width: 1000px) {
  .LeftContainer {
    width: 30%;
  }
  .PleftContainerHa {
    width: 31%;
  }
  .formlabel {
    padding-top: 2px;
  }
  #ctl {
    padding-top: 12px;
  }
  .usgenpopinput {
    width: 45%;
  }
}
@media (max-width: 960px) {
  .LeftContainer {
    width: 35%;
  }
  .PleftContainerHa {
    width: 36%;
  }
}
@media (max-width: 845px) {
  .LeftContainer {
    width: 40%;
  }
  .PleftContainerHa {
    width: 40%;
  }
}

@media (max-width: 767px) {
  .PleftContainerHa {
    display: none;
  }
  .Container {
    display: flex;
    flex-direction: column;
  }
  .RightContainer {
    width: 99%;
  }
  .LeftContainer {
    width: 90%;
    margin: auto;
  }
  #closebuttoncss {
    margin: auto;
  }
  .soft {
    margin-left: 30px;
    margin-top: 10px;
  }
  .reserch {
    margin-left: 0px;
    margin-top: 10px;
  }
  .colbtn {
    margin-top: 10px;
  }
}

@media (max-width: 642px) {
  .newcloseposition {
    width: 100%;
    float: none;
  }

  #newtooff {
    float: left;
    text-align: left;
  }
  #mediap {
    width: 100%;
  }
  #projecctidmedia {
    height: 63px;
  }
  #newtooff {
    margin-top: 2px;
  }
  .usgenpopinput {
    width: 30%;
  }
}

@media (max-width: 512px) {
  .PtopHeadera {
    width: 100%;
  }
  .usgenpopinput {
    width: 35%;
  }
}

.heightfix {
  max-height: 51px;
}

.setW {
  width: 100px;
}
css-14el2xx-placeholder {
  color: #999 !important;
  grid-area: 1/1/2/3;
  margin-left: 2px;
  margin-right: 2px;
  box-sizing: border-box;
  font-size: 14px !important;
}
