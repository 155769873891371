.navbar.fixed {
  background-color: #1b1b1b;
  position: fixed;
  width: 100%;
  z-index: 999;
}
.header {
  width: 100%;
  height: 65px;
}
.nav_ml {
  margin-left: -50px;
}

.navbar-expand .navbar-nav .nav-link {
  font-family: "Ubuntu";
  font-weight: 500;
  color: #fff !important;
  font-size: 13px;
  padding: 10px 15px;
  letter-spacing: 0.7px;
  padding-right: 10px !important;
  padding-left: 10px !important;
  text-decoration: none !important;
}

.header_samplicity_logo {
  /* border: 1px solid red; */
  height: 50px;
  margin-left: 20px;
  margin-right: 20px;
}

.inputForm {
  background-color: transparent !important;
  border: 1px solid rgb(100, 100, 100) !important;
}

.navbar .form-control,
.navbar .form-control:focus {
  color: #e1e1e1;
}

.inputForm:focus {
  /* border: none !important; */
  color: white;
  letter-spacing: 1px;
  box-shadow: none !important;
  outline-offset: none !important;
}

.badge {
  border: 1px solid red;
  width: 20px;
  padding: 2px;

  /* align-items: center ; */
  height: 15px;
  background-color: red !important;
  border-radius: 50px;
  margin-top: -25px;
  margin-left: -5px;
}

#dropdown-basic {
  /* border: 1px solid red !important; */
  width: 40px;
  height: 30px;
}

#dropdown-basic:focus {
  /* border: 1px solid red !important; */

  box-shadow: none !important;
  outline-offset: none !important;
}

.dropdown {
  /* border: 1px solid red; */
  width: 20px;
}

.dropdown_menu {
  border: 1px solid blue;
  background-color: #1a1a1a;
  width: 100px;
}

/* .notification
{
    float:right;
    width:120px
} */

.notification button {
  float: right;
}

.profileDropdown {
  width: 250px;
  height: 108px;
  padding: 5px 10px;
}

.leftPane {
  width: 55px;
  float: left;
  height: 60px;
}
.rightPane {
  width: 230px;
  padding-left: 10px;
  height: 60px;
}
.rightPane h5 {
  font-size: 16px;
  text-transform: capitalize;
  letter-spacing: normal;
}
.rightPane p {
  font-size: 12px;
  line-height: 1.4;
  margin: 0;
  letter-spacing: normal;
}

.ddFooter {
  width: 100%;
  height: 50px;
  border-top: 1px solid #eee;
  padding: 5px 0 0;
  text-align: right;
  position: relative;
}
.ddFooter .btn-changepwd,
.ddFooter .btn-changepwd:hover {
  border: none;
  margin-right: 10px;
  background-color: #1a1a1a;
  color: #fff;
}
.ddFooter .btn-logout,
.ddFooter .btn-logout:hover {
  border: none;
  background-color: #999;
  color: #fff;
}
.profile {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 2px solid #aaa;
  position: relative;
  cursor: pointer;
}
#photo {
  display: none;
}
.uploadHover {
  background-color: #ffffff89;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  padding: 5px;
  display: none;
}
.profile:hover .uploadHover {
  display: block;
}

/*-----------------sunny----------------*/
.notification {
  display: flex;
  align-items: center;
  justify-content: center;
}
.notification .hamberger {
  display: none;
}
/*-----------------Tab-1024px----------------*/
@media (max-width: 1024px) {
  .navbar-expand .navbar-nav {
    display: flex;
    flex-direction: column !important;
    position: fixed;
    top: 65px;
    right: 0px;
    width: 300px;
    height: 100vh;
    background-color: #1b1b1b;
    padding: 20px 0px 0px 20px;
    display: none;
  }
  .navbar-expand .navbar-nav.open {
    display: block;
  }
  .navbar > .container {
    display: flex;
    flex-wrap: inherit;
    align-items: flex-end !important;
    justify-content: flex-end !important;
  }
  .notification {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .notification .hamberger {
    display: block;
    height: 24px !important;
    width: 24px !important;
    color: #fff;
    margin: 0 8px 0 20px;
    cursor: pointer;
  }
  .css-14er7d7-MuiButtonBase-root-MuiIconButton-root {
    margin-left: 10px !important;
  }
  .dashboard .card-header .row .col:last-child {
    max-width: 180px;
    display: block;
    display: flex;
    justify-content: CENTER;
    align-items: center;
  }
  .dashboard .col.bl1 .form-control {
    padding: 0;
    text-align: right;
    font-size: 12px;
    border: none;
    background-color: transparent;
    width: 150px;
    float: left;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAgAAAAIAAgMAAACJFjxpAAAAA3NCSVQICAjb4U/gAAAACXBIWXMAABvKAAAbygEeqZAXAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAAlQTFRF////AAAAAAAAflGpXQAAAAJ0Uk5TAICbK04YAAAEbElEQVR42u3aya0jMQyEYerCIBTNi+dFoyB40UQ5F8Pw0osWkgXM1B9BHT64uyWLMMYYY4wxxhhjjDHGGGOMMcYYY4wxxhhjjDHGGGOMMcYYY4wxxhhj7L/v9w+0LhU7wESxA5oU7IAfMIIugkVgIlgETQSL4Af8S9BFsAhMBIugiWAR/IAfB/3xPKpYAkAE7TGggAnAEPTnS0nFEoAhaM8BBUwAhKC/vJlWLAEQgvYyoIAJQBD0t8+TiiUAQdDeBhQwAQCC/vGNWrEEAAjax4ACJpCOoH8dVFQsgXQE7WtAARNIRtAPTqsqlkAygnYwoIAJpCLoh0eWFUsgFUE7HFDABBIR9JNz64olkIignQwoYAJpCPrp5UXFEkhD0E4HFDCBJAT94garYgkkIWgXAwqYQAqCfnmNWbEEUhC0ywEFTCABQb+5y65YAgkI2s2AAiYQjqDf/qGhYgmEI2i3AwqYQDCCewLBCGxggGIJBCMYIBCKYIRAKAIbGqBYAqEIhggEIhgjEIjABgcolkAggkECYQhGCYQhsOEBiiUQhmCYQBCCcQJBCGxigGIJBCGYIBCCYIZACAKbGqBYAiEIpggEIJgjEIDAJgcolkAAgkkC7ghmCbgjsOkBiiXgjmCagDOCeQLOCGxhgGIJOCNYIOCKYIWAKwJbGqBYAq4Ilgg4Ilgj4IjAFgcoloAjgkUCbghWCbghsOUBiiXghmCZgBOCdQJOCGxjgGIJOCHYIOCCYIeACwLbGqBYAi4Itgg4INgj4IDANgcoloADgk0C2wh2CWwjsO0BiiWwjWCbwCaCfQKbCMxhgGIJbCJwILCFwIPAFgJzGaBYAlsIXAhsIPAhsIHAnAYolsAGAicCywi8CCwjMLcBiiWwjMCNwCICPwKLCMxxgGIJLCJwJLCEwJPAEgJzHaBYAksIXAksIPAlsIDAnAcolsACAmcC0wi8CUwjMPcBiiUwjcCdwCQCfwKTCCxggGIJTCIIIDCFIILAFAILGaBYAlMIQghMIIghMIHAggYolsAEgiACwwiiCAwjsLABiiUwjCCMwCCCOAKDCCxwgGIJDCIIJDCEIJLAEAILHaBYAkMIQgkMIIglMIDAggcolsAAgmACtwiiCdwisPABiiVwiyCcwA2CeAI3CCxhgGIJ3CBIIHCJIIPAJQJLGaBYApcIUghcIMghcIHAkgYolsAFgiQCpwiyCJwisLQBiiVwiiCNwAmCPAInCCxxgGIJnCBIJHCIIJPAIQJLHaBYAocIUgkcIMglcIDAkgcolsABgmQCXwiyCXwhsPQBiiXwhSCdwAeCfAIfCAwwQLEEPhAACLwhQBB4Q2CQAYol8IYAQuAFAYbACwIDDVAsgRcEIAJPBCgCTwQGG6BYAk8EMAIPBDgCDwQGHKBYAg8EQAIiv1gCIhVLQESxBEQKmIDIL5aASDXwAG3gAQVMgDHGGGOMMcYYY4wxxhhjjDHGGGOMMcYYY4wxxhhjjDHGGGOMMcYYY/9AfwFl5IJ3vCCsmQAAAABJRU5ErkJggg==);
    background-position: 140px 5px;
    background-repeat: no-repeat;
    background-size: 8px;
    padding-right: 15px;
  }
  .dashboard .card-body.bidbooked {
    height: 335px;
    max-height: 335px;
  }
  .dashboard .card-body .bidbooked2 {
    height: auto !important;
  }
  /* .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper{
        top: 132px!important;
        left: auto!important;
        right: 25px!important;
    } */
  /* .css-6hp17o-MuiList-root-MuiMenu-list{
        width: 246px!important;
    } */
}
