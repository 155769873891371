div.table {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.row.tr,
.form.tr {
  display: table-row;
  width: 100%;
}

.row.tr:hover,
.form.tr:hover {
  background-color: #f2f2f2;
}
.row.tr.thead {
  background-color: #666;
}
.row.tr.thead:hover {
  background-color: #666;
}
.row.tr.thead .td,
.col.td {
  vertical-align: middle;
  font-weight: normal;
  width: auto;
}
.row.tr.thead .td {
  color: #fff;
}
.row.tr.thead .td:first-child,
.col.td:first-child {
  width: 30%;
}
.row.tr.thead .td:last-child,
.col.td:last-child {
  text-align: right;
  width: 60px;
}
.col.td {
  display: table-cell;
}
.invoice_errmsg_proj {
  display: block;
  font-size: 8px;
  line-height: 1;
  width: 100%;
  color: #fff;
  margin: 0px;
  padding: 0px !important;
  top: auto;
  position: relative;
  background-color: #900 !important;
  /* margin: 0px 5%; */
  bottom: 9px;
}
