.supply_setupbtn {
  background-color: #1a1a1a;
  text-align: center;
  padding-top: 4px;

  font-size: 14px;
  color: white;

  border-radius: 4px;
  border: none;
  height: 30px;
  line-height: 20px;
  padding: 5px 12px;
  margin-top: 10px;
}

@media (min-width: 992px) {
  .setsupplierModal .modal-lg,
  .modal-xl {
    --bs-modal-width: 1100px;
  }
}
div.col .editsuperror {
  width: 84%;
  margin: 0 8%;
}
.suppliersetup {
  margin: 0px;

  margin-right: 10px;
}
.s_edit_switcbtn {
  text-align: left;
  padding-right: 0px;
}
.supply_country {
  margin-bottom: 5px;
  font-size: 13px;
  padding: 6px 0px 0px 0px;
  font-weight: bolder;
  font-family: "Ubuntu", sans-serif;
}
.supply_theadfont.css-bsph2u-MuiTableCell-root {
  font-family: "Ubuntu", sans-serif;
}
.dropeTablesupply {
  display: none;
}
.dropeTablesupply.open {
  display: block;
}
body {
  font-family: "Ubuntu", sans-serif;
  letter-spacing: 0.5px;
  line-height: 1.6;
  font-weight: 400;
  font-size: 13px;
}

.inline-container {
  display: flex;
  /* align-items: center; */
  justify-content: space-evenly;
}

.inline-container > * {
  margin-right: 10px;
}
.box_width {
  width: 20px;
}

/* Styling the select element */
.variable_select select {
  width: 100%;
  height: 35px;
  padding: 3px 10px;
  border: 1px solid #aaa;
  color: #4c44449d;
  border-radius: 1px;
  margin-bottom: 1px;
  font-size: 14px;
  /* background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23333333' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E"); */
  background-repeat: no-repeat;
  background-position: right 8px center;
}

.variable_select select:focus {
  outline: none;
  border-color: #888;
}

/* Styling the select options */
.variable_select select option {
  font-size: 14px;
  background-color: #fff;
  color: #5f6062;
}

.vtttinput {
  display: flex;
  width: 100%;
  max-width: 85px;
  font-size: 12px;
  border: none;
  border-bottom: 1px solid black;
}

.variable_select__select option[disabled] {
  color: #c1bcbc;
}

.variable_select__select option:not([disabled]):hover {
  background-color: rgb(133, 238, 115);
}
