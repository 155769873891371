
.rdt_TableHead{
    vertical-align: middle; 
    box-sizing: border-box;    
}
.dTable .rdt_Table .rdt_TableHeadRow{
    min-height: 30px;  
    font-size:13px;    
    letter-spacing: 1px;
    line-height: 1.4;
    font-weight: 600;
    color:#555;
    background-color: #f0efef  !important;    
}
.rdt_TableBodyrdt_TableBody{
    border-bottom:1px solid red !important ;
}
.dTable .rdt_Table .rdt_TableRow{
    min-height: 36px;
}
.dTable .rdt_Pagination{
    min-height: 30px;
    background-color: #f0efef !important;   
}

.userprofile{
    border-radius:30px;
    height: 24px;
    width:24px;
}


.btn-outline-success {
    border-radius: 20px !important;
    color: #1a1a1a !important;
    height:24px;
    border-color: rgb(209, 209, 209);
}

.btn-outline-danger {
    border-radius: 20px !important;
    color: #999;
    height:24px;
    border-color: rgb(209, 209, 209);
}
.switch .switch-handle {
    border-radius: 50px !important;
    border: 5px solid #ffffff ;
    padding-left: 0px !important;
    background-color: #999;
    width:22px;
    position:relative;
    left:12px

}
.rdt_TableCell .switch.btn-outline-danger .switch-handle{
    background-color: #999;
    border: 3px solid #fff !important;
    position:relative;
    left:12px
}

.rdt_TableCell .switch.btn-outline-success .switch-handle{
    background-color: #1a1a1a;
    border: 3px solid #fff !important;
    position:relative;
    right:12px;
    left:auto
}

.btn-outline-success:hover, .btn-outline-danger:hover{
    background-color: #fff;
}
.rdt_TableCell .switch.btn-outline-success:hover .switch-handle{
    background-color:#1a1a1a;
}
.rdt_TableCell .switch.btn-outline-danger:hover .switch-handle{
    background-color:#999;
}
.switch-on.btn-xs {
    padding-right: 25px !important;
    font-weight: normal !important;
    line-height: 1;
    font-size:12px;
    letter-spacing: 0.5px;
}

.switch-off.btn-xs {
    padding-left: 25px !important;
    font-weight: normal !important;
    line-height: 1;
    font-size:12px;
    letter-spacing: 0.5px;
}


.actionButton{
    padding:3px;
    cursor: pointer;
}

.actionButton img{
    height:16px;

}
.rdt_Table span.edit img, .rdt_Table span.delete img{
    height:16px;
}






.dTable.bids .rdt_Table .rdt_TableCol:first-child, .dTable.bids .rdt_Table .rdt_TableRow .rdt_TableCell:first-child{
   max-width:80px;
   height:36px;
}

.dTable.bids .rdt_Table .rdt_TableCol:last-child,  .dTable.bids .rdt_Table .rdt_TableRow .rdt_TableCell:last-child{
    max-width:100px
}