body{
    background-color: #f5f3f3;
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 1.6;
    letter-spacing: 1;
    color:#222222;
    overflow-x: hidden;
    overflow-y: scroll;
}

.main.qual_frontend {
    width:100%;
    height: 100vh;
    width:100vw;
    padding-top: 60px;
}

.main.qual_frontend .container{
    background-color: #fff;
    padding: 15px 50px;
    position:relative;

}


.qual_frontend .btn.sbmtbtn{
    background-color: #1a1a1a;
    color: #fff;
    padding: 5px 15px;
    border:1px solid #1a1a1a;
    border-radius:3px;
    margin-top: 8px;;
}

.qual_frontend span.errMessage{
    background-color:#900;
    color:#fff;
    padding:3px 5px;
    margin-left: 8px;
    font-size:11px;
    line-height: 1;
    border-radius:2px;
}


.footer {
    text-align: center;
    /* padding: 5px; */
    background-color: #abbaba;
    color: #000;
  }
  
  /* Add this CSS to your stylesheet */
  footer {
    position: fixed; /* or position: absolute; */
    bottom: 0;
    width: 100%;
    background-color: #abbaba; /* You can change the background color if needed */
    text-align: center;
    /* padding: 10px; */
    padding-bottom: 10px;
    padding-top: 10px;
    font-size: 12px;
    color: #666; /* You can change the text color if needed */
  }