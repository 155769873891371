.Deatail_to_client {
  margin-top: 0px;
  height: 30px;
  /* border: 2px solid red; */
  width: 96%;
  margin-left: 2%;
  display: flex;
}

.tabs {
  margin: 0px 20px 0 0;
  cursor: pointer;
  border: 3px solid transparent;
  border: none;
  padding-bottom: 2px;
  height: 30px;
  font-size: medium;
  text-decoration: none;
  color: black;
}
.SelectHeader {
  display: none;
}
.showingHeader {
  display: block;
}

.active-tabss {
  background: white;
  border-bottom: 3px solid #1a1a1a;
}
@media (max-width: 560px) {
  .showingHeader {
    display: none;
  }
  .SelectHeader {
    display: block;
    width: 100%;
    padding: 3px 10px;
    /* margin-left: 15px; */
    margin-bottom: 10px;
    border: 1px solid #999999;
  }
  .Deatail_to_client {
    display: none;
  }
}
/* .active-tabs::before {

    display: block;
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 2px);
    height: 5px;
    background: rgb(88, 147, 241);
} */
