.cost_main_cont {
  padding: 15px;
  margin: auto;
}

.cost_main_cont .LoaderContainer {
  margin-top: 0px !important;
}
.borderColorCss {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
}

.invoicelabel {
  width: 70%;
  font-size: 12px;
  color: #555;
  margin-bottom: 5px;
  margin-left: 5px;
  line-height: 1;
}

.invoice_table_p {
  /* border: 1px solid red; */
  margin: 0px;
  width: fit-content;
  background-color: #f1efef;
}
.table_box {
  width: 100%;
  margin: auto;
  padding: 5px 10px 5px 10px;
}
.table_box_sumary {
  width: 100%;
  margin: auto;
  padding: 5px 10px 5px 10px;
}
.supplyInfocost {
  background-color: #929594;
  position: relative;

  /* line-height: 2.1; */
  /* border: 1px solid red; */
  height: 30px;
}

.supplyInfocost > .supinfoTitle {
  width: 47%;
  margin: auto;
  color: #f5f3f3;
  float: left;
  /* border: 1px solid blue; */
  /* margin-left: 25px; */
  /* margin-top: 5px; */
  font-weight: bolder;
  position: relative;
  padding-left: 10px;
  padding-top: 5px;
}
.supplyInfocost > .supplyInfoT_icon {
  /* border: 1px solid red; */
  width: 30%;
  float: right;
  text-align: right;
  margin-top: 2px;
  margin-right: 10px;
}
.supplyInfoT_icon_inside {
  position: absolute;
  left: auto;
  right: 25px;
  top: 5px;
}

.InvoiceHead {
  background-color: #f5f3f3;

  height: 30px;

  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}
.InvoiceHead > p {
  float: left;
}
.invoice_lbtn {
  margin-left: 50px;
}

.InvoiceHead > .costbtn {
  margin-left: 35px;
}
.supplyInfoT_icon_inside > .costup_icon {
  position: absolute;
  top: -6px;
  right: -30px;
}
.InvoiceHead .SDropIcon {
  display: block;
  cursor: pointer;
}
.InvoiceHead .SIconUp {
  display: none;
}
.InvoiceHead.open .SIconUp {
  display: block;
  cursor: pointer;
}
.InvoiceHead.open .SDropIcon {
  display: none;
}

.spcost_icon .SDropIcon {
  display: block;
  cursor: pointer;
}
.spcost_icon .SIconUp {
  display: none;
}
.spcost_icon.open .SIconUp {
  display: block;
  cursor: pointer;
}
.spcost_icon.open .SDropIcon {
  display: none;
}

.sup_thead {
  font-weight: 500;
  height: 32px;
}
.reduced-height {
  display: flex;
  align-items: center;
}
.reduced-height > p {
  width: 100%;
  margin-bottom: 10px !important;
  margin-top: 5px;
  margin-left: 0px;
}
.clk_rvs_btn {
  margin-left: 0px;
  padding: 0px 5px 0px 2px;
}
.invoice_two_icon {
  margin-right: 5px;
  cursor: pointer;
}
table tfoot {
  margin-top: 10px;
  background-color: #f5f3f3;
}
.customTable td:last-child.reduced-height {
  width: 110px;
  text-align: center;
  /* padding-right: 15px; */
}

.invoice_table_btn {
  text-align: center;
  color: white;
  /* height: 10px; */
  /* width: 40px; */
  border-radius: 15px;
  /* margin-left: 20px; */
  font-size: 10px;
  font-weight: 400;
  border: none;
  cursor: pointer;
}
.Invoice_attachmentItem {
  /* width: 430px; */
  width: 100%;
  padding: 1px 0px;
  background-color: #f8f8f8;
  border: 1px solid #eee;
  margin: 2px 0;
  font-size: 13px;
  /* margin-left: 10px; */
  display: block;
}
.Invoice_attachmentItem .attachmentContent .invoice_fileInfo {
  display: block;
  float: left;
  width: 300px;
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 0px;
  padding-left: 10px;
}

.Invoice_attachmentItem .attachmentContent .progress {
  width: 70px;
  display: inline-block;
  margin: 0px;
  height: 13px;
  font-size: 10px;
  position: relative;
  top: 2px;
}
.Invoice_attachmentItem_ids {
  /* width: 430px; */
  width: 100%;
  padding: 1px 0px;
  background-color: #f8f8f8;
  border: 1px solid #eee;
  margin: 2px 0;
  font-size: 13px;
  /* margin-left: 10px; */
  display: block;
  clear: both;
}
.Invoice_attachmentItem_ids .attachmentContent .invoice_fileInfo {
  display: block;
  float: left;
  width: 300px;
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 0px;
  padding-left: 10px;
}
.Invoice_attachmentItem_ids .attachmentContent .progress {
  width: 70px;
  display: inline-block;
  margin: 0px;
  height: 13px;
  font-size: 10px;
  position: relative;
  top: 2px;
}
.invoice_emailcont {
  /*width: 455px;  Specify the desired width */
  width: 100%;
  max-width: 100%; /* Optionally, limit the maximum width to the container's width */
  height: 100px;
}

.invoice_multi .rmsc .dropdown-container {
  border-color: #aaa;
  border-radius: 2px;
  height: 23px;
}
.invoice_log_sub {
  width: auto;
  max-width: 130px;
  background-color: rgb(241, 239, 239);
  border-radius: 5px;
  font-size: 10px;
  height: 40px;
  padding: 5px;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.invoice_customTable.css-1ex1afd-MuiTableCell-root {
  font-family: "Ubuntu", sans-serif !important;
  font-size: 13px;
  font-weight: 400;
}
.invoice_errmsg {
  display: block;
  font-size: 11px;
  line-height: 1;
  width: 100%;
  color: #fff;
  margin: 0px;
  padding: 0px;
  top: auto;
  position: relative;
  background-color: #900;
  /* margin: 0px 5%; */
  bottom: 9px;
}
.inovice_btn .css-sghohy-MuiButtonBase-root-MuiButton-root {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  /* -webkit-tap-highlight-color: transparent; */

  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;

  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;

  color: #212529;
  background-color: #f5f5f5;
  box-shadow: none;
}
.inovice_btn .css-sghohy-MuiButtonBase-root-MuiButton-root:hover {
  -webkit-text-decoration: none;
  background-color: #d9d9d9;
  box-shadow: none;
}
.opacity_show {
  opacity: 1;
}
.opacity_hide {
  opacity: 0;
}

.error-message_invoiceIDs {
  font-size: 10px;
  line-height: 1;
  width: 90%;
  color: #fff;
  margin: 0px 5%;
  padding: 2px;
  /* top: auto; */

  background-color: #900;
  left: 0px;
  position: absolute;
}
.error-message_invoiceIDs.fixErrorMsg {
  bottom: 10px;
  width: 100%;
  position: relative;
  margin: 0px;
}
@media (max-width: 1252px) {
  .table_box_sumary {
    overflow: scroll;
  }
}

@media (max-width: 960px) {
  .costbtn {
    margin-right: -18px;
  }
}
@media (max-width: 1112px) {
  .cost_main_cont {
    margin-top: 40px;
  }
}

@media (max-width: 768px) {
  .cost_main_cont {
    margin-top: 50px;
  }
}

@media (max-width: 865px) {
  .table_box {
    overflow: scroll;
  }
}

@media (max-width: 490px) {
  .Invoice_attachmentItem {
    width: 260px;
  }

  .Invoice_attachmentItem .attachmentContent .invoice_fileInfo {
    width: 150px;
  }

  .Invoice_attachmentItem .attachmentContent .progress {
    width: 40px;
  }
  .invoice_emailcont {
    width: 400px; /* Specify the desired width */
    max-width: 100%; /* Optionally, limit the maximum width to the container's width */
  }
}

/* 
.invoice_multi .rmsc .dropdown-container {
  position: relative;
} */

.invoice_multi .rmsc .dropdown-container .dropdown-content {
  width: 100%;
}

.table.customTable.invoicing td {
  overflow: unset;
  max-width: 145px;
}
.remove-mt {
  margin-top: 0px !important;
}
.csmulti .rmsc .dropdown-heading .dropdown-heading-value {
  width: 15px;
}
.invoice_multi .rmsc .dropdown-container {
  height: 28px;
}
/* .attachmentContainer.w100 {
  display: flex;
  width: 100%;
} */

@media (max-width: 576px) {
  .addBtn.mr10 {
    margin-right: 10px !important;
  }
}

.w-60 {
  width: 60px;
}
.add-Quota-btn.p-set {
  padding: 3px 9px !important;

  background: #333;
}
.table.customTable.td-w-set td {
  min-width: 80px !important;
}
.table.customTable.td-w-set td:first-child {
  max-width: 100%;
}
.items-center {
  align-items: center !important;
}
.invoice_table_nodata {
  display: table-caption;
}

.table_box .invoice_customTable {
  display: table;
}

.table_box.open .invoice_customTable {
  display: none;
}

.table_box .cost_table {
  display: none;
}

.table_box.open .cost_table {
  display: block;
  border: 2px solid #f5f3f3;
  border-bottom: 0px;
}
.editIconSize {
  height: 16px !important;
}
