.Quality_search-filter {
  background: #f2f0f0;
  padding: 8px;
  font-size: 13px;
  /* margin-bottom: 16px; */
  /* display: none; */
  margin: 15px 15px;
}
.Quality_sf {
  margin: 0 auto;
}
.Quality_subbtn {
  flex-direction: row-reverse;

  align-items: flex-end;
  margin-top: 15px;
}
.Quality_submit-btn {
  padding: 3px 10px;
  background: #1a1a1a;
  border: none;
  color: white;
}
.Proj_inner-border {
  display: none;
}
.Proj_inner-border.open {
  display: table-row;
}
.dataPicker {
  width: calc(100%-200px);
}
.circle-singleline {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  background: #333232;
  color: #fff;
}

.Quality_search-filter input {
  font-family: "Ubuntu", sans-serif;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #999999;
  line-height: 24px;
  padding: 0px 10px;
}
.projectDetail_date {
  width: 200px;
  font-weight: 100;
  height: 30px;
}
.project_qualityIconColr {
  padding: 4px 0px;

  width: 30px;
  color: white;
  /* background-color: #1a1a1a; */
  border: 1px solid #999;
  height: 28px;
}
.pj_mang {
  width: 50%;
}
.cbtn.SearchQualityBtn {
  width: auto;
  background: #1a1a1a;
}
.SearchQualityBtn {
  font-size: 14px;
  color: #fff !important;
  border-radius: 1px;
  height: 30px;
  line-height: 20px;
  /* padding: 5px 15px; */
  /* float: right; */
  /* margin-right: 10px; */
  border: none;
  border-radius: 4px;
}
.mainContent .table.customTable2 td,
.mainContent .table.customTable2 > :not(caption) > * > * {
  text-align: left;
}
@media (max-width: 960px) {
  .projectDetail_date {
    /* width: 300px; */
  }
}
.top_container .main_container .maindiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 0px 10px;
}
.top_container .main_container .maindiv .left {
  display: flex;
  align-items: center;
}
.top_container .main_container .maindiv .left .tableHeading {
  margin-bottom: 0px;
}

.top_container .main_container .maindiv .right {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}
.top_container .main_container .maindiv .right .Error-list {
  display: flex;
  gap: 10px;
}

/* .cbtn {
  margin: 0px !important;
} */

@media (max-width: 768px) {
  .top_container .main_container .maindiv {
    flex-direction: column;
    align-items: start;
  }
  .top_container .main_container .maindiv .right {
    padding-top: 10px;
  }
}
@media (max-width: 580px) {
  .top_container .main_container .maindiv .right {
    flex-direction: column;
    align-items: start;
  }
  .top_container
    .main_container
    .maindiv
    .right
    .date-range
    .projectDetail_date {
    width: 300px;
  }
}
@media (max-width: 400px) {
  .top_container
    .main_container
    .maindiv
    .right
    .date-range
    .projectDetail_date {
    width: 222px;
  }
}
