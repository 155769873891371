/* .ck.ck-content.ck-editor__editable p {
  visibility: hidden;
} */

.ck.ck-content.ck-editor__editable > p {
  outline: none;
  border: none;
}
.ck-content .table,
.ck-content .table table,
.ck-content .table table tr,
.ck-content .table table td {
  border: none;
  outline: none;
}
.ck-content .table:hover {
  outline: none;
  border: none;
}
.ck
  .ck-widget.ck-widget_with-selection-handle.ck-widget_selected:hover
  > .ck-widget__selection-handle
  .ck-icon
  .ck-icon__selected-indicator,
.ck
  .ck-widget.ck-widget_with-selection-handle.ck-widget_selected
  > .ck-widget__selection-handle
  .ck-icon
  .ck-icon__selected-indicator {
  opacity: 0;
}

.ck
  .ck-widget:not(.ck-widget_selected)
  > .ck-widget__type-around
  > .ck-widget__type-around__button {
  display: none;
}
.ck.ck-editor__editable td .image-inline img,
.ck.ck-editor__editable th .image-inline img,
.ck.ck-editor__editable th .image-inline img:hover,
.ck.ck-editor__editable td .image-inline img:hover {
  /* max-width: none; */
  outline: none;
  border: none;
}
.ck.ck-editor__editable.ck-blurred
  .ck-widget.ck-widget_selected.ck-widget_with-selection-handle:hover
  > .ck-widget__selection-handle,
.ck.ck-editor__editable.ck-blurred
  .ck-widget.ck-widget_selected.ck-widget_with-selection-handle:hover
  > .ck-widget__selection-handle:hover,
.ck.ck-editor__editable.ck-blurred
  .ck-widget.ck-widget_selected.ck-widget_with-selection-handle
  > .ck-widget__selection-handle,
.ck.ck-editor__editable.ck-blurred
  .ck-widget.ck-widget_selected.ck-widget_with-selection-handle
  > .ck-widget__selection-handle:hover {
  background-color: #fff;
  display: none;
}
.ck
  .ck-widget.ck-widget_with-selection-handle:hover
  > .ck-widget__selection-handle {
  background-color: #fff;
}
.ck.ck-editor__editable.ck-blurred .ck-widget.ck-widget_selected,
.ck.ck-editor__editable.ck-blurred .ck-widget.ck-widget_selected:hover {
  background-color: #fff;
  border: none;
  outline: none;
}
.ck .ck-widget {
  outline: none;
  border: none;
}
.ck.ck-content.ck-editor__editable p {
  min-height: 10px;
  margin-bottom: 5px;
}

.jodit-workplace+.jodit-status-bar:not(:empty){
  display:none
}

.ReplyBlock.rpedit{
  padding: 0px;
  border: none;
}
