.department_rolename_to_active {
  display: grid;
  grid-template-columns: 300px 300px 300px 300px;
  grid-column-gap: 20px;
  grid-template-rows: 20px 20px;
}
.department_hod {
  margin-top: 20px;
}
#update_save {
  background-color: blue;
  color: white;
}
#closebutton {
  background-color: black;
  color: aliceblue;
}
.department_finance_input {
  width: 100%;
}
.department_update_dropdown {
  height: 35px;
}
select {
  background-color: white;
}
