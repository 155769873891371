.custom-file-upload{
    width:200px;
    height: 30px;
    font-size:14px;
    padding: 4px 10px;
    color:#1a1a1a;
    border:1px solid #1a1a1a;
    cursor: pointer;
    margin-top:18px;
}

#file-upload{
   display: none;
}

.headinginternal{
    font-size:16px;
    width:auto;
    display: inline-block;
    padding-bottom: 5px;
    border-bottom:2px solid #1a1a1a
}