@media (min-width: 992px) {
  .popupmodehere .modal-lg,
  .popupmodehere .modal-xl {
    --bs-modal-width: 618px;
  }
}

input.qulaity_check[type="radio"]:checked ~ label {
  color: #0f100f;
}
