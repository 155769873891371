.nesting-table {
  table-layout: fixed;
  width: 100%;
}

.nesting-table thead td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nesting-table thead td:last-child,
.nesting-table tbody td:last-child,
.nesting-table thead td:nth-last-child(2),
.nesting-table tbody td:nth-last-child(2) {
  text-align: center;
}

.text-center {
  text-align: center;
}
