/* ExcelGrid.css */

.excel-grid {
  font-family: Arial, sans-serif;
  width: 100%;
  overflow-x: scroll; /* Allow horizontal scrolling for responsive design */
  max-height: 500px; /* Set a maximum height for the grid, adjust as needed */
}

.gridTable {
  border-collapse: collapse;
  width: 100%;
  overflow: scroll;
  margin-bottom: 20px; /* Add some spacing between the grid and other elements */
}
.grid_input {
  /* border: 1px solid red; */
  width: 45px;
  height: 20px;
  border: none;
  /* padding: 4px; */
  box-sizing: border-box;
}

.grid_td {
  border: 1px solid rgba(8, 8, 8, 0.868);
}
.addbg {
  background-color: #f0efef;
  padding: 10px 15px 10px 15px;
}
.rateCardValFix.addnew_supply {
  display: block;
  font-size: 10px;
  line-height: 1;
  width: 100%;
  color: #fff;
  margin: 0px !important;
  padding: 2px;
  top: auto;
  bottom: 7px;
  background-color: #900;
  left: 0px;
  position: relative;
}
