.fbs {
  background-color: black;
  color: white;
  border-top: 17px;
  width: 100%;
  padding-left: 15px;
  font-size: 12px;
  height: 30px;
  padding-top: 5px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.clf {
  margin-top: 20px;
}
.optioncontainer {
  width: 100%;
  font-size: 12px;
  padding: 10px;
  background-color: white;
  padding-bottom: 10px;
  color: #817b7b;
}

.filterarrow {
  color: white;
  margin-left: 33%;
}
.FilterPtag {
  width: 100%;
  margin: 0px;
  margin-left: 8px;
  cursor: pointer;
  background-color: #f5f3f3;
  margin: 10px 1px 1px 1px;
  padding-left: 8px;
}

.uparrow {
  color: white;
}

.gap {
  background-color: #f5f3f3;
  height: 20px;
}
.fbu {
  margin-left: 15px;
  font-size: 12px;
}

.wrapwords {
  padding: 1px 5px 1px 5px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.csmulti .rmsc .dropdown-container {
  border-color: #aaa;
  border-radius: 2px;
  height: 28px;
}

.csmulti .rmsc .dropdown-heading {
  height: 28px;
  display: flex;
  justify-content: space-between;
}

.csmulti .dropdown-heading-value,
.csmulti .dropdown-search-clear-icon.gray {
  margin-top: 0;
}

.csmulti .rmsc .dropdown-container .dropdown-heading-dropdown-arrow {
  margin: 0px;
  vertical-align: top;
}

.csmulti .rmsc .dropdown-container {
  margin-top: 0px;
}
.csmulti .rmsc .dropdown-container .options {
  max-height: 50px;
}
.csmulti .rmsc .search input {
  height: 25px;
  padding: 0 8px;
  font-size: 12px;
}
.csmulti .rmsc .dropdown-container .dropdown-content .select-item.selected {
  background: #eee;
}

.csmulti .rmsc .dropdown-container .dropdown-content .select-item {
  font-size: 13px;
  padding: 2px 5px;
}
.csmulti .rmsc .dropdown-heading .dropdown-heading-value {
  font-size: 14px;
}

.csmulti .rmsc .dropdown-container:focus-within {
  box-shadow: #000 0 0 0 1px;
  border-color: #000;
}

.dSZDRP {
  max-height: unset !important;
}

.csmulti .rmsc .dropdown-heading .dropdown-heading-value {
  flex: auto !important;
}

.csmulti .rmsc .dropdown-content .panel-content .options {
  max-height: 100px;
  height: 200px;
  z-index: 99999999;
}

.dropeTablefilter {
  display: none;
}
.dropeTablefilter.open {
  display: block;
}
.droptablechild1 {
  display: none;
}
.droptablechild1.open {
  display: block;
}

.filter_drop_height.open {
  height: 95px;
}

#dynataoption {
  background-color: #f5f3f3;

  width: 100%;
  padding-right: 10px;
  margin-top: 10px;
}

.Dynatasc {
  padding-bottom: 3%;
  background-color: white;

  border: 1px solid rgb(241, 241, 241);
  padding: 1px 5px 1px 5px;
}
.gss_to_washing {
  margin-left: 15px;
  margin-top: 15px;
  font-size: 12px;
}
.gss_to_washing > p {
  margin: 5px;
}
.SC106647 {
  font-size: 12px;
  margin-left: 14px;
  margin-top: 4px;
  /* white-space: nowrap; */
  text-wrap: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.linegreen {
  background-color: #55bf35;
  color: #55bf35;
}
.linegreenPending {
  background-color: #f9e20b;
  color: #f9e20b;
}
.linegreenTest {
  background-color: #03bbf8;
  color: #03bbf8;
}
.linegreenPaused {
  background-color: #ffb302;
  color: #ffb302;
}

.linegreenAwaited {
  background-color: #96a6c1;
  color: #96a6c1;
}
.linegreenBilled {
  background-color: #aa00ff;
  color: #aa00ff;
}

.linegreenCancelled {
  background-color: #ff0808;
  color: #ff0808;
}
.sc10 {
  background-color: #f6f7f7;
  display: flex;
  width: 100%;

  margin-top: 5px;
}
.SC106647dynataarrow {
  color: #f6f7f7;
  margin-left: 15%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.rmsc {
  --rmsc-main: #01050b;
}

.usgen {
  font-size: 12px;
  height: fit-content;
  color: rgb(74, 76, 76);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.sc10container {
  width: 100%;
  background-color: #f6f7f7;
}
.sc101 {
  display: flex;

  margin-top: 5px;
  padding: 1px 5px 1px 13px;
}

.filter_arrow_icon .css-i4bv87-MuiSvgIcon-root {
  position: absolute;
  left: auto;
  right: 10px;
  top: 5px;
}

.childData {
  display: block;
}
.childData.open {
  display: none;
}
.colorFilterselected {
  background: linear-gradient(to right, #dfe0e5, rgba(146, 151, 151, 0.525));
}

.sc101 .childUp {
  display: none;
}
.sc101.open .childUp {
  display: block;
}
.sc101 .childDown {
  display: block;
}
.sc101.open .childDown {
  display: none;
}
.filter_StatusColor {
  height: 10px;
  margin-top: 5px;
  margin-left: -7px;
}
#filterProjectArrow {
  font-size: 120%;
  position: absolute;
  left: auto;
  right: 5px; /* Assuming you intended to set a value for 'right' */
  top: 0;
  color: black;
}

@media (max-width: 834px) {
  .fbs {
    font-size: 12px;
  }
  .filterarrow {
    margin-left: 10%;
  }
  .optioncontainer {
    padding-top: 10px;
  }
  .uparrow {
    margin-left: 19%;
  }
  .optioncontainer {
    font-size: 12px;
  }
  .dynataarrow {
    margin-left: 35%;
  }
  .SC106647 {
    font-size: 12px;
  }
  .usgen {
    font-size: 11px;
  }
}

/* styles.css */
/* FilterHeading.css */
/* styles.css */

.clientFilter {
  width: 50%;
  color: white;
  margin: 0;
  display: inline-block;
  height: 0;
}

.filterHeading {
  position: relative;
}

#updownIcon {
  position: absolute;
  left: auto;
  right: 5;
  top: -2px;
  color: black;
}

.filterHeading .azurUpIcon,
.filterHeading .azurDownIcon {
  position: absolute;
  left: auto;
  right: 5px;
  top: 0;
  color: black;
}

.SC106647 .projectName {
  cursor: pointer;
  text-wrap: wrap;
}

.SC106647 .scUpIcon,
.SC106647 .scDownIcon {
  font-size: 120%;
  position: absolute;
  left: auto;
  right: 4px;
  top: 6px;
  color: black;
}

.green-dot {
  color: #34b140;
  height: 10px !important;
  margin-top: 5px;
  margin-left: -7px;
}

.yellow-dot {
  color: #fce83a;
  height: 10px !important;
  margin-top: 5px;
  margin-left: -7px;
}

.blue-dot {
  color: #2dccff;
  height: 10px !important;
  margin-top: 5px;
  margin-left: -7px;
}

.orange-dot {
  color: #fec53f;
  height: 10px !important;
  margin-top: 5px;
  margin-left: -7px;
}

.gray-dot {
  color: #a4abb6;
  height: 10px !important;
  margin-top: 5px;
  margin-left: -7px;
}

.purple-dot {
  color: #b330f5;
  height: 10px !important;
  margin-top: 5px;
  margin-left: -7px;
}

.red-dot {
  color: #ff3838;
  height: 10px !important;
  margin-top: 5px;
  margin-left: -7px;
}

@media (max-width: 480px) {
  .fbs {
    width: 100%;
  }

  .uparrow {
    margin-left: 75%;
  }
  .dynataarrow {
    margin-left: 75%;
  }
}
