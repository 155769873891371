.permissions-user-box {
  background-color: rgb(243, 243, 250);
  margin-top: 10px;
  height: 40px;
}

.container-checkbox-fields {
  margin-top: 0px;
  font-size: 15px;
}

.user-inputs {
  margin-top: 10px;
}

.permissions-columns {
  margin-left: 20px;
}

@media screen and (max-width: 576px) {
  .permissions-user-box span {
    margin-left: 27px;
  }
  .css-1wf8b0h-MuiTabs-flexContainer{
    display: grid!important;
    grid-template-columns: repeat(3, 1fr);
  }
  .css-1gsv261{
    border-bottom: none!important;
  }
  .css-1aquho2-MuiTabs-indicator{
    background-color: transparent!important;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .permissions-user-box span {
    margin-left: 34px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .permissions-user-box span {
    margin-left: 38px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .permissions-user-box span {
    margin-left: 38px;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .permissions-user-box span {
    margin-left: 35px;
  }
}

@media (min-width: 1200px) {
  .permissions-user-box span {
    margin-left: 35px;
  }
}



