.css-bsph2u-MuiTableCell-root.qualitytablecell {
  font-size: 13px;
  font-weight: 400;
  font-family: "Ubuntu", sans-serif;
}
.quality_prj_textarea {
  border: 1px solid grey;
  height: 200px;
  overflow: hidden;
  width: 100%;
  color: grey;
  font-size: 13px;
}
.qa_comnt {
  margin-left: 15px;
  color: grey;
  font-size: 13px;
  margin-top: 10px;
}
.read_more {
  color: #555;
  font-size: 8px;
  cursor: pointer;
}

/* Tooltip container */

.table > :not(caption) > * > .tooltip {
  border: 1px solid red;
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.table > :not(caption) > * > .tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.table > :not(caption) > * > .tooltip:hover .tooltiptext {
  visibility: visible;
}
.table > :not(caption) > * > .tooltip .tooltiptext {
  width: 120px;
  bottom: 100%;
  left: 50%;
  margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */
}
