.rowContant {
  margin: 10px;
}
.settingHeading {
  background-color: whitesmoke;
  margin-top: 15px;
  height: 20px;
}

.addbutton {
  margin-right: 0px;
  margin-top: 10px;
  margin-left: 10px;
}

.togglebuttonsetting {
  margin-left: 20px;
}
.showHeaders {
  display: none;
}
.supplier_setting_error {
  display: block;
  font-size: 10px;
  line-height: 1;
  width: 100%;
  color: #fff;
  /* margin: 0px 5%; */
  padding: 2px;
  top: auto;
  bottom: -6px;
  background-color: #900;
  left: 0px;
  /* position: absolute; */
}
.supplier_setting {
  margin-bottom: 0px;
}

.redirect_url_row {
  margin: 10px 0px;
}
@media (max-width: 772px) {
  .showHeaders {
    display: block;

    width: 100%;
  }
  .settingHeadersh {
    display: none;
  }
}
