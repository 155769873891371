body {
  font-family: "Ubuntu", sans-serif;
  letter-spacing: 0.5px;
  line-height: 1.6;
  font-weight: 400;
  font-size: 13px;
}

.targetscreenLayout {
  display: flex;
  justify-content: end;

  grid-gap: 12px;
}

/* styles.css */

.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
}

/* styles.css */

.flex-end-container {
  justify-content: flex-end;
  gap: 10px;
  display: flex;
}
/* styles.css */

.border-top-container {
  border-top: 1px solid #eee;
  margin-top: 20px;
  margin-bottom: 20px;
}
/* styles.css */

.custom-text {
  font-size: 13px;
  height: 40px;
  margin-top: 25px;
  text-align: center;
}

.quotaMain > .accordion .accordion__button {
  position: relative;
  padding: 2px 12px;
}

.quotaMain > .accordion .accordion__button:before {
  position: absolute;
  left: auto;
  top: 13px;
  height: 8px;
  width: 8px;
  right: -5px;
  display: none;
}

.quotaMain > .accordion .line_item {
  width: 100%;
  /* padding-left: 12px; */
}

.ageRangeInput {
  display: none;
  position: absolute;
}

.ageRangeInput.show {
  display: flex;
}

.fileUpload {
  text-align: right;
}

.fileUpload span {
  max-width: 70%;
  margin: 23px 0 0 0;
  display: block;
  text-align: right;
  float: right;
  padding-right: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  border: 1px solid #eee;
  padding: 2px 10px;
}

.custom-file-upload input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid #ccc;
  margin-top: 22px;
  padding: 4px 12px;
  cursor: pointer;
  float: right;
  text-align: center;
  width: 25%;
  background-color: #333;
  color: #fff;
  height: 30px;
}

.quotaMain .rmsc .dropdown-container {
  border-radius: 1px;
  border-color: #aaa;
}
.quotaMain .rmsc .dropdown-heading {
  height: 26px;
  font-size: 14px;
  color: #999;
}

.addDefinition {
  cursor: pointer;
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #aaa;
  border-radius: 0;
  text-align: center;
  font-size: 14px;
  background-color: #fff;
  margin-bottom: 10px;
}
.input-container {
  position: relative;
}

.delete-icon {
  position: absolute;
  top: 3px;
  right: 5px;
  cursor: pointer;
  width: 20px;
  color: #140c0c;
}

.addDefinition.disabled {
  cursor: no-drop;
  border-color: #f1f1f1;
  outline: none;
  background-color: #f1f1f194;
}

.targetSubmit {
  font-size: 14px;
  color: #fff !important;
  background: #1a1a1a;
  border-radius: 1px;
  height: 30px;
  line-height: 20px;
  padding: 5px 15px;
  float: right;

  border: none;
  border-radius: 4px;
  margin: 0px;
}

.targetMain .modal-body .css-1s2u09g-control {
  height: auto;
  max-height: unset;
}

.quotaMain .accordion__panel {
  padding: 0px;
}

.extra_client_input_header_target {
  display: none;
}
.extra_client_input_header_target.open {
  padding: 20px 0;
  display: block;
}

.T_IconUp {
  position: relative;
  left: 10;
  right: auto;
  /* top: 5; */
  cursor: pointer;
}
.T_DropIcon {
  position: relative;
  left: auto;
  right: 5;
  top: 5;
  cursor: pointer;
}

.target_arrow .T_DropIcon {
  display: block;
  cursor: pointer;
  margin-right: -8px;
}
.target_arrow .T_IconUp {
  display: none;
}
.Target_panel.open {
  display: block;
}
.Target_panel {
  display: none;
}
.target_arrow.open .T_IconUp {
  display: block;
  cursor: pointer;
  margin-right: -8px;
}
.target_arrow.open .T_DropIcon {
  display: none;
}
.targrt_swit_btn {
  margin-right: 0px;
  padding-right: 0;
}

.extra_client_input_header_quota_target .css-1s2u09g-control,
.extra_client_input_header_quota_target .css-1pahdxg-control {
  min-height: 28px;
  border-radius: 1px;
}

.extra_client_input_header_quota_target .css-319lph-ValueContainer {
  padding: 0px 6px;
}

.extra_client_input_header_quota_target .css-tlfecz-indicatorContainer,
.extra_client_input_header_quota_target .css-1gtu0rj-indicatorContainer {
  padding: 1px;
}

.extra_client_input_header_quota_target .input-group .form-control {
  padding: 3px 8px;
  height: 28px;
  border-radius: 1px;
  border: 1px solid #ccc;
}

.extra_client_input_header_quota_target .input-group .form-control:focus {
  box-shadow: 0 0 0 0 rgba(13, 110, 253, 0.25);
}

.extra_client_input_header_quota_target .input-group .input-group-text {
  padding: 2px 4px;
  font-size: 14px;
  line-height: 1.2;
}
.target_select .css-1rhbuit-multiValue {
  color: red;
}

.target_select .css-1s2u09g-control {
  overflow: hidden;
}
.react-select__multi-value__label {
  color: #7e0707;
  font-size: 14px;
  font-weight: bold;
}
.row_manual .css-1hb7zxy-IndicatorsContainer {
  height: 28px;
}
