#QuestioName {
    margin-top: 10px;
}

.gaprespondan {
    width: 10px;
}
.questionPosition {
    text-align: center;
}
.gapheight {
    height: 10px;
}
.respondentsave {
    font-size: 14px;
    color: #fff !important;
    border-radius: 1px;
    height: 30px;
    line-height: 20px;
    padding: 5px 15px;

    border: none;
    border-radius: 4px;
    background-color: #000;
}

.headerQuestion {
    float: left;
}

.parent-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.makesomePadding {
    padding-top: 10px;
    padding-bottom: 10px;
}

.toppadding {
    padding-top: 10px;
}

#respoSelect {
    height: '10px';
    width: 100%;
}
make install #respondentQuestionSelect {
    font-size: 14px;
    border: 1px solid black;
    border-radius: 0px;
    height: 27px;
    padding: 3px;
    padding-left: 12px;
    border: 1px solid #aaa;
    color: '#999';
}
