/* 992 */
.tablescroll {
    /* overflow-x: scroll; */
    padding-top: 0px !important;
}
.rightTable {
    padding-left: 15px !important;
}
.spaceBetween {
    /* padding-left: 0px; */
    padding-right: 0px;
    /* background-color: aquamarine; */
}

.heightcss .table-responsive-sm {
    padding-left: 0px;
    padding-right: 0px;
}
.unick td {
    padding-left: 10px;
    padding-right: 10px;
}

.buttonParents {
    justify-content: flex-end;
    margin-top: -70px;
    display: flex;
}
.unick tr {
    /* background-color: aqua; */
    border-bottom: 1px solid whitesmoke;
}
.unicknew tr {
    /* background-color: aqua; */
    border-bottom: 1px solid white;
    background-color: whitesmoke;
}

.RespData {
    background-color: #1a1a1a;
    text-align: center;
    padding-top: 4px;
    font-size: 14px;
    color: white;
    /* margin-top: 16px; */
    border-radius: 4px;
    border: none;
    height: 30px;
    line-height: 20px;
    padding: 5px 15px;
    margin-right: 10px;
}
.Screendata {
    background-color: #1a1a1a;
    text-align: center;
    padding-top: 4px;
    font-size: 14px;
    color: white;
    border-radius: 4px;
    border: none;
    height: 30px;
    line-height: 20px;
    padding: 5px 15px;
    margin-right: 5px;
}
.quota_surtrakbtn {
    background-color: #999;
    text-align: center;
    padding-top: 4px;
    font-size: 14px;
    color: white;
    border-radius: 4px;
    border: none;
    height: 30px;
    line-height: 20px;
    padding: 5px 15px;
    margin-right: -10px;
}
.quota_savebtn {
    background-color: #1a1a1a;
    text-align: center;
    padding-top: 4px;

    font-size: 14px;
    color: white;

    border-radius: 4px;
    border: none;
    height: 30px;
    line-height: 20px;
    padding: 5px 15px;
    margin-right: -9px;
}
.q_edit_switcbtn {
    text-align: left;
    padding-right: 0px;
}

.quota_survey_track_title {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.tabledata {
    font-size: 13px;
    font-family: 'Ubuntu', sans-serif !important;
}
.tabledata th {
    min-width: 140px;
}
.fifteenspan {
    font-weight: bolder;
    font-size: 20px;
}
.inactive_qutobtn {
    background-color: orange;
    width: 55px;
    text-align: center;
    color: white;
    font-size: 13px;
}
.off_qutobtn {
    background-color: #1a1a1a;
    font-size: 13px;
    color: white;
    text-align: center;
    width: 35px;
}

.quta_link_text {
    font-size: 13px;
    color: #999;
    padding-top: 8px;
}
.qutasiderow {
    font-size: 13px;
    font-family: 'Ubuntu', sans-serif !important;
    line-height: 3;
    background-color: #f1efef;
    width: 160px;
}

.secoundLineHeight {
    line-height: 3;
    font-size: 17px;
}

.headerlineheihgt {
    line-height: 3;
    font-size: 17px;
    color: #f1efef;
}
.heightcss {
    line-height: 3;
    font-size: 13px;
    width: 130px;
    white-space: nowrap;
}
.removeBorder {
    border-bottom: none;
    padding-left: 10px;
    /* border-color: #d12020; */
}
.unicknew td {
    padding-left: 10px;
}
.showBoder {
    border-color: black;
}
/* .table > :not(caption) > * > .qutasiderow {
  background-color: #f1efef;
  border-bottom: none;
} */
.quota_iconBtn {
    cursor: pointer;
    width: 18px;
}

.quotasub .css-bsph2u-MuiTableCell-root {
    font-family: 'Ubuntu', sans-serif !important;
    font-size: 13px;
}
.supplyinfo_text.css-1ex1afd-MuiTableCell-root {
    font-family: 'Ubuntu', sans-serif !important;
    font-size: 13px;
    font-weight: 400;
}
.supplyinfo_text.css-q34dxg {
    font-family: 'Ubuntu', sans-serif !important;
    font-size: 13px;
    font-weight: 400;
}
/* .trackingRow {
  border-bottom: none;
  font-size: 13px;
  font-family: "Ubuntu", sans-serif !important;
  background-color: rgb(241, 239, 239);
} */

.supplierNameagain {
    background-color: whitesmoke;
    font-weight: bold;
}

@media (max-width: 991px) {
    .RespData {
        padding: 5px 8px;
        margin-right: 1px;
    }
    .Screendata {
        padding: 5px 12px;
        margin-right: 1px;
    }
}
.editicon-css {
    width: 35px;
    font-size: 34px;
}
.scrollXNone.table-responsive {
    overflow-x: auto !important;
}
.fixedXScroll {
    --bs-gutter-x: 0rem !important;
}
