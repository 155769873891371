@media (min-width: 991px) {
  .inputSize {
    width: 14%;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 1100px;
  }
}
.editsupplier_explink {
  margin: 10px 10px 10px 10px;
  padding: 0px;
  /* margin-right: 15px; */
}
/* .edit_sup_select {

} */

.css-1s2u09g-control {
  background-color: #ffffff;
  color: #555;
  cursor: default;
  display: block;
  font-size: inherit;
  padding: 00px;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  max-height: 28px;
}
.epjt_select .css-1s2u09g-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  border-color: #aaa;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 28px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: none;
  transition: none;
  box-sizing: border-box;
  font-family: "Ubuntu", sans-serif;
  font-size: smaller;
}
.epjt_select .css-1pahdxg-control:hover {
  border-color: #aaa;
}
.epjt_select .css-1pahdxg-control,
.css-1pahdxg-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: #aaa;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 0 0 1px #2684ff00;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  max-height: 28px;
  min-height: 28px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: none;
  transition: none 100ms;
  box-sizing: border-box;
}

.epjt_select .css-tlfecz-indicatorContainer,
.epjt_select .css-1gtu0rj-indicatorContainer,
.epjt_select .css-1hb7zxy-IndicatorsContainer {
  padding: 2px 2px;
}
.epjt_select .css-qc6sy-singleValue {
  color: #999;
  font-size: 13px;
}
.epjt_select .css-1pahdxg-control .css-6j8wv5-Input {
  margin: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
  visibility: visible;
  color: hsl(0, 0%, 20%);
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: inline-grid;
  grid-area: 1/1/2/3;
  grid-template-columns: 0 min-content;
  box-sizing: border-box;
  font-size: 14px;
}

.css-6j8wv5-Input:active,
.css-6j8wv5-Input:focus,
.css-1pahdxg-control {
  outline: none;
  border: none;
}
.css-14el2xx-placeholder {
  color: #999 !important;
  grid-area: 1/1/2/3;
  margin-left: 2px;
  margin-right: 2px;
  box-sizing: border-box;
  font-size: 14px !important;
}

.epjt_select .css-26l3qy-menu {
  top: 100%;
  background-color: hsl(0, 0%, 100%);
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  margin-bottom: 2px;
  margin-top: 0px;
  position: absolute;
  width: 100%;
  z-index: 1;
  box-sizing: border-box;
}
.epjt_select .css-6j8wv5-Input {
  margin: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
}

.epjt_select .css-4ljt47-MenuList {
  max-height: 160px;
  overflow-y: auto;
  padding-bottom: 1px;
  padding-top: 1px;
  position: relative;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
}
.epjt_select .css-11unzgr {
  max-height: 160px;
}

.epjt_select .css-yt9ioa-option,
.epjt_select .css-1n7v3ny-option {
  padding: 2px 12px;
}

.inputSize .css-b62m3t-container .select__menu {
  font-size: 13px !important;
}

.supply_errormsg {
  display: block;
  font-size: 10px;
  line-height: 1;
  /* width: 85%; */
  width: 100%;
  color: #fff;
  margin-left: 10px;
  /* margin: 0px 5%; */
  padding: 2px;
  top: auto;
  /* bottom: -6px; */
  bottom: 5px;
  background-color: #900;
  left: 0px;
  /* position: absolute; */
  position: relative;
}

.buildURL_wrapper {
  padding: 20px 10px 10px 10px;
  display: grid;
  row-gap: 10px;
}
.active-badge {
  display: block;
  width: 70px;
  text-align: center;
  color: #ffffff !important;
  background: #55bf35;
  padding: 3px 8px;
  border-radius: 20px;
  font-size: 10px;
  font-weight: 400;
}
.inactive-badge {
  display: block;
  width: 70px;
  text-align: center;
  color: #ffffff !important;
  background: #ff3838;
  padding: 3px 8px;
  border-radius: 20px;
  font-size: 10px;
  font-weight: 400;
}
.stanBadge {
  color: #ffffff !important;
  background: #555;
  padding: 3px 8px;
  border-radius: 20px;
}
/* .high {
  color: #ffffff !important;
  background: #ff3838;
  padding: 3px 8px;
  border-radius: 20px;
}
.medium {
  color: #ffffff !important;
  background: #494ca2;
  padding: 3px 8px;
  border-radius: 20px;
}
.low {
  color: #ffffff !important;
  background: #d2d462;
  padding: 3px 8px;
  border-radius: 20px;
} */

.settingHeading {
  height: 30px !important;
  display: flex;
  align-items: center;
}
.px-10 {
  padding: 0px 10px;
}
.py-10 {
  padding: 10px 0px;
}
