.RSsubmit {
    background-color: black;
    color: white;
    height: 40px;
    width: 100px;
    margin-top: 10px;
    margin: 0px;
}
.submitPosition {
    display: flex;
    justify-content: flex-end;
}

.addcss .formlabelviewzone ~ small.addnew_supply {
    display: block;
    font-size: 10px;
    line-height: 1;
    /* width: 90%; */
    width: 100%;
    color: #fff;
    /* margin: 0px 5%; */
    margin: 0px !important;
    padding: 2px;
    top: auto;
    /* bottom: -6px; */
    bottom: 7px;
    background-color: #900;
    left: 0px;
    /* position: absolute; */
    position: relative;
}
.addsupply_col {
    position: relative;
}
