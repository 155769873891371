.opportunity_main {
  background-color: #fff;
  padding: 0px 0px 5px;
}

.opportunity_main .cbtn {
  float: right;
  margin-right: 15px;
  margin-top: 5px;
}

.opportunity_main .accordion__button {
  padding: 4px 25px;
  position: relative;
  background-color: #ddd;
}
.topzero {
  font-size: 11px;
  line-height: 1;
  width: 100%;
  color: #fff;
  margin: 0px;
  padding: 0px;
  top: 6px;
  position: relative;
  background-color: #900;
  /* margin: 0px 5%; */
  bottom: 15px;
}

.opportunity_main .accordion__button:before {
  position: absolute;
  right: 10px;
  top: 10px;
  left: auto;
  display: inline-block;
  content: "";
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
}

.opportunity_main .accordion__item {
  margin-bottom: 4px;
}

.opportunity_main .accordion__panel {
  background-color: #fafafa;
  padding: 5px 20px;
}

.opportunity_main .deleteBtn {
  position: absolute;
  left: auto;
  right: 45px;
  top: 8px;
  height: 16px;
}

.opportunity_main .accordion__item:first-child .deleteBtn {
  display: none;
}

.line_item {
  width: 800px;
}

.line_item label.formlabel {
  width: auto;
  display: block;
  float: left;
  margin-top: 8px;
  margin-right: 5px;
}

.line_item .formtext {
  width: 200px;
  margin-bottom: 0px;
}

.extra_client_input_header {
  font-size: 12px;
  color: #555555;
  padding-top: 0px;
  /* display: grid;
  grid-template-columns: 23% 23% 11% 11% 11% 11%;
  grid-column-gap: 2%; */
  margin: 0px;
}

.bid_input {
  width: 30%;
  height: 25px;
  margin-left: 33px;
  font-size: 12px;
}

.client_bid_details {
  font-size: 12px;
  color: #0e72b9;
  margin-left: 35px;
  padding-top: 20px;
}

#passBlock.row.hidden {
  display: none;
}

#bidWon.row.hidden {
  display: none;
}

#oppBlock.row.hidden {
  display: none;
}

.ck.ck-content.ck-editor__editable p {
  min-height: 100px;
}
.itemWrap {
  display: inline;
}

@media (max-width: 576px) {
  .opportunity_main .cbtn {
    margin-right: 0px;
  }
  .line_item .formtext {
    width: 120px;
  }
}

@media (max-width: 768px) {
  .itemWrap {
    display: block;
  }
}
