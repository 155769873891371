.radio-content {
    width: 30px;
    margin: -2px;
}

.firstRowborder {
    border: 1px solid whitesmoke;
    padding-bottom: 10px;
}
.width100 {
    width: 100%;
}
.emailquestionGap {
    height: 20px;
}

.centerstyle {
    text-align: center;
}

.questionInputheight {
    height: 30px;
    font-size: 14px;
}

.addButtonOPtions {
    background-color: black;
    color: white;
    height: 30px;
    width: 100px;
    margin: 10px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
}
#addPredefinedstyle {
    width: 100%;
    font-size: 14px;
    height: 30px;
}

.iconalliment {
    border: 1px solid black;
    text-align: center;
}

.radiouscolorchanges .form-select:focus {
    border-color: none;
    outline: 0;
    box-shadow: none;
}

.multiselectoption .rmsc .dropdown-heading {
    height: 25px;
    font-size: 12px;
}

.prescreenmultiselectbox {
    height: 15px;
    background-color: #0519f2;
}

#prescreen input[type='radio'] {
    display: none;
    visibility: hidden;
}
.containerssssss {
    display: flex;
    margin: 40px auto;
    height: auto;
    width: 500px;
    padding: 20px;
}

#prescreen input[type='radio'] + label {
    color: black;
    cursor: pointer;
    font-size: 20px;
}

#prescreen input[type='radio'] + label span {
    width: 27px;
    height: 27px;
    margin: -2px 5px 0 0;
    border: 1px solid #cecece;
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.2s, transform 0.2s;
}

#prescreen input[type='radio'] + label span:hover,
#prescreen input[type='radio'] + label:hover span {
    transform: scale(1.2);
}

#prescreen input[type='radio']:checked + label span {
    background: #1d976c;
    background: -webkit-linear-gradient(to right, #0534f0, #081ddc);
    background: linear-gradient(to right, #0703e1, #0519f2);
    box-shadow: 0px 0px 0px 5px white inset;
}

#prescreen input[type='radio']:checked + label.skip {
    color: #b006ff;
}
#prescreen input[type='radio']:checked + label.circle {
    color: #0606f5;
}
#prescreen input[type='radio']:checked + label.cross {
    color: #ff0000;
}
#prescreen input[type='radio']:checked + label.play {
    color: #06ff0a;
}

/* input[type="radio"]:checked ~ .check {
  border: 5px solid #0dff92;
}

input[type="radio"]:checked ~ .check::before {
  background: #0dff92;
}

input[type="radio"]:checked ~ label {
  color: #0dff92;
} */

.signature {
    margin: 10px auto;
    padding: 10px 0;
    width: 100%;
}

.signature p {
    text-align: center;
    font-family: Helvetica, Arial, Sans-Serif;
    font-size: 0.85em;
    color: #aaaaaa;
}

.signature .much-heart {
    display: inline-block;
    position: relative;
    margin: 0 4px;
    height: 10px;
    width: 10px;
    background: #ac1d3f;
    border-radius: 4px;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.signature .much-heart::before,
.signature .much-heart::after {
    display: block;
    content: '';
    position: absolute;
    margin: auto;
    height: 10px;
    width: 10px;
    border-radius: 5px;
    background: #ac1d3f;
    top: -4px;
}

.signature .much-heart::after {
    bottom: 0;
    top: auto;
    left: -4px;
}

.signature a {
    color: #aaaaaa;
    text-decoration: none;
    font-weight: bold;
}
