.Reconciliation_search-filter {
  width: 100%;
  /* border: 1px solid red; */
  /* display: flex; */
  height: 70px;
  background-color: #f1efef;
}
.R_input_div {
  width: 14.28%;
  display: block;
  float: left;
  padding: 0 0.5%;
}

.R_input_div select {
  color: #999;
}
.R_input_div select option {
  color: #999;
}
.redit_select select {
  color: #999;
}
.redit_select select option {
  color: #999;
  min-width: 40px;
  width: 40px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.R_input {
  float: left;

  width: 100%;

  height: 30px;
  font-size: 14px;
}
.R_label {
  display: inline-block;
  font-size: 12px;

  height: 18px;
  width: 100%;
}
.R_submit-btn {
  padding: 0px 5px;
  background: #1a1a1a;
  border: none;
  color: white;

  margin-top: 18px;
  height: 25px;
  width: fit-content;
  border-radius: 4px;
  font-size: 14px;
}

.R_update {
  background: #999;
}

.R_down_img {
  width: 20px;
  align-items: flex-end;
}
.table#R_consilition > :not(caption) > * > * {
  border-bottom: none;
  border-left: 1px solid grey;
  vertical-align: middle;
}

.table#R_consilition > :not(caption) > * > :first-child {
  border-left: none;
}

.table#R_consilition > :not(caption) > * > td {
  align-items: center;
  width: 100px;
}
.table#R_consilition > :not(caption) > * > .R_recon {
  width: 350px;
}
.table#R_consilition > :not(caption) > * > .RD_recon {
  width: 20px;
}
.table#R_consilition > :not(caption) > * > .R_down_img {
  width: 10px;
}
.R_col.col div {
  width: 100%;
  overflow-x: hidden;
}
.R_conc_butn.col-lg-2 {
  border-right: 1px solid #999;
  margin-left: -12px;
}

.r_profile {
  width: 300px;
  height: 70px;
  margin-left: 80px;
  position: relative;
  cursor: pointer;
}
.r_uploadPic {
  cursor: pointer;
  color: black;
  background-color: #545554;

  width: 100%;
  height: 100%;
  margin-top: 10px;
  color: white;
  display: flex;
  padding: 15px 5px 0px 10px;
}
.r_Plusimg {
  font-size: 30px;
}
.R_uploadbtn {
  margin: auto;
  background-color: #1a1a1a;
  color: white;
  padding: 2px 12px;
  border-radius: 5px;
  border: none;
  margin-left: 100px;
}
.recon_upload_text {
  font-size: 10px;
  margin-left: 60px;
}
.td_Vendor {
  text-align: center;
}
@media (max-width: 1185px) {
  .R_col.col div {
    width: 100%;
    overflow-x: scroll;
  }
}
@media (max-width: 1024px) {
  .Reconciliation_search-filter {
    height: 120px;
  }
  .R_input_div {
    width: 25%;

    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .Reconciliation_search-filter {
    height: 250px;
  }
  .R_input_div {
    width: 50%;

    flex-direction: column;
  }
  .R_all_cost_btn.col-md-12 {
    border-top: none;
  }
}
@media (max-width: 991px) {
  .R_conc_butn.col-lg-2 {
    border-bottom: none;
    border-right: none;
    margin-left: 0px;
  }
  .R_all_cost_btn.col-md-12 {
    border-top: 1px solid #999;
    margin-top: 15px;
  }
}
@media (max-width: 360px) {
  .table#R_consilition > :not(caption) > * > * {
    overflow-x: scroll;
  }
}

@media (max-width: 476px) {
  .r_profile {
    margin-left: 70px;
    width: 250px;
  }
  .recon_upload_text {
    font-size: 8px;
    margin-left: 40px;
  }
  .R_uploadbtn {
    margin-left: 60px;
  }
}

@media (max-width: 360px) {
  .r_profile {
    margin-left: 50px;
    width: 200px;
  }
  .recon_upload_text {
    font-size: 8px;
    margin-left: 30px;
  }
  .R_uploadbtn {
    margin-left: 50px;
  }
}
/* .RD_recon {
} */
