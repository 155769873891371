.UserleftPane,
.UserRightPane {
  width: 40%;
  height: auto;
  position: relative;
  float: left;
}

.UserRightPane {
  width: 60%;
  height: auto;
  position: relative;
  float: left;
}

/* HIDE RADIO */
.UserleftPane img {
  margin: 15px;
}
