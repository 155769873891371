.MuiTablePagination-toolbar {
  background-color: #f9fcfe;
  margin-bottom: 15px !important;
}

.MuiTablePagination-spacer {
  display: none !important;
  display: block !important;
}

.MuiTablePagination-selectLabel {
  margin-top: 14px;
}

.MuiTablePagination-actions {
  padding: 0px !important;
}
.MuiTablePagination-root p {
  margin-bottom: 0px;
  margin-top: 0;
}
.MuiTablePagination-root .MuiToolbar-root {
  background: #f0efef;
}
@media (min-width: 600px) {
  .MuiTablePagination-root .MuiToolbar-root {
    min-height: 36px;
    padding-right: 2px;
  }
}
