.questionpopup .modal-lg,
.questionpopup .modal-xl {
    --bs-modal-width: 80%;
}

.blur {
    filter: blur(3px);
}

.submitButtonQuestion {
    background-color: black;
    color: white;
    height: 30px;
    width: 70px;
    margin: 10px;
    font-size: 12px;
}
.switchButtonAllInOne .switch .switch-handle {
    border-radius: 0px !important;
}
.givepadding .form-select:focus {
    border-color: none;
    outline: 0;
    box-shadow: none;
}

.buttonPosition {
    display: flex;
    justify-content: flex-end;
    width: auto;
}

#QuestionSelect {
    font-size: 14px;
    border: 1px solid black;
    border-radius: 0px;
    height: 35px;
    width: 100%;
    text-align: center;
    cursor: pointer;
}
.Button_row {
    justify-content: end;
}
.prescreenOneByOne {
    height: 20px;
    width: 200px;
}

@media (min-width: 992px) {
    #questionpopupflex {
        display: flex;
        justify-content: flex-end;
    }
}
#checkkkkk .btn-outline-secondary {
    --bs-btn-hover-bg: white;
}
#checkkkkk .btn-outline-success {
    --bs-btn-hover-border-color: rgb(17, 17, 17);
    --bs-btn-border-color: black;
}
.errormessage {
    font-size: 10px;
    line-height: 1;
    width: 80%;
    color: #fff;
    margin: 28px 14.5%;
    top: auto;
    background-color: #900;
    left: 40px;
    position: absolute;
}
.errormessageForMessageBox {
    font-size: 10px;
    line-height: 1;
    width: 80%;
    color: #fff;
    margin: 150px 14.5%;
    padding: 2px;
    top: auto;
    background-color: #900;
    left: 40px;
    position: absolute;
}
.formiactablestyl {
    margin-bottom: 5px;
}
.formictd {
    width: 1010px;
}
.formicinputOption {
    width: 100%;
    height: 27px;
}
.formicinputtable {
    width: 1010px;
    font-size: 14px;
}
.questionCOuntcss {
    margin: 15px;
}

#noShowVenderFontSIze {
    font-size: 14px;
    height: 30px;
    width: 100%;
    border-radius: 0px;
}
.formicDeleteOPtion {
    margin-left: -20px;
}
.formSkipIcon {
    border: 1px solid black;
    margin: 10px;
    text-align: center;
}
.formiciconlist {
    border: 1px solid black;
    text-align: center;
}
.formicinput {
    width: 100%;
    height: 28px;
}
.modelRTitle {
    font-size: 16px;
    padding-left: 10px;
}
.singleQuestionwidh {
    width: 100%;
}
.dotCircle {
    margin: 2px;
}
.givepadding {
    padding-bottom: 10px;
}
.boxgap {
    height: 20px;
}

.buttonbackground {
    background-color: black;
}

.deleteMOdule {
    font-size: 16px;
}

.prescreenMultiselect {
    height: 30px;
}

.butttonRadious {
    border-radius: 3px;
    border: none;
    font-size: 14px;
}
/* ..................................messageBox...................... */

.messageBoxRow {
    border: '1px solid whitesmoke';
    padding-bottom: 20px;
}
.messageTop {
    margin-top: 10px;
}
.messageNoVender {
    font-size: 12px;
}
#messageMultiselect {
    width: 29px;
    color: red;
}
/* ...................................messageBox...................... */
/* ..................................Openquestinon........................................ */

.openendRow {
    border: 1px solid whitesmoke;
    padding-bottom: 20px;
}

.opendQuestionInput {
    width: 100%;
    font-size: 12px;
}
/* ..................................Openquestinon........................................ */

.addTemplateButton {
    width: 100%;
    height: 35px;
    font-size: 14px;
    background-color: white;
    border-radius: 2px;
    border: 1px solid black;
}
.questionCountCss {
    background-color: whitesmoke;
    height: 30px;
    padding-top: 3px;
}

.showQuestion {
    font-size: 13px;
    margin: 2px;
}
.questionside {
    border: 1px solid whitesmoke;
    padding-bottom: 10px;
}
.addAnswerButton {
    background-color: black;
    color: white;
    height: 30px;
    width: 100px;
    margin: 10px;
}

.switchButtonAllInOne .switch[class*='btn-outline']:hover .switch-handle {
    background-color: #212529;
}
.switchButtonAllInOne {
    padding-right: 20px;
    padding-right: 20px;
    color: #212529;
}
.switchButtonAllInOne .switch.btn-xs {
    width: 107%;
}
.switchButtonAllInOne .switch.btn-outline-danger .switch-handle {
    padding-top: 30px;
    background-color: #212529;
}
.switchButtonAllInOne .switch.btn-outline-success .switch-handle {
    background-color: #212529;
    padding-top: 24px;
}
.spacebutton {
    margin-left: 32px;
}

.headingSelect .switch.btn.btn-outline-danger {
    color: #0519f2;
}
.switchButtonAllInOne .switch.btn-outline-danger,
.switch .btn.btn-outline-danger {
    border-radius: 0px !important;
    height: 35px;
    color: #212529 !important;
}
.switchButtonAllInOne .switch.btn-outline-success,
.switch .btn.btn-outline-success {
    border-radius: 0px !important;
    height: 35px;
}
.switchButtonAllInOne .switch-off.btn-xs {
    font-size: 14px;
    padding-top: 10px;
    color: black;
}
.switchButtonAllInOne .switch-on.btn-xs {
    padding-top: 10px;
    font-size: 14px;
    color: black;
}

.messageBoxTextArea {
    width: 100%;
    height: 150px;

    font-size: 12px;
}

.messageBoxTextArea_height {
    width: 100%;
    height: 30px;

    font-size: 12px;
}
.questionContainer {
    background-color: #f5f3f3;
    color: #817b7b;
    width: 100%;

    padding-bottom: 20px;
    height: auto;
}

.radio-content {
    width: 40px;
    margin: -4px;
}
#prescreen input[type='radio'] {
    display: none;
    visibility: hidden;
}
.containerssssss {
    display: flex;
    margin: 40px auto;
    height: auto;
    width: 500px;
    padding: 20px;
}

#prescreen input[type='radio'] + label {
    color: black;
    cursor: pointer;
    font-size: 20px;
}

#prescreen input[type='radio'] + label span {
    width: 27px;
    height: 27px;
    margin: -2px 5px 0 0;
    border: 1px solid #cecece;
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.2s, transform 0.2s;
}

#prescreen input[type='radio'] + label span:hover,
#prescreen input[type='radio'] + label:hover span {
    transform: scale(1.2);
}

#prescreen input[type='radio']:checked + label span {
    background: #1d976c;
    background: -webkit-linear-gradient(to right, #0534f0, #081ddc);
    background: linear-gradient(to right, #0703e1, #0519f2);
    box-shadow: 0px 0px 0px 5px white inset;
}

#prescreen input[type='radio']:checked + label.skip {
    color: #b006ff;
}
#prescreen input[type='radio']:checked + label.circle {
    color: #0606f5;
}
#prescreen input[type='radio']:checked + label.cross {
    color: #ff0000;
}
#prescreen input[type='radio']:checked + label.play {
    color: #06ff0a;
}

/* input[type="radio"]:checked ~ .check {
  border: 5px solid #0dff92;
}

input[type="radio"]:checked ~ .check::before {
  background: #0dff92;
}

input[type="radio"]:checked ~ label {
  color: #0dff92;
} */

.signature {
    margin: 10px auto;
    padding: 10px 0;
    width: 100%;
}

.signature p {
    text-align: center;
    font-family: Helvetica, Arial, Sans-Serif;
    font-size: 0.85em;
    color: #aaaaaa;
}

.signature .much-heart {
    display: inline-block;
    position: relative;
    margin: 0 4px;
    height: 10px;
    width: 10px;
    background: #ac1d3f;
    border-radius: 4px;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.signature .much-heart::before,
.signature .much-heart::after {
    display: block;
    content: '';
    position: absolute;
    margin: auto;
    height: 10px;
    width: 10px;
    border-radius: 5px;
    background: #ac1d3f;
    top: -4px;
}

.signature .much-heart::after {
    bottom: 0;
    top: auto;
    left: -4px;
}

.signature a {
    color: #aaaaaa;
    text-decoration: none;
    font-weight: bold;
}
.iconBox {
    border: '1px solid red';
}
.questionShow span {
    color: #000;
}

.questionShow.active span {
    color: #0519f2;
}
@media (max-width: 991px) {
    table tbody.respques {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (max-width: 450px) {
    table tbody.respques {
        grid-template-columns: repeat(2, 1fr);
    }
}
