.SupIcon {
  margin: 2px;
  font-size: 15px;
  width: 15px;
  color: #535353;
  cursor: pointer;
}

.addcss .formtextviewzone ~ small.addnew_supply {
  display: block;
  font-size: 10px;
  line-height: 1;
  width: 90%;
  color: #fff;
  margin: 0px 5%;
  padding: 2px;
  top: auto;
  bottom: -6px;
  background-color: #900;
  left: 0px;
  position: absolute;
}
.survey_det_error {
  display: block;
  font-size: 10px;
  line-height: 1;
  width: 90%;
  color: #fff;
  margin: 0px 5%;
  padding: 2px;
  top: auto;
  bottom: -6px;
  background-color: #900;
  left: 0px;
  position: absolute;
}
.addsupply_col {
  position: relative;
}
.SupIcon_notActive {
  padding: 2px 5px;
  font-size: 30px;
  color: white;
  background-color: #999;
  height: 28px;
  cursor: pointer;
}
.user_serachboxSupply {
  width: 100%;
  height: auto;
  padding: 10px 20px 5px;

  text-align: left;
}
.supplyInputBox {
  float: right;
  display: flex;
  margin: 0px 0px;
}
.supplyIconColr {
  padding: 7px 0px;

  width: 30px;
  color: white;
  border: 1px solid #999;
  height: 28px;
}
.supply_filterbox {
  margin: 15px 15px 15px 15px;
}

.table.customTable .country_col {
  width: 10%;
}
.table.customTable td:last-child.sd_action {
  width: 10%;
  max-width: 200px;
}
.sdIcon {
  margin: 0px 1px 0px 5px;
}
.supply_active-badge {
  display: block;
  width: 70px;
  text-align: center;
  color: #ffffff !important;
  background: #55bf35;
  padding: 0px 5px;
  border-radius: 20px;
  font-size: 10px;
  font-weight: 400;
}
.supply_inactive-badge {
  display: block;
  width: 70px;
  text-align: center;
  color: #ffffff !important;
  background: #ff3838;
  padding: 0px 5px;
  border-radius: 20px;
  font-size: 10px;
  font-weight: 400;
}
.ssbtn .switch.btn-outline-success,
.ssbtn .switch .btn.btn-outline-success.btn:active {
  border-radius: 20px !important;
  color: #1a1a1a !important;
  height: 24px;
  border-color: #1a1a1a;
  box-shadow: none;
}
.ssbtn .switch .btn.btn-outline-success.btn,
.ssbtn .switch .btn.btn-outline-success.btn:hover,
.ssbtn .switch .btn.btn-outline-success.btn:active {
  border: none;
}

.ssbtn .switch .btn.btn-outline-danger:hover,
.ssbtn .switch .btn.btn-outline-danger,
.ssbtn .switch .btn.btn-outline-danger:active {
  border: none;
}
.ssbtn .switch .switch-handle {
  border-radius: 50px !important;
  border: 5px solid #ffffff;
  padding-left: 0px !important;
  width: 22px;
  position: relative;
  left: 12px;
  top: 0px;
}
.ssbtn .switch.btn-outline-danger .switch-handle {
  background-color: #555555;
  border: 3px solid #fff !important;
  position: relative;
  left: 12px;
}

.ssbtn .switch.btn-outline-success .switch-handle {
  background-color: #1a1a1a;
  border: 3px solid #fff !important;
  position: relative;
  right: 12px;
  left: auto;
}

.sscolorbtn .switch.btn-outline-danger .switch-handle {
  background-color: #999;
}
.sscolorbtn .switch.btn-outline-success .switch-handle {
  background-color: #1a1a1a;
}

.ssbtn .btn-outline-success:hover,
.ssbtn .btn-outline-danger:hover {
  background-color: #fff;
}
.ssbtn .switch.btn-outline-success:hover .switch-handle {
  background-color: #1a1a1a;
}
.ssbtn .switch.btn-outline-danger:hover .switch-handle {
  background-color: #999;
}
.ssbtn .switch-on.btn-xs {
  padding-right: 25px !important;
  font-weight: normal !important;
  line-height: 1;
  font-size: 12px;
  letter-spacing: 0.5px;
}

.ssbtn .switch-off.btn-xs {
  padding-left: 25px !important;
  font-weight: normal !important;
  line-height: 1;
  font-size: 12px;
  letter-spacing: 0.5px;
}
@media (max-width: 576px) {
  .user_serachboxSupply {
    width: 100%;
    height: auto;
    padding: 10px 20px 5px;
    text-align: left;
  }
  .supplyInputBox {
    width: 60%;
    margin: 0px;
    padding: 5px;
  }
}
@media (max-width: 767px) {
  .supply_col {
    width: 100%;
  }
}

.supplytoggele {
  margin-top: 0px;
}
.supplytoggele_col {
  margin-top: 10px;
}

.supplymaincontent .table.customTablesupply thead td,
.supplymaincontent .table.customTablesupply thead > :not(caption) > * > * {
  padding-right: 0px;
}
.stanBadge {
  border-radius: 20px;
  color: #fff !important;
  font-size: 12px;
  padding: 2px 6px;
}
.validationErrorMsg {
  display: block !important;
  font-size: 10px !important;
  line-height: 1 !important;
  width: 100% !important;
  color: #fff !important;
  padding: 2px !important;
  margin: 0px !important;
  top: auto !important;
  bottom: 5px !important;
  background-color: #900 !important;
  left: 0px !important;
  position: relative !important;
}
