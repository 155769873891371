body .Ptop_container {
  font-family: "Ubuntu", sans-serif;
  letter-spacing: 0.5px;
  line-height: 1.6;
  font-weight: 400;
  font-size: 13px;
  background-color: #f5f3f3;
  /* background-color: #1b1b1b; */
  height: auto;
  padding-bottom: 20px;
}
.supplyPartnerReq {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
}

.projectMargintopzero {
  margin-top: 0px;
}
.projectMarginTop {
  margin-top: 7px;
}
.quotaCssBorder {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
}

.projectTop {
  padding: 3px;
}

.supply_theadfont {
  font-size: 10px;
}
.supplyinforelatin {
  position: relative;
  border-left: 1px solid #f5f3f3;
  border-right: 1px solid #f5f3f3;
}

.Ptop_container {
  display: block;
  /* border: 1px solid red; */
  width: 100%;
  overflow-x: hidden;
  height: auto;
}

.CsformText {
  width: 100%;
  height: 28px;
  padding: 3px 10px;
  border: 1px solid #aaa;
  color: #999;
  border-radius: 1px;
  margin-bottom: 10px;
  font-size: 14px;
  font-family: "Ubuntu", sans-serif !important;
}

.TwoContainer {
  display: flex;

  height: 100%;
}
.TwoContainerH {
  display: flex;

  /* height: 100%; */
}
.PleftContainerH {
  /* border: 1px solid green; */
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-top: 20px;
  /* position: fixed; */
  width: 24%;
  /* height: auto; */
}
.PrightContainerH {
  display: flex;

  margin-top: 20px;
  margin-right: 0px;
  margin-left: 40px;
  width: 100%;
  justify-content: space-between;
  /* height: auto; */
  /* border: 1px solid red; */
}
.pheaderRow {
  display: flex;
  flex-direction: row;
}
.PtopHeaderHide {
  display: none;
}
.projlabel {
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  margin-bottom: 0px;
}
.PtopHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; /* Aligns items vertically center */
  width: 100%;
  padding-left: 10px;
  background-color: #f5f3f3;
}
.PtopHeader > p {
  border-bottom: 2px solid black;
  font-weight: 400;
}
.PtopHeader .left-section > .live {
  margin-bottom: 15px;
  text-align: center;
  color: white !important;
  height: fit-content;
  width: 50px;
  border-radius: 15px;
  margin-left: 20px;
  font-size: 12px;
  font-weight: 400;
  border: none;
  background: linear-gradient(to right, #34b140, #55bf35);
}
.PtopHeader .left-section > .pending {
  margin-bottom: 15px;
  text-align: center;
  /* color: rgb(96, 93, 93) !important; */
  color: #ffffff;
  height: fit-content;
  width: 70px;
  border-radius: 15px;
  margin-left: 20px;
  font-size: 12px;
  font-weight: 400;
  border: none;
  background: linear-gradient(to right, #fce83a, #f9e20b);
}
.PtopHeader .left-section > .testmode {
  margin-bottom: 15px;
  text-align: center;
  color: white !important;
  height: fit-content;
  width: 70px;
  border-radius: 15px;
  margin-left: 20px;
  font-size: 12px;
  font-weight: 400;
  border: none;
  background: linear-gradient(to right, #2dccff, #03bbf8);
}
.PtopHeader .left-section > .paused {
  margin-bottom: 15px;
  text-align: center;
  color: white !important;
  height: fit-content;
  width: 70px;
  border-radius: 15px;
  margin-left: 20px;
  font-size: 12px;
  font-weight: 400;
  border: none;
  background: linear-gradient(to right, #fec53f, #ffb302);
}
.PtopHeader .left-section > .IdAwaited {
  margin-bottom: 15px;
  text-align: center;
  color: white !important;
  height: fit-content;
  width: 70px;
  border-radius: 15px;
  margin-left: 20px;
  font-size: 12px;
  font-weight: 400;
  border: none;
  background: linear-gradient(to right, #a4abb6, #96a6c1);
}
.PtopHeader .left-section > .billed {
  margin-bottom: 15px;
  text-align: center;
  color: white !important;
  height: fit-content;
  width: 70px;
  border-radius: 15px;
  margin-left: 20px;
  font-size: 12px;
  font-weight: 400;
  border: none;
  background: linear-gradient(to right, #b330f5, #aa00ff);
}
.PtopHeader .left-section > .cancelled {
  margin-bottom: 15px;
  text-align: center;
  color: white !important;
  height: fit-content;
  width: 70px;
  border-radius: 15px;
  margin-left: 20px;
  font-size: 12px;
  font-weight: 400;
  border: none;
  background: linear-gradient(to right, #ff3838, #ff0808);
}
.PtopHeader .left-section > p {
  margin-right: -15px;
}
.ProjectDetail_linkIcon {
  width: 50px;
  height: 22px;
  cursor: pointer;
}

.saveclsebtn {
  width: fit-content;

  position: relative;
  float: right;
  top: -10px;
  margin-top: -14px;
}
.csHR {
  width: 100%;
}

.PleftContainer {
  overflow: hidden;
  display: block;
  padding: 20px;
  width: 25%;
  max-width: 25%;
  overflow-x: hidden;
  height: auto;
  float: left;
  min-height: 400px;
}

.PrightContainer {
  display: block;
  margin-top: 20px;
  margin-right: 0px;
  margin-left: 0px;
  background-color: white;
  height: auto;
  /* border: 1px solid red; */
  width: 75%;
  float: left;
}

.PrightHeader {
  margin-top: 10px;

  display: inline-block;
  width: 100%;
  font-size: 14px;
  font-weight: 900;
  padding-left: 5px;

  /* border-bottom: 1px solid #f5f3f3; */
}
.PrightHeader > p {
  display: inline;
  font-size: 14px;
  cursor: pointer;
}
.activeBorder {
  border-bottom: 2px solid black;
  padding-bottom: 5px;
  height: 10px;
}

.Pformtext {
  width: 30px;
}
.PrightLower {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  /* margin: auto; */
  /* margin-top: 20px; */
  /* margin-bottom: 20px; */
  padding: 15px;
}

.Pheading {
  background-color: #929594;
  width: 98%;
  margin: auto;
  color: #f5f3f3;
  margin-left: 12px;

  /* margin-left: 12px; */
  font-weight: bolder;
}
.ScreeningHead {
  background-color: #f5f3f3;
  margin-top: 10px;
  height: 30px;
  padding: 5px 10px;
  position: relative;
  justify-content: space-between;
}
.second > .ScreeningHead {
  margin-top: 20px;
}
.ScreeningHead > p {
  width: 20%;
  float: left;
  margin-left: 0px;
  line-height: 1;
}

.ScreeningBtn {
  display: inline-block;
  width: auto;

  color: black;
  margin-right: 6px;

  /* text-align: right; */
  height: 0px;
}
.ScreeningBtn img {
  height: 12px;
}
.rankPain {
  /* width: 80%; */
  float: left;
  text-align: right;
  padding-right: 10px;
}
/* .liveBtn {
  float: right;
  border-radius: 30px;
  height: 20px;
  border: none;
  background: linear-gradient(to right, #34b140, #55bf35);
  color: white;
  font-size: 12px;
  padding: 0px 15px 1px 15px;
  margin-right: 5px;
} */
.liveBtn {
  float: right;
  border-radius: 30px;
  height: 20px;
  border: none;
  background: linear-gradient(to right, #34b140, #55bf35);
  color: white;
  font-size: 12px;
  padding: 0px 15px 1px 15px;
  margin-right: 5px;
}
.pendingBtnagain {
  float: right;
  border-radius: 30px;
  height: 20px;
  border: none;
  background: linear-gradient(to right, #fce83a, #f9e20b);
  /* color: rgb(96, 93, 93); */
  color: #ffffff;
  font-size: 12px;
  padding: 0px 15px 1px 15px;
  margin-right: 5px;
}
.testmodeBtnagain {
  float: right;
  border-radius: 30px;
  height: 20px;
  border: none;
  background: linear-gradient(to right, #2dccff, #03bbf8);
  color: white;
  font-size: 12px;
  /* padding: 0px 15px 1px 15px; */
  margin-right: 5px;
  padding: 0px 6px;
  white-space: nowrap !important;
}
.pausedBtnagain {
  float: right;
  border-radius: 30px;
  height: 20px;
  border: none;
  background: linear-gradient(to right, #fec53f, #ffb302);
  color: white;
  font-size: 12px;
  padding: 0px 15px 1px 15px;
  margin-right: 5px;
}
.IdAwaitedBtnagain {
  float: right;
  border-radius: 30px;
  height: 20px;
  border: none;
  background: linear-gradient(to right, #a4abb6, #96a6c1);
  color: white;
  font-size: 12px;
  /* padding: 0px 15px 1px 15px; */
  margin-right: 5px;
  padding: 0px 6px;
  white-space: nowrap !important;
}
.billedBtnagain {
  float: right;
  border-radius: 30px;
  height: 20px;
  border: none;
  background: linear-gradient(to right, #b330f5, #aa00ff);
  color: white;
  font-size: 12px;
  padding: 0px 15px 1px 15px;
  margin-right: 5px;
}
.cancelledBtnagain {
  float: right;
  border-radius: 30px;
  height: 20px;
  border: none;
  background: linear-gradient(to right, #ff3838, #ff0808);
  color: white;
  font-size: 12px;
  padding: 0px 15px 1px 15px;
  margin-right: 5px;
}

.PSaveBtn {
  border-radius: 30px;
  width: 60px;
  border: none;
  /* border: 1px solid red; */
  background: black;
  color: white;
  margin-right: 10px;
  height: 20px;
  margin-top: 1px;
  float: right;
  font-size: 12px;
  padding: 0px 0px 1px 0px;
}

.PauseBtn {
  border-radius: 30px;
  width: 60px;
  border: none;
  background: linear-gradient(to right, #e17e2c, #f4a253);
  color: white;
  margin-right: 6px;
  height: 20px;
  margin-top: 1px;
  float: right;
  /* border: 1px solid red; */
  font-size: 12px;
  text-align: center;
  padding: 0px 1px 1px 1px;
}
#boxContainerBorder {
  /* border: 1px solid whitesmoke !important; */
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
}

.PrightLower > .first {
  /* border: 1px solid black; */
  width: 100%;
  margin: auto;
}

.PrightLower > .first > p {
  background-color: #55bf35;
}

.PrightLower > .second {
  width: 95%;
  margin: auto;
}

.pswtchbtn {
  text-align: right;
  padding-right: 30px;
  margin-top: 10px;
  /* padding-top: 16px; */
  /* border: 1px solid red; */
}
.projectswitchbtn > .switch.off .switch-group {
  left: -100%;
}
.PrightHeader .threeLine {
  display: none;
}
.SrightCont {
  width: 100%;
}
.Sfirstbox {
  padding: 5px 15px;
}

.SArrowHeead {
  height: 30px;
  background-color: #f5f3f3;

  display: flex;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
}
.STable {
  display: none;
}
.STable.open {
  display: block;
}
.dropeTable {
  display: none;
}
.dropeTable.open {
  display: table-row;
}
.dropeTablesupply {
  display: none;
}
.dropeTablesupply.open {
  display: block;
}
.IconUp {
  position: absolute;
  font-size: 20px;
  right: 50px;
}
.DropIcon {
  position: absolute;
  font-size: 20px;
  right: 50px;
}
.SIconUp {
  position: relative;
  left: auto;
  right: 5;
  top: 5;
  cursor: pointer;
}
.SDropIcon {
  position: relative;
  left: auto;
  right: 5;
  top: 5;
  cursor: pointer;
}
.SArrowHeead .SDropIcon {
  display: block;
  cursor: pointer;
}
.SIconUpnew {
  position: relative;
  left: auto;
  right: 5;
  top: 5;
  cursor: pointer;
}
.SArrowHeead .SIconUpnew {
  display: none;
}
.SArrowHeead.open .SIconUpnew {
  display: block;
  cursor: pointer;
}
.SArrowHeead .SIconUp {
  display: none;
}
.SArrowHeead.open .SIconUp {
  display: block;
  cursor: pointer;
}
.SDropIconNew {
  position: relative;
  left: auto;
  right: 5;
  top: 5;
  cursor: pointer;
}
.SArrowHeead .SDropIconNew {
  display: block;
  cursor: pointer;
}
.SArrowHeead.open .SDropIconNew {
  display: none;
}

.QArrowHeead .DropIcon {
  display: block;
}
.QArrowHeead .IconUp {
  display: none;
}
.QArrowHeead.open .IconUp {
  display: block;
}
.QArrowHeead.open .DropIcon {
  display: none;
}

.quotasubBorderColor {
  background-color: #fcfbfb;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  margin: 0px;
}
.quotaHead {
  height: 35px;
  background-color: #f5f3f3;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
}
.quotaBtn {
  text-align: center;
  color: white;
  width: 40px;
  border-radius: 15px;
  font-size: 10px;
  font-weight: 400;
  margin: 0px;
  border: none;
  background: linear-gradient(to right, #34b140, #55bf35);
}
.quotapendingBtnagain {
  margin: 0px;
  text-align: center;
  /* color: rgb(108, 103, 103); */
  color: #ffffff;
  width: 70px;
  border-radius: 15px;
  font-size: 10px;
  font-weight: 400;
  border: none;
  background: linear-gradient(to right, #fce83a, #f9e20b);
}
.quotatestmodeBtnagain {
  margin: 0px;
  text-align: center;
  color: white;
  width: 70px;
  margin: 2px;
  border-radius: 15px;
  font-size: 10px;
  font-weight: 400;
  border: none;
  background: linear-gradient(to right, #2dccff, #03bbf8);
}
.quotapausedBtnagain {
  text-align: center;
  color: white;
  width: 70px;
  border-radius: 15px;
  font-size: 10px;
  font-weight: 400;
  border: none;
  margin: 0px;
  background: linear-gradient(to right, #fec53f, #ffb302);
}
.quotaIdAwaitedBtnagain {
  text-align: center;
  color: white;
  width: 70px;
  border-radius: 15px;
  font-size: 10px;
  font-weight: 400;
  border: none;
  background: #a4abb6;
  margin: 0px;
}
.quotabilledBtnagain {
  text-align: center;
  color: white;
  width: 70px;
  border-radius: 15px;
  font-size: 10px;
  font-weight: 400;
  border: none;
  margin: 0px;
  background: linear-gradient(to right, #b330f5, #aa00ff);
}

.quotacancelledBtnagain {
  text-align: center;
  color: white;
  width: 70px;
  border-radius: 15px;
  font-size: 10px;
  font-weight: 400;
  border: none;
  margin: 0px;
  background: linear-gradient(to right, #ff3838, #ff0808);
}

.showHeader {
  /* display: inline-block; */
  /* margin-top: 10px; */
  width: 100%;
  /* border: 1px solid green; */
}
.showHeader > p {
  display: inline;
}
.csSearchIcon {
  margin-right: 4px;
  width: 20px;
  height: max-content;
  margin-top: 2px;
}
.SupplyIcon {
  font-size: 20px;
  width: 15px;
  color: #535353;
  margin: 2px;
  cursor: pointer;
}

#MainMenuCs {
  display: none;
}

div.col .perrmsg {
  bottom: 0px;
}
.prj_rowone {
  /* margin-left: 10px; */
  padding: 0px;
  margin-top: 15px;
  width: 100%;
  margin: auto;
}
.prj_rowtwo {
  margin: 0px !important;

  margin-left: 2px;
  width: 100%;
  margin-top: 5px;
}
/* .MuiSvgIcon-root */
/* .MuiSvgIcon-fontSizeMedium */
/* .css-i4bv87-MuiSvgIcon-root */
.pjt_arrow_icon .css-i4bv87-MuiSvgIcon-root {
  position: absolute;
  left: auto;
  right: 5px;
  top: 5px;
}

.pjt_arrow_icon .css-vubbuv {
  position: absolute;
  left: auto;
  right: 5px;
  top: 5px;
}
.pBlockHead {
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: bolder;
}
/* ==================== react single select======================= */

.css-1s2u09g-control {
  background-color: #ffffff;
  color: #555;
  cursor: default;
  display: block;
  font-size: inherit;
  padding: 00px;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  max-height: 28px;
}
.pjt_select .css-1s2u09g-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  border-color: #aaa;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 28px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: none;
  transition: none;
  box-sizing: border-box;
}

.pjt_select .css-1pahdxg-control:hover {
  border-color: #aaa;
}
.pjt_select .css-1pahdxg-control,
.css-1pahdxg-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: #aaa;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 0 0 1px #2684ff00;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  max-height: 28px;
  min-height: 28px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: none;
  transition: none 100ms;
  box-sizing: border-box;
}

.pjt_select .css-tlfecz-indicatorContainer,
.pjt_select .css-1gtu0rj-indicatorContainer,
.pjt_select .css-1hb7zxy-IndicatorsContainer {
  padding: 2px 2px;
}
.pjt_select .css-qc6sy-singleValue {
  color: #999;
  font-size: 14px;
}
.pjt_select .css-1pahdxg-control .css-6j8wv5-Input {
  margin: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
  visibility: visible;
  color: hsl(0, 0%, 20%);
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: inline-grid;
  grid-area: 1/1/2/3;
  grid-template-columns: 0 min-content;
  box-sizing: border-box;
  font-size: 14px;
}

.css-6j8wv5-Input:active,
.css-6j8wv5-Input:focus,
.css-1pahdxg-control {
  outline: none;
  border: none;
}
.css-14el2xx-placeholder {
  color: #999 !important;
  grid-area: 1/1/2/3;
  margin-left: 2px;
  margin-right: 2px;
  box-sizing: border-box;
  font-size: 14px !important;
}

.pjt_select .css-26l3qy-menu {
  top: 100%;
  background-color: hsl(0, 0%, 100%);
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  margin-bottom: 2px;
  margin-top: 0px;
  position: absolute;
  width: 100%;
  z-index: 999 !important;
  box-sizing: border-box;
}
.pjt_select .css-6j8wv5-Input {
  margin: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
}

.pjt_select .css-4ljt47-MenuList {
  max-height: 160px;
  overflow-y: auto;
  padding-bottom: 1px;
  padding-top: 1px;
  position: relative;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
}
.pjt_select .css-11unzgr {
  max-height: 160px;
}

.pjt_select .css-yt9ioa-option,
.pjt_select .css-1n7v3ny-option {
  padding: 2px 12px;
}

.pjt_select .select__menu .css-b62m3t-container {
  /* font-size: 13px!important; */
  color: red !important;
}
.pjt_select .css-319lph-ValueContainer {
  color: #999;
}
.respselect .css-1s2u09g-control {
  min-width: 105px;
}
/* ==================== react single select======================= */

.quotaHead .css-bsph2u-MuiTableCell-root {
  /* font-family: "Roboto", "Helvetica", "Arial", sans-serif  */
  font-family: "Ubuntu", sans-serif !important;
  vertical-align: middle;
  padding: 4px 7px;
  font-size: 13px;
}
.project_quality_closeModal {
  position: fixed;
  right: 0px;
  left: auto;
  top: 0px;
  border: 2px solid #ccc;
  background-color: #ccc;
  width: 26px;
  height: 26px;
  line-height: 1;
}
@media (max-width: 1224px) {
  .PleftContainer {
    width: 25%;
    margin: 20px auto;
  }
  .PrightContainer {
    margin: 20px auto;
  }

  .PleftContainerH {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-top: 20px;
    /* position: fixed; */
    width: 25%;
    /* height: auto; */
  }
  .PrightContainerH {
    display: flex;

    margin-top: 20px;
    margin-right: 0px;
    margin-left: 40px;
    width: 70%;
    justify-content: space-between;
    /* height: auto; */
    /* border: 1px solid red; */
  }
}
@media (max-width: 1112px) {
  .PrightHeader {
    /* margin-top: 10px; */
    /* border: 1px solid red; */
    display: inline-block;
    width: 100%;
    font-size: 14px;
    padding-left: 5px;

    /* border-bottom: 1px solid #f5f3f3; */
  }
  .csHR {
    display: none;
  }
  .wrapperBelow {
    /* margin-top: 50px; */
    padding-bottom: 5px;
  }
  .saveclsebtn {
    right: 10px;
    top: 20px;
  }
  .saveclsebtn.mSet {
    top: -25px !important;
  }
  .supply_btn {
    top: -25px;
  }
  .showHeader {
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #d0d1d2;
  }
  .pswtchbtn {
    padding-right: 20px;
  }
  .pswtchleft {
    padding-left: 20px;
    width: 50%;
    float: left;
    /* border: 1px solid red; */
    text-align: left;
    /* margin-bottom: -5px; */
    margin-top: 0px;
    padding-top: 16px;
  }
  .Sfirstbox {
  }
}

@media (max-width: 768px) {
  .Ptop_container {
    width: 100%;
  }
  .TwoContainer {
    width: 100%;
    display: flex;
    flex-direction: column;

    height: auto;
  }
  .PleftContainer {
    display: none;
  }
  .PleftContainer.open {
    display: block;
    width: 100%;
    max-width: 100%;
    margin: 10px auto;
    /* background-color: #fff;
    position: absolute;
    z-index: 999; */
  }
  .PleftContainerH {
    display: none;
  }
  .PrightContainerH {
    display: flex;

    margin-top: 20px;
    margin-right: 0px;
    margin-left: 40px;
    width: 100%;
    justify-content: space-between;
    /* height: auto; */
    /* border: 1px solid red; */
  }
  .pbtn {
    margin-left: 5px;
  }

  .PrightContainer {
    width: 92%;

    margin-left: 25px;
    margin-right: 0px;
    margin-top: 10px;
  }

  .PheaderBtn {
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
    position: relative;
  }
  .PtopHeaderHide {
    display: flex;
    width: 70%;
    margin-top: 20px;
    margin-left: 20px;
    flex-direction: row;
  }
  .PtopHeaderHide .left-section > p {
    border-bottom: 2px solid black;
    font-weight: 400;
    margin-left: 5px;
  }
  .PtopHeaderHide .left-section > .live {
    margin-bottom: 10px;
    text-align: center;
    color: white !important;
    height: fit-content;
    width: 50px;
    border-radius: 15px;
    margin-left: 20px;
    font-size: 12px;
    font-weight: 400;
    border: none;
    background: linear-gradient(to right, #34b140, #55bf35);
  }
  .PtopHeaderHide .left-section > .pending {
    margin-bottom: 10px;
    text-align: center;
    /* color: rgb(96, 93, 93) !important; */
    color: #ffffff;
    height: fit-content;
    width: 70px;
    border-radius: 15px;
    margin-left: 20px;
    font-size: 12px;
    font-weight: 400;
    border: none;
    background: linear-gradient(to right, #fce83a, #f9e20b);
  }
  .PtopHeaderHide .left-section > .testmode {
    margin-bottom: 10px;
    text-align: center;
    color: white !important;
    height: fit-content;
    width: 70px;
    border-radius: 15px;
    margin-left: 20px;
    font-size: 12px;
    font-weight: 400;
    border: none;
    background: linear-gradient(to right, #2dccff, #03bbf8);
  }
  .PtopHeaderHide .left-section > .paused {
    margin-bottom: 10px;
    text-align: center;
    color: white !important;
    height: fit-content;
    width: 70px;
    border-radius: 15px;
    margin-left: 20px;
    font-size: 12px;
    font-weight: 400;
    border: none;
    background: linear-gradient(to right, #fec53f, #ffb302);
  }
  .PtopHeaderHide .left-section > .IdAwaited {
    margin-bottom: 10px;
    text-align: center;
    color: white !important;
    height: fit-content;
    width: 70px;
    border-radius: 15px;
    margin-left: 20px;
    font-size: 12px;
    font-weight: 400;
    border: none;
    background: linear-gradient(to right, #a4abb6, #96a6c1);
  }
  .PtopHeaderHide .left-section > .billed {
    margin-bottom: 10px;
    text-align: center;
    color: white !important;
    height: fit-content;
    width: 70px;
    border-radius: 15px;
    margin-left: 20px;
    font-size: 12px;
    font-weight: 400;
    border: none;
    background: linear-gradient(to right, #b330f5, #aa00ff);
  }
  .PtopHeaderHide .left-section > .cancelled {
    margin-bottom: 10px;
    text-align: center;
    color: white !important;
    height: fit-content;
    width: 70px;
    border-radius: 15px;
    margin-left: 20px;
    font-size: 12px;
    font-weight: 400;
    border: none;
    background: linear-gradient(to right, #ff3838, #ff0808);
  }
  .PtopHeaderHide .left-section {
    display: flex;
    align-items: center; /* Aligns items vertically center */
  }

  .PtopHeaderHide .ClientLinkIcon {
    margin-right: 10px; /* Optional, to add some space from the right edge */
  }

  .PtopHeader {
    display: none;
  }

  .ScreeningHead {
    /* border: 1px solid red; */
    height: 65px;
    padding: 5px 10px;
    position: relative;
  }

  .rankPain {
    width: 100%;
    text-align: left;
  }

  .ScreeningHead > p {
    float: left;
    width: 100%;
    /* border: 1px solid blue; */
  }
  .ScreeningBtn {
    float: left;

    margin-top: 0px;

    text-align: left;
    margin-right: 0px;
  }
}
@media (max-width: 960px) {
  .ScreeningHead {
    height: 65px;
    padding: 5px 10px;
    position: relative;
  }

  .rankPain {
    width: 100%;
    text-align: left;
  }

  .ScreeningHead > p {
    float: left;
    width: 100%;
    /* border: 1px solid blue; */
  }
  .ScreeningBtn {
    float: left;

    margin-top: 0px;

    text-align: left;
    margin-right: 0px;
  }
}

@media (max-width: 1180px) {
  .PrightHeader {
    display: none;
  }

  #MainMenuCs {
    display: block;
    width: 50%;
    padding: 6px 3px;
    margin-top: 10px;
    margin-left: 15px;
    /* margin-bottom: 10px; */
    border: 1px solid #999999;
  }
}
@media (max-width: 560px) {
  .PleftContainer {
    width: 90%;
    margin: 10px auto;
  }
  .PleftContainerH {
    display: none;
  }
  .PrightContainerH {
    display: flex;
    flex-direction: column;

    margin: 10px auto;
    width: 100%;
    /* justify-content: center; */
    align-items: center;
    /* height: auto; */
    /* border: 1px solid rgb(85, 5, 5); */
  }
  .PtopHeaderHide {
    display: flex;
    width: 70%;
    margin-top: 20px;
    margin-left: 20px;
    flex-direction: row;
  }
  .PtopHeaderHide > p {
    border-bottom: 2px solid black;
    font-weight: 400;
    margin-left: 5px;
  }
  .PtopHeaderHide > button {
    margin-top: 3px;
    text-align: center;
    color: white;
    height: fit-content;
    width: 50px;
    border-radius: 15px;
    margin-left: 20px;
    font-size: 12px;
    font-weight: 400;
    border: none;

    background: linear-gradient(to right, #34b140, #55bf35);
  }

  .PtopHeader {
    display: none;
  }
  .saveclsebtn {
    width: fit-content;

    padding-left: 20px;
  }
  .pbtn {
    margin-left: 10px;
  }
  .SArrowHeead {
    margin-top: 20px;
    width: 150%;
  }

  .Sfirstbox {
    overflow: scroll;
    width: 95%;
  }

  .DropIcon {
    position: relative;
    right: 10px;
  }
  .IconUp {
    position: relative;
    right: 10px;
  }
  .STable {
    width: 150%;
  }
}
.firstone .csmulti .pjt_select .css-26l3qy-menu {
  top: auto !important;
  bottom: 28px;
}
.m-l-0 {
  margin-left: 0px;
}
.m-0 {
  margin: 0px;
}
.projbtns {
  grid-gap: 12px;
  display: flex;
  /* flex-direction: row-reverse; */
  /* padding-right: 25px !important; */
  padding: 0px 25px !important;
  align-items: center;
  justify-content: end;
}
.SArrowHeead .right-area {
  display: flex;
  align-items: center;
  gap: 20px;
}
.SArrowHeead .right-area a {
  display: flex;
  gap: 3px;
}
.icon-style a {
  cursor: pointer;
  font-size: 20px;
}
.switch .switch-handle {
  border-radius: 50px !important;
  border: 5px solid #ffffff;
  padding-left: 0px !important;
  background-color: #999;
  width: 22px;
  position: relative;
  left: 12px;
  top: 1px;
}
.PrightContainer .switch.btn-outline-danger .switch-handle,
.modal-body .switch.btn-outline-danger .switch-handle {
  background-color: #ff3838;
  border: 3px solid #fff !important;
  position: relative;
  left: 12px;
  top: 1px;
}

.PrightContainer .switch.btn-outline-success .switch-handle,
.modal-body .switch.btn-outline-success .switch-handle {
  background-color: #00a31b;
  border: 3px solid #fff !important;
  position: relative;
  right: 12px;
  top: 1px;
  left: auto;
}

.btn-outline-success:hover,
.btn-outline-danger:hover {
  background-color: #fff;
}
.PrightContainer .switch.btn-outline-success:hover .switch-handle,
.modal-body .switch.btn-outline-success:hover .switch-handle {
  background-color: #00a31b;
}
.PrightContainer
  .switch.btn-outline-danger:hover
  .switch-handle
  .modal-body
  .switch.btn-outline-danger:hover
  .switch-handle {
  background-color: #ff3838;
}
.switch-on.btn-xs {
  padding-right: 25px !important;
  font-weight: normal !important;
  line-height: 1;
  font-size: 12px;
  letter-spacing: 0.5px;
}

.switch-off.btn-xs {
  padding-left: 25px !important;
  font-weight: normal !important;
  line-height: 1;
  font-size: 12px;
  letter-spacing: 0.5px;
}

.boxShadow-none {
  box-shadow: none !important;
}
.cloneContainer {
  padding: 2px 15px;
}
.hoverBgColorGrey:hover {
  background-color: rgb(153, 153, 153) !important;
  color: #ffffff !important;
}
.hoverBgColorBlack:hover {
  background-color: #1a1a1a !important;
  color: #ffffff !important;
}
.hover-bgColor-none:hover:focus {
  background-color: #1a1a1a !important;
  border: none;
}

.PtopHeader .left-section {
  display: flex;
  align-items: center; /* Aligns items vertically center */
}

.ClientLinkIcon {
  margin-right: 10px; /* Optional, to add some space from the right edge */
  margin-bottom: 10px;
}
