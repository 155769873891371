
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #aaa;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color:#aaa;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #aaa;
  }
.login_container {
    margin: auto;
    margin-top: 15vh;
    display: flex;
    flex-direction: column;
    width: 70%;
    /* height: 70vh;  */
    border-radius: 5px;
    box-shadow: 0px 0px 25px rgba(173, 173, 173, 0.2);
    text-align: center;
    vertical-align: middle;
    justify-content: center;
}

.login_torfacimg {

    float: right;
    width: 80px;
    margin-top: 20px;
    margin-right: 20px;

}

.login_samplicity_logo {
    height: 70px;
}
.login_form{
    width:100%;
    max-width: 470px;
    height: auto;
    margin:10px auto
}
.login_form form{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.input-text{
    width:100%;
    max-width: 400px;
    height:38px;
    padding:10px 15px 10px 55px;
    background:#fff;
    border-radius:5px;    
    border:1px solid #fff;
    margin:15px 0 0;
    font-size: 14px;
    line-height: 1.2;
    color:#999;    
    box-shadow: 0px 15px 35px rgba(136, 136, 136, 0.2);
    background: 20px 6px no-repeat ;
    background-size: 22px;
    font-family: 'Ubuntu', sans-serif;
    letter-spacing: 1px;
}
#loginbutton {
    background-image: linear-gradient(to bottom right, #474747, #1a1a1a);
    margin-top: 10px;
    margin: auto;
    color: white;
    border-radius: 5px;
    width: 180px;
    height: 40px;
    padding: 10px;
    box-shadow: 0px 21px 12px 0px rgba(172, 172, 172, 0.2);
    border: 2px solid transparent;
    margin-top: 10px;
    cursor: pointer;
    line-height: 1;
    position: relative;
}
#loginbutton:disabled{
   
    cursor:no-drop;
}

.login_forget_text{
    margin:20px  auto;
    cursor: pointer;
    color:#555 ;
}
p.errorMessage{
    font-size:13px;
    color:#900;
    min-height: 15px;
    margin:3px 0;
    text-align: center;
}
.msg{
    text-align: center;
    width:70%;
    margin:28px auto 15px;
    color:#900;
}
@media(max-width:768px){
    .login_samplicity_logo {
        height: 60px;
        margin-top:10px;
    }
    .login_container {
        margin: auto;
        margin-top: 50px;
        width: 80%;
    }

    .login_form{
        width:96%;
        max-width: 400px;
    }
    
    .input-text{
        width:90%;
        max-width: 250px;       
    }

}
@media(max-width:450px){
    .login_container {
        margin: auto;
        margin-top: 50px;
        width: 95%;
    }
}


.errmsg {
    font-size: 11px;
    line-height: 1.2;
    width: 70%;
    color: #f00;
    margin: 0px;
    padding: 0px;
    top: 5px;
    position: relative;
  }
  
  
  
  .login_form div.col {
    position: relative;
  }
  .login_form div.col .errmsg {
    font-size: 10px;
    line-height: 1;
    width: 80%;
    color: #fff;
    margin: 0px 10%;
    padding: 2px;
    top: auto;
    bottom: -6px;
    background-color: #900;
    left: 0px;
    position: absolute;
  }
  
  
