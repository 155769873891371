.Supply_to_account {
  font-weight: 900;
  margin-top: 30px;
  width: 90%;
  margin: 10px 5% 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Supply_to_account span {
  font-size: 14px;
  cursor: pointer;
  padding-bottom: 5px;
  border-bottom: 2px solid transparent;
  margin: 10px 25px;
  color: #555;
}
.Supply_to_account span:hover {
  color: #1a1a1a;
  border-bottom: 2px solid #1a1a1a;
}
.Supply_to_account span.active {
  color: #1a1a1a;
  border-bottom: 2px solid #1a1a1a;
}
