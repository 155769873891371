.apr_edit_switcbtn {
  text-align: left;
  margin-top: 0px;
}
.apr_loctionP {
  margin: auto;
  background-color: #f2f0f0;
  font-size: small;
  padding: 1px 1px 1px 10px;
  font-weight: bolder;
}
.apr_submitbtn {
  display: flex;
  justify-content: flex-end;
}
.apr_errmsg {
  font-size: 11px;
  line-height: 1;
  width: 100%;
  color: #fff;
  margin: 0px;
  padding: 0px;
  top: auto;
  position: relative;
  background-color: #900;
  /* margin: 0px 5%; */
  bottom: 15px;
  display: inline-block;
}
