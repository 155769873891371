.modal-backdrop.show,
.modal {
  z-index: 99;
}
.targetMain {
  margin-top: 100px;
}
.react-confirm-alert-overlay {
  z-index: 99999;
}
