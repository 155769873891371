.flex {
  display: flex;
}
.flex-gap {
  gap: 5px;
}
.rowGap-2 {
  row-gap: 2px;
}

.items-center {
  align-items: center;
}
.items-end {
  align-items: end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-evenly {
  justify-content: space-evenly;
}
.flex-direction {
  flex-direction: column;
}
.live {
  /* color: #55bf35 !important; */
  color: #ffffff !important;
  background: #55bf35;
  padding: 2px 6px;
  border-radius: 20px;
  font-size: 12px;
}

.pending {
  /* color: #fce83a !important; */
  color: #ffffff !important;
  background: #fce83a;
  padding: 2px 6px;
  border-radius: 20px;
  font-size: 12px;
}
.testmode {
  /* color: #2dccff !important; */
  color: #ffffff !important;
  background: #2dccff;
  padding: 2px 6px;
  border-radius: 20px;
  font-size: 12px;
}
.Paused {
  /* color: #ffb302 !important; */
  color: #ffffff !important;
  background: #ffb302;
  padding: 2px 6px;
  border-radius: 20px;
  font-size: 12px;
}
.IdAwaited {
  /* color: #a4abb6 !important; */
  color: #ffffff !important;
  background: #a4abb6;
  padding: 2px 6px;
  border-radius: 20px;
  font-size: 12px;
}
.billed {
  /* color: #aa00ff !important; */
  color: #ffffff !important;
  background: #aa00ff;
  padding: 2px 6px;
  border-radius: 20px;
  font-size: 12px;
}
.cancelled {
  /* color: #ff3838 !important; */
  color: #ffffff !important;
  background: #ff3838;
  padding: 2px 6px;
  border-radius: 20px;
  font-size: 12px;
}
.pointer {
  cursor: pointer;
}
.overflow-x {
  overflow-x: auto;
}
/*---------------------------Content Wrapper Start--------------------------*/
.containerbox {
  max-width: 97%;
  /* padding: 0 15px; */
  margin: 0 auto;
  padding-bottom: 2rem;
}

section.content_wrapper {
  background: #f2f0f0;
}

section.content_wrapper .containerbox {
  padding-top: 10px;
}

section.content_wrapper .containerbox .data_wrapper {
  background: #ffffff;
  /* padding: 1rem 2rem; */
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 8px;
}

/*---------------------------Inner Header Start--------------------------*/
section.content_wrapper .containerbox .data_wrapper .inner-header {
  /* padding-bottom: 1rem; */
  padding: 1rem;
}

section.content_wrapper
  .containerbox
  .data_wrapper
  .inner-header
  .inner-left
  h3 {
  font-size: 16px;
}
section.content_wrapper
  .containerbox
  .data_wrapper
  .inner-header
  .inner-left
  img {
  width: 44px;
  margin-right: 10px;
}
@media (max-width: 668px) {
  section.content_wrapper
    .containerbox
    .data_wrapper
    .inner-header
    .inner-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  section.content_wrapper
    .containerbox
    .data_wrapper
    .inner-header
    .inner-right
    .add-survey-btn {
    margin-bottom: 10px;
  }
}
@media (max-width: 450px) {
  section.content_wrapper .containerbox .data_wrapper .inner-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  section.content_wrapper
    .containerbox
    .data_wrapper
    .inner-header
    .inner-right {
    align-items: flex-start;
  }
  section.content_wrapper
    .containerbox
    .data_wrapper
    .inner-header
    .inner-right
    .add-survey-btn {
    margin-top: 1rem;
  }
  section.content_wrapper
    .containerbox
    .data_wrapper
    .inner-header
    .inner-right
    .action-icons {
    margin-left: 0px !important;
  }
}
section.content_wrapper
  .containerbox
  .data_wrapper
  .inner-header
  .inner-right
  .add-survey-btn {
  padding: 7px 9px;
  background: #1a1a1a;
  border: none;
  border-radius: 4px;
}
section.content_wrapper
  .containerbox
  .data_wrapper
  .inner-header
  .inner-right
  .add-survey-btn
  a {
  color: #ffffff;
  text-decoration: none;
}
section.content_wrapper
  .containerbox
  .data_wrapper
  .inner-header
  .inner-right
  .add-survey-btn
  a
  i {
  margin-right: 6px;
}

/*--------------icons start-------------*/
section.content_wrapper
  .containerbox
  .data_wrapper
  .inner-header
  .inner-right
  .action-icons {
  border: 1px solid #f2f0f0;
  margin-left: 2rem;
}
section.content_wrapper
  .containerbox
  .data_wrapper
  .inner-header
  .inner-right
  .action-icons
  a {
  border-right: 1px solid #f2f0f0;
  padding: 7px 14px;
  color: #1a1a1a;
}
section.content_wrapper
  .containerbox
  .data_wrapper
  .inner-header
  .inner-right
  .action-icons
  i {
  font-size: 15px;
}
section.content_wrapper
  .containerbox
  .data_wrapper
  .inner-header
  .inner-right
  .action-icons
  a:last-child {
  border: none;
}
/*-----------------------------------Inner Header Close-------------------------------------*/

/*-----------------------------------Search Filter Start-------------------------------------*/
section.content_wrapper .containerbox .data_wrapper .search-filter {
  background: #f2f0f0;
  padding: 8px;
  font-size: 13px;
  margin-bottom: 16px;
  /* display: none; */
}
section.content_wrapper .containerbox .data_wrapper .search-filter .sf {
  /* margin: 0 auto; */
  grid-row-gap: 10px;
}
/* section.content_wrapper
  .containerbox
  .data_wrapper
  .search-filter
  .input-row-child {
  width: 250px;
} */
section.content_wrapper .containerbox .data_wrapper .search-filter .subbtn {
  flex-direction: row-reverse;
  flex: 1;
  align-items: flex-end;
  /* padding: 0 1%; */
}
section.content_wrapper .containerbox .data_wrapper .search-filter .submit-btn {
  padding: 3px 10px;
  background: #1a1a1a;
  border: none;
}
section.content_wrapper .containerbox .data_wrapper .search-filter .subbtn a {
  text-decoration: none;
  color: #ffffff;
}
section.content_wrapper .containerbox .data_wrapper .search-filter input {
  font-family: "Ubuntu", sans-serif;
  border: 1px solid #aaa;
  border-radius: 2px;
  color: #999;
  line-height: 26px;
  padding: 0px 10px;
  font-size: 13px;
}
section.content_wrapper .containerbox .data_wrapper .search-filter input:focus {
  outline: none;
}
section.content_wrapper .containerbox .data_wrapper .search-filter label {
  margin-bottom: 4px;
}
section.content_wrapper .containerbox .data_wrapper .search-filter select {
  padding: 4px;
  font-family: "Ubuntu", sans-serif;
  border: 1px solid #ccc;
  border-radius: 2px;
  color: #1a1a1a;
}
section.content_wrapper
  .containerbox
  .data_wrapperinput-row-child
  .search-filter
  select:focus {
  outline: none;
}

/*-----------------------------------Table start-------------------------------------*/

section.content_wrapper
  .containerbox
  .data_wrapper
  table
  tr.table-heading
  .iconColor {
  color: #1a1a1a;
  position: absolute;
}

section.content_wrapper .containerbox .data_wrapper table {
  width: 100%;
  padding: 1rem 0;
  border-collapse: collapse;
}
section.content_wrapper .containerbox .data_wrapper table tr,
section.content_wrapper .containerbox .data_wrapper table tr td {
  border: 1px solid #f2f0f0;
}
section.content_wrapper .containerbox .data_wrapper table tr th {
  font-size: 13px;
  font-weight: 500;
  /* min-width: 60px; */
}
section.content_wrapper .containerbox .data_wrapper table tr th span {
  position: relative;
}
section.content_wrapper .containerbox .data_wrapper table tr:hover {
  background-color: #f2f0f0;
}
section.content_wrapper .containerbox .data_wrapper table tr.table-heading {
  background: #f2f0f0;
}
section.content_wrapper .containerbox .data_wrapper table tr th small {
  font-size: 10px;
}
section.content_wrapper .containerbox .data_wrapper table tr td {
  font-size: 13px;
  font-weight: 400;
  white-space: nowrap;
}
section.content_wrapper .containerbox .data_wrapper table tr td.PN_dimension {
  max-width: 200px;
}

section.content_wrapper
  .containerbox
  .data_wrapper
  table
  tr
  td.content_ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100px !important;
}
section.content_wrapper
  .containerbox
  .data_wrapper
  table
  tr
  td.red:nth-child(1)::after {
  content: "";
  border-bottom: 2px dotted red;
  width: 35px;
  display: block;
  padding-top: 7px;
}
section.content_wrapper
  .containerbox
  .data_wrapper
  table
  tr
  td.yellow:nth-child(1)::after {
  content: "";
  border-bottom: 2px dotted yellow;
  width: 35px;
  display: block;
  padding-top: 7px;
}
/* section.content_wrapper
  .containerbox
  .data_wrapper
  table
  tr
  td:nth-child(2)::after {
  content: "";
  border-bottom: 2px dotted rgb(9, 151, 21);
  width: 35px;
  display: block;
  padding-top: 7px;
} */
section.content_wrapper .containerbox .data_wrapper table td,
section.content_wrapper .containerbox .data_wrapper table th {
  text-align: left;
  padding: 6px;
}

section.content_wrapper .containerbox .data_wrapper table tr.inner-bg {
  background-color: #f2f0f0;
}
section.content_wrapper
  .containerbox
  .data_wrapper
  table
  tr.inner-border
  td.indent {
  background-color: #f2f0f0;
  padding-left: 50px;
  padding-bottom: 0px;
}
section.content_wrapper
  .containerbox
  .data_wrapper
  table
  tr.inner-border
  table.bgc {
  background-color: #ffffff;
}
section.content_wrapper
  .containerbox
  .data_wrapper
  table
  tr.inner-border
  tr.bdr {
  border: 1px solid #979999;
}
section.content_wrapper .containerbox .data_wrapper table tr.mainrow.active {
  background-color: #ccc;
  /* color: #ffffff; */
}
section.content_wrapper .containerbox .data_wrapper table tr.inner-border {
  display: none;
}
section.content_wrapper .containerbox .data_wrapper table tr.inner-border.open {
  display: table-row;
}
section.content_wrapper
  .containerbox
  .data_wrapper
  table
  tr.inner-border:hover {
  background: #ffffff;
}
section.content_wrapper
  .containerbox
  .data_wrapper
  table
  tr.inner-border
  tr:hover {
  background: #ffffff;
}
section.content_wrapper
  .containerbox
  .data_wrapper
  table
  tr.inner-border
  td:nth-child(2)::after {
  border-bottom: none;
}
section.content_wrapper
  .containerbox
  .data_wrapper
  table
  tr.inner-border
  td
  table
  tr
  td
  tr:nth-child(odd) {
  background: #f2f0f0;
}
section.content_wrapper .containerbox .data_wrapper table tr.inner-border td {
  font-size: 13px;
}
/* section.content_wrapper
  .containerbox
  .data_wrapper
  table
  tr.inner-border
  td.pbzero {
  padding-bottom: 0px;
} */
section.content_wrapper .containerbox .data_wrapper table tr.inner-border .fs {
  font-size: 20px;
  color: #1a1a1a;
}
section.content_wrapper
  .containerbox
  .data_wrapper
  table
  tr.inner-border
  .fs.mdupdate-size {
  font-size: 30px;
}
/* section.content_wrapper .containerbox .data_wrapper table tr.inner-border td table td{
    border-right: 1px solid #f2f0f0;
} */
section.content_wrapper
  .containerbox
  .data_wrapper
  table
  tr.inner-border
  td
  table
  td:last-child {
  border: none;
}
section.content_wrapper
  .containerbox
  .data_wrapper
  table
  tr.inner-border
  td.inner-border-right {
  /* border-right: 1px solid #979999; */
  position: relative;
  text-align: center;
  width: 200px;
  max-width: 200px;
  text-wrap: wrap;
  white-space: normal;
  border: 1px solid #979999;
}
section.content_wrapper
  .containerbox
  .data_wrapper
  table
  tr.inner-border
  td.inner-border-right
  span {
  margin: 0px 0 10px 0;
}
section.content_wrapper
  .containerbox
  .data_wrapper
  table
  tr.inner-border
  td.inner-border-right
  span
  b {
  color: green;
}
section.content_wrapper
  .containerbox
  .data_wrapper
  table
  tr.inner-border
  td.inner-border-right
  span
  i {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #1a1a1a;
}

section.content_wrapper
  .containerbox
  .data_wrapper
  table
  tr.inner-border
  td.inner-border-right
  div.inner-icons
  div {
  font-size: 18px;
  padding: 5px;
}
section.content_wrapper
  .containerbox
  .data_wrapper
  table
  tr.inner-border
  td.inner-border-right
  div.inner-icons
  div
  i {
  color: #1a1a1a;
}
section.content_wrapper
  .containerbox
  .data_wrapper
  table
  tr.inner-border
  td.inner-border-right
  div.inner-icons
  div
  a.push-right {
  margin-right: 10px;
}
/*-----------------------------------Footer Start-------------------------------------*/
section.content_wrapper .containerbox .data_wrapper .footer-bar {
  background: #f2f0f0;
  padding: 6px 8px;
  margin: 1rem 0rem 0rem 0rem;
  font-size: 12px;
  justify-content: flex-end;
}
section.content_wrapper .containerbox .data_wrapper .footer-bar p {
  margin-bottom: 0px;
}
section.content_wrapper .containerbox .data_wrapper .footer-bar div.three i {
  color: #1a1a1a;
}
section.content_wrapper
  .containerbox
  .data_wrapper
  .footer-bar
  div.three
  a:first-child {
  margin-right: 10px;
}
section.content_wrapper .containerbox .data_wrapper .footer-bar .one,
section.content_wrapper .containerbox .data_wrapper .footer-bar .two {
  margin-right: 3rem;
}
section.content_wrapper .containerbox .data_wrapper .footer-bar select {
  background-color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
section.content_wrapper .containerbox .data_wrapper .footer-bar a {
  font-size: 20px;
  color: #1a1a1a;
}
.linksWrapper {
  display: grid;
  row-gap: 10px;
}
.linksWrapper label {
  font-weight: 500;
  color: #1a1a1a;
}
.fs-30 {
  font-size: 30px;
}
.main_log_table {
  width: 100%;
}
.main_log_tr {
  border-top: 1px solid rgb(206, 214, 206);
  padding-bottom: 20px;
}
.main_log_tr > td {
  vertical-align: top;
  width: 160px;
  min-width: 160px;
}
/* .main_log_tr > td :first-child {
  width: 30%;
} */
.log_table {
  width: 100%;
}
/* .log_table > tr {
  width: 100%;
} */

.log_table > tr > .log_first_td {
  /* width: 80%; */
  font-weight: 600;
  font-size: 13px;
  width: 200px;
  min-width: 200px;
}
