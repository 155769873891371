.supplierParents {
  margin: 0px;
}
.suppliyChild {
  margin: 30px;
  background-color: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
}
.newvendor {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 15px;
  margin: 0px;
}

.sdCloseButton {
  color: white;
  background-color: #8e8e8e;
  border-style: none;
  height: 30px;
  width: 70px;
  font-size: 12px;
}
.newSsaveButton {
  color: white;
  background-color: black;
  border-style: none;
  width: 70px;
  font-size: 12px;
}
.newSsaveButtonPosition {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.rowGap-5 {
  row-gap: 5px;
}
