.filterIcon {
  width: 80%;
  display: flex;
  justify-content: flex-end;
  padding-right: 5px;
}

.modal-header.filter_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal-title.filter_title {
  font-size: 16px;
  width: 30%;
}
.viewpopIcon {
  float: right;
  color: white;
  background-color: #1a1a1a;
  border: 1px solid black;
  cursor: pointer;
}
.screening_view_nodata {
  display: table-caption;
}
