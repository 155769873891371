.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  width: 480px;
  height: auto;
  /* max-height: 350px !important; */
  right: 10px;
  left: auto !important;
  overflow: hidden;
}
.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper ul li {
  padding: 4px 10px;
  display: block;
}
.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper
  ul
  li:hover {
  background-color: transparent;
}
.notification_wrapper {
  /* padding-top: 7px; */
  padding: 7px 10px 0px 10px;
}
.notification_wrapper .notification_bells {
  display: inline-block;
}
.notification_wrapper .notification_bells span {
  /* border: 1px solid #f2f0f0; */
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}
/* .notification_wrapper .notification_bells span a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #1a1a1a;
  cursor: pointer;
  color: #ffffff;
} */
.notification_wrapper .notification_bells span a {
  border: 1px solid #f2f0f0;
  padding: 10px 12px;
  color: #1a1a1a;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
}

.notification_wrapper .notification_bells span a.bgRed {
  background-color: #ce2029;
  color: #ffffff;
  border: none;
}
.notification_wrapper .notification_bells span a.red {
  background-color: transparent;
  color: #ce2029;
}
.notification_wrapper .notification_bells span a.bgGreen {
  background-color: green;
  color: #ffffff;
  border: none;
}
.notification_wrapper .notification_bells span a.green {
  background-color: transparent;
  color: green;
}

/* .notification_wrapper .notification_bells span a:last-child {
  border: none;
} */
.notification_wrapper .notificatiion_content_wrapper {
  min-height: auto !important;
  max-height: 300px !important;
  overflow-y: auto !important;
  display: none;
}
.notification_wrapper .notificatiion_content_wrapper.show {
  display: block;
}
.notification_wrapper .notificatiion_content_wrapper .notificatiion_content {
  display: flex;
  align-items: flex-end;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
  border-radius: 4px;
  padding: 5px 10px;
  background-color: bisque;
  justify-content: space-between;
}
.notification_wrapper .notificatiion_content_wrapper .notificatiion_content p {
  text-wrap: wrap;
  font-size: 13px;
}
.notification_wrapper
  .notificatiion_content_wrapper
  .notificatiion_content
  button {
  background-color: #1a1a1a;
  color: white;
  border-radius: 4px;
  padding: 1px 6px;
  font-size: 10px;
  font-weight: bold;
  border: none;
  font-family: "Ubuntu";
}
.notification_wrapper
  .notificatiion_content_wrapper
  .notificatiion_content
  button:focus {
  box-shadow: none !important;
}
.setMarginHr {
  margin: 12px 0px !important;
}
/* .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper:before {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  background: rgb(62, 83, 126);
  transform: rotate(45deg);
  top: -8px;
  left: calc(50% - 2px);
} */

.NoNotificationFound {
  background-color: #f8d7da;
  padding: 4px 10px;
  border-radius: 4px;
}

.ViewAllNotification_div {
  border-top: 3px solid red;
}
.live {
  /* color: #55bf35 !important; */
  color: #ffffff !important;
  background: #55bf35;
  padding: 2px 6px;
  border-radius: 20px;
  font-size: 12px;
}

.pending {
  /* color: #fce83a !important; */
  color: #ffffff !important;
  background: #fce83a;
  padding: 2px 6px;
  border-radius: 20px;
  font-size: 12px;
}
.testmode {
  /* color: #2dccff !important; */
  color: #ffffff !important;
  background: #2dccff;
  padding: 2px 6px;
  border-radius: 20px;
  font-size: 12px;
}
.Paused {
  /* color: #ffb302 !important; */
  color: #ffffff !important;
  background: #ffb302;
  padding: 2px 6px;
  border-radius: 20px;
  font-size: 12px;
}
.IdAwaited {
  /* color: #a4abb6 !important; */
  color: #ffffff !important;
  background: #a4abb6;
  padding: 2px 6px;
  border-radius: 20px;
  font-size: 12px;
}
.billed {
  /* color: #aa00ff !important; */
  color: #ffffff !important;
  background: #aa00ff;
  padding: 2px 6px;
  border-radius: 20px;
  font-size: 12px;
}
.cancelled {
  /* color: #ff3838 !important; */
  color: #ffffff !important;
  background: #ff3838;
  padding: 2px 6px;
  border-radius: 20px;
  font-size: 12px;
}
.Viewallbtn_wrapper .viewAllBtn_show {
  display: block;
}
.Viewallbtn_wrapper .viewAllBtn_hide {
  display: none;
}
.Viewallbtn_wrapper button {
  background-color: #1a1a1a;
  float: right;
  margin-top: 10px;
  margin-bottom: 10px;
  color: white;
  border-radius: 4px;
  padding: 1px 6px;
  font-size: 13px;
  font-weight: bold;
  border: none;
  font-family: "Ubuntu";
}
