/* * {
  box-sizing: border-box;
} */

.vcm-row {
  margin-left: -5px;
  margin-right: -5px;
}

.vcm-column {
  float: left;
  width: 50%;
  padding: 5px;
}

/* Clearfix (clear floats) */
.vcm-row::after {
  content: "";
  clear: both;
  display: table;
}

.vcm-table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  /* border: 1px solid #ddd; */
}

.vcm-tr th,
.vcm-tr td {
  text-align: left;
  padding: 6px;
  border: 1px solid #ddd;
}

.vcm-tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other on screens that are smaller than 600 px */
@media screen and (max-width: 600px) {
  .vcm-column {
    width: 100%;
  }
}
.fs16 {
  font-size: 16px;
}
.vcmBtn {
  background-color: rgb(153, 153, 153);
  color: #ffffff;
  border-radius: 4px;
  font-size: 14px;
  border: none;
  padding: 5px 12px;
}
.vcmBtn:hover {
  background-color: rgb(139, 139, 139);
}
.vcmBtn:focus {
  background-color: rgb(139, 139, 139);
  border: none;
}
