.big_div {
  height: 500px;
  border: 2px solid rgb(245, 240, 240);
}

.big_first_div {
  width: 60%;
  height: auto;
  float: left;
  border-right: 1px solid rgb(245, 240, 240);
  padding-bottom: 10px;
  padding-right: 10px;
}

.follow_up_section {
  height: 55px;
  width: 100%;
}

.follow_up {
  width: 100px;
  float: left;
  font-size: 12px;
  color: #555;
  float: left;
}

.btns_section {
  width: 300px;
  margin-top: 10px;
  height: 40px;
  float: right;
}

.save_btn_section {
  width: 40px;
  height: 30px;
  float: left;
}

.add_btn_section {
  width: 200px;
  height: 30px;
  float: right;
  color: #0e72b9;
}

.opportunity {
  color: #0e72b9;
  background-color: white;
  border: none;
  font-size: 12px;
  border: 2px solid black;
}

.on_btn {
  background-color: #0e72b9;
  background: linear-gradient(#0e72b9 + #009eff);
  border: none;
  color: white;
}

.save_btn {
  background-color: #0e72b9;
  background: linear-gradient(#0e72b9 + #009eff);
  border: none;
  color: white;
  font-size: 12px;
}

.opportunity_div {
  height: auto;
  width: 100%;
  border: 2px solid rgb(245, 240, 240);
  display: grid;
  grid-template-rows: 30px 70px;
  grid-template-columns: 30% 40% 17% 13%;
}

#div3 {
  background-color: rgb(244, 246, 248);
  height: 30px;
  padding: 2px 5px;
}

#div4 {
  color: #555;
  padding: 2px 5px;
}

.opportunity_headings {
  font-size: small;
}

.opportunity_headings.ono span {
  color: #1a1a1a;
  text-decoration: underline;
}

.big_second_div {
  height: auto;
  width: 40%;
  float: left;
  font-size: 12px;
  padding: 0px 30px;

  padding-bottom: 10px;
}

.second_div_input {
  margin-top: 10px;
  width: 100%;
  font-size: 12px;
}

.email_subject {
  font-size: 12px;
  color: black;
  margin-top: 3px;
}

.bid_request {
  font-size: 12px;
}

.separator {
  width: 100%;
}

.reply_btns_section {
  width: 100%;
  height: 25px;
}

.btn_right_shift {
  float: right;
  background-color: #555;
  background: linear-gradient(#555 + #1a1a1a);
  border: none;
  color: white;
  font-size: 12px;
  margin-right: 5px;
}

.email_section {
  width: 100%;
  height: auto;
  font-size: 12px;
  color: black;
}

.email_content {
  margin-top: 0px;
  height: 240px;
  width: 100%;
  max-width: 100%;
  border: 2px solid rgb(245, 240, 240);
  overflow: auto;
}

p {
  margin-left: 10px;
  color: #555;
}

.system_info_div {
  height: auto;
  width: 100%;
  margin-top: 10px;
}

.system_information_div {
  width: 310px;
  float: left;
  height: 50px;
  font-size: 10px;
}

.modify_div {
  width: 320px;
  float: right;
  height: 50px;
  font-size: 10px;
}

.system_color {
  color: #1a1a1a;
}

span {
  color: #555;
}

.follow_up .switch {
  margin-top: 5px;
}

.follow_up .btn-outline-success {
  border-radius: 0px !important;
  color: #1a1a1a !important;
  height: 24px;
  border-color: rgb(209, 209, 209);
}

.follow_up .btn-outline-danger {
  border-radius: 0px !important;
  color: #999 !important;
  height: 24px;
  border-color: rgb(209, 209, 209);
}

.follow_up .switch .switch-handle {
  border-radius: 0px !important;
  border: 5px solid #ffffff;
  padding-left: 0px !important;
  background-color: #999;
  width: 22px;
  position: relative;
  left: 12px;
}

.follow_up .switch.btn-outline-danger .switch-handle {
  background-color: #999;
  border: 3px solid #fff !important;
  position: relative;
  left: 12px;
}

.follow_up .switch.btn-outline-success .switch-handle {
  background-color: #1a1a1a;
  border: 3px solid #fff !important;
  position: relative;
  right: 12px;
  left: auto;
}

.follow_up .btn-outline-success:hover,
.follow_up .btn-outline-danger:hover {
  background-color: #fff;
}

.follow_up .switch.btn-outline-success:hover .switch-handle {
  background-color: #1a1a1a;
}

.follow_up .switch.btn-outline-danger:hover .switch-handle {
  background-color: #999;
}
/*-------------------tab 1024--------------------*/
@media (max-width: 1024px) {
  .big_first_div.big_first_div2 {
    width: 100%;
  }
  .big_second_div.big_second_div2 {
    width: 100%;
  }
}
@media (max-width: 576px) {
  .system_info_div {
    display: flex;
    flex-direction: column;
  }
  .opportunity_div {
    grid-template-columns: 22% 35% 24% 19%;
  }
  .cbtn.addBtn {
    width: unset !important;
    margin-top: 0px;
    /* margin-left: 10px; */
  }
  .cbtn.addBtn.mset {
    margin-top: 5px !important;
    margin-left: 10px !important;
  }
  .cbtn.addBtn.cbtnMargin {
    margin-top: 5px;
    margin-left: 10px;
  }
}
@media (max-width: 454px) {
  .follow_up_section {
    display: flex;
  }
}
@media (max-width: 430px) {
  #div3 {
    height: 45px;
  }
  #div4 {
    padding: 22px 5px 5px;
  }
}
a.attachmail {
  cursor: pointer;
}
.mailboxDrop {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-left: 0px;
  margin-top: 5px;

  height: 50px;
}
.mailboxDrop:hover {
  background-color: #b9e7b3;
}
.mailboxDrop > h6 {
  margin-top: 5px;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  font-size: small;
  text-overflow: ellipsis;
}
.mailboxDrop > p {
  margin-top: -10px;
  margin-left: 38px;
  font-size: small;
}
.mailboxDrop > span > img {
  width: 50px;
  height: 100px;
}

.bidBtnAssign {
  border: none;
  width: 50px;
  margin-left: 300px;
  margin-top: -35px;
  border-radius: 30px;

  font-size: smaller;
}
.colorGreen {
  background: linear-gradient(to right, #34b140, #55bf35);
}

.suggestion {
  width: 100%;
  height: 240px;
  overflow-y: scroll;
}
