.top_container {
  /* width: 100%;
    height: 100px;
    border: 1px solid red;
    background-color:green ; */
  display: flex;
  flex-direction: column;
}

.table_container {
  /* border: 1px solid red; */
  width: 100%;
  height: auto;
  /* padding: 5px 20px 0px; */
  font-family: "Ubuntu";
}
.table_container.pd {
  padding: 5px 0px !important;
}

.table.customTable {
  font-family: "Ubuntu";
  font-size: 14px;
  letter-spacing: normal;
  border-spacing: 0 4px;
  margin-bottom: 0px;
}

.table.customTable thead td {
  padding-right: 18px;
}

.table.customTable thead td {
  vertical-align: middle;
  height: 30px;
}
.mainContent .table.customTable td,
.mainContent .table.customTable > :not(caption) > * > * {
  vertical-align: middle;
  padding: 4px 5px;
  font-size: 13px;
  letter-spacing: 0;
}

.mainContent .table.customTable thead td,
.mainContent .table.customTable thead > :not(caption) > * > * {
  padding-right: 12px;
}

.mainContent .customTable.table-hover > tbody > tr:hover > * {
  background-color: #eeeeee;
  --bs-table-accent-bg: rgb(237 237 237 / 8%);
  border-color: #eeeeee;
}
.mainContent .customTable.table-hover > tbody > tr:hover {
  border-color: #eee;
}

.table.customTable.table-hover > tbody > tr:hover td {
  background-color: #eeeeee;
}
.table.customTable.bidDetails {
  font-family: "Ubuntu";
  table-layout: fixed;
}

.table.customTable td {
  overflow: hidden;
  white-space: nowrap;
  /* max-width: 100%; */
  max-width: 100px !important;
  text-overflow: ellipsis;
  margin-bottom: 0px;
  font-family: "Ubuntu", sans-serif;
}

.table.customTable.salesReport td {
  overflow: hidden;
  white-space: nowrap;
  max-width: 140px;
  text-overflow: ellipsis;
  margin-bottom: 0px;
  font-family: "Ubuntu", sans-serif;
}
.table.customTable td:first-child {
  min-width: 50px;
  width: auto;
  max-width: 80px;
}
.table.customTable.bidDetails td:first-child,
.table.customTable td:last-child,
.table.customTable.bidDetails td:nth-last-child(2) {
  width: 75px;
  max-width: 100px;
}

.table.customTable.bidDetails td:nth-child(3) {
  /* width: 35%; */
}

.thead {
  background-color: #f0efef;
  font-weight: bold;
  height: 32px;
}
.thead .MuiButtonBase-root {
  font-size: 13px;
  letter-spacing: 0.3px;
  line-height: 1.4;
  font-weight: bold;
  color: #515151;
  font-family: "Ubuntu";
}

.imagediv {
  /* border: 1px solid black; */
  width: 30px;
  height: 30px;
  border-radius: 30px;
}

.MuiTablePagination-toolbar {
  background-color: #f9fcfe;
  margin-bottom: 15px !important;
}

.MuiTablePagination-spacer {
  display: none !important;
  display: block !important;
}

.MuiTablePagination-selectLabel {
  margin-top: 14px;
}

.MuiTablePagination-actions {
  padding: 0px !important;
}
.MuiTablePagination-root p {
  margin-bottom: 0px;
  margin-top: 0;
  font-family: "Ubuntu";
  font-size: 13px;
  letter-spacing: 0.7px;
}
.MuiTablePagination-root .MuiToolbar-root {
  background: #f0efef;
}
@media (min-width: 600px) {
  .MuiTablePagination-root .MuiToolbar-root {
    min-height: 36px;
    padding-right: 2px;
  }
}

.customTable {
  font-size: 14px;
}

.customTable td:last-child {
  width: 110px;
  text-align: center;
  padding: 0px;
}

.customTable .css-1q1u3t4-MuiTableRow-root {
  height: 36px;
}

.customTable .btn-outline-success {
  border-radius: 20px !important;
  color: #1a1a1a !important;
  height: 24px;
  border-color: #1a1a1a;
}

.customtable .btn-outline-danger {
  border-radius: 20px !important;
  color: #555555 !important;
  height: 24px;
  border-color: #555555;
}
.switch .switch-handle {
  border-radius: 50px !important;
  border: 5px solid #ffffff;
  padding-left: 0px !important;
  width: 22px;
  position: relative;
  left: 12px;
}
.customTable .switch.btn-outline-danger .switch-handle {
  background-color: #555555;
  border: 3px solid #fff !important;
  position: relative;
  left: 12px;
}

.customTable .switch.btn-outline-success .switch-handle {
  background-color: #1a1a1a;
  border: 3px solid #fff !important;
  position: relative;
  right: 12px;
  left: auto;
}

.btn-outline-success:hover,
.btn-outline-danger:hover {
  background-color: #fff;
}
.customTable .switch.btn-outline-success:hover .switch-handle {
  background-color: #1a1a1a;
}
.customTable .switch.btn-outline-danger:hover .switch-handle {
  background-color: #999;
}
.switch-on.btn-xs {
  padding-right: 25px !important;
  font-weight: normal !important;
  line-height: 1;
  font-size: 12px;
  letter-spacing: 0.5px;
}

.switch-off.btn-xs {
  padding-left: 25px !important;
  font-weight: normal !important;
  line-height: 1;
  font-size: 12px;
  letter-spacing: 0.5px;
}

.mainContent .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  padding: 0px;
}

.mainContent
  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root
  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  width: 25px;
  height: 25px;
  margin: 0px;
}

#mailbox .MuiCheckbox-root {
  padding: 2px;
}

#mailbox .MuiCheckbox-root .MuiSvgIcon-root {
  width: 25px;
  height: 25px;
  margin: 0px;
}
.thead td.MuiTableCell-root {
  height: 20px;
  position: relative;
}
.thead td.MuiTableCell-root span {
  width: 96%;
  white-space: nowrap;
}

.thead .MuiButtonBase-root .MuiSvgIcon-root {
  font-size: 22px;
  width: 26px;
  height: 26px;
  position: absolute;
  top: -3px;
  right: -22px;
  color: rgb(27, 27, 27);
}

.table.customTable.bidDetails .thead .MuiButtonBase-root .MuiSvgIcon-root {
  position: relative;
  top: 0px;
  right: auto;
  left: -8px;
  display: inline;
}

@media (max-width: 1024px) {
  .table_container {
    max-width: 100%;
    overflow-x: scroll;
  }
}
