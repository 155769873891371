.qual_frontend .qblock{
    width:100%; 
    padding:10px 15px;
    margin:3px auto;
    border:1px solid #f6f6f6
}

.qual_frontend.qblock .dropdown{
    width:120px
}

:root{
  accent-color : rgb(88, 88, 88);
}

.qual_frontend p.qtitle{
    font-size: 20px;
    line-height: 1.6;
    letter-spacing: 1;
    color:#000;
    margin: 0px;
}

.qual_frontend .anscol{
    padding: 5px !important;
    padding-left:12px !important;
}


.qual_frontend input,
.qual_frontend textarea, .qual_frontend select{
  border        : 2px solid #ddd;
  border-radius : 4px;
  background    : #fff no-repeat center center;
  color         : #333;
  padding: 5px 5px;
}
.qual_frontend select{
    margin-right:5px
}

:is(.qual_frontend input,.qual_frontend  textarea,.qual_frontend  select):where(:active:not(:disabled), :focus){
  border-color : rgb(95, 95, 95);
  outline      : none;
}

:is(.qual_frontend input,.qual_frontend textarea, .qual_frontend select):disabled{
  background : #eee;
  color      : #000;
}

.qual_frontend input:where([type="checkbox"],.qual_frontend  [type="radio"]){
  -webkit-appearance : none;
  appearance         : none;
  width              : 22px;
  height             : 22px;
  margin             : calc(0.75em - 11px) 0.25rem 0 0;
  vertical-align     : top;
}

.qual_frontend input[type="radio"]{
  border-radius : 50%;
}

.qual_frontend input[type="checkbox"]:checked{
  background-image : url('../../../assets/common/checkbox.svg');
}

.qual_frontend input[type="radio"]:checked{
  background-image : url('../../../assets/common/radio.svg');
}

.qual_frontend input[type="radio"]:checked ~ label , 
.qual_frontend input[type="checkbox"]:checked ~ label {
    color: #222222;
}

.qual_frontend .btn.sbmtbtn{
    background-color: #1a1a1a;
    color: #fff;
    padding: 5px 15px;
    border:1px solid #1a1a1a;
    border-radius:3px;
    margin-top: 8px;;
}

.qual_frontend span.errMessage{
    background-color:#900;
    color:#fff;
    padding:3px 5px;
    margin-left: 8px;
    font-size:11px;
    line-height: 1;
    border-radius:2px;
    white-space: nowrap;
}