.condition_box{
    padding: 15px;
}

.answercheck{
    margin:5px
}

.errMsg{
    color:#990000;
}

.customerrmsg{
    display: none;
}

.add-Quota-btn:disabled{
    cursor:not-allowed
}

.switch.btn:disabled
{
    cursor:not-allowed
}

button.supply_setupbtn{
    margin-top:20px
}