.formgroupCompose {
  width: 100%;
  padding: 0px 15px;
  position: relative;
  margin-bottom: 2px;
}

.formgroupCompose label {
  color: #0e72b9;
  width: auto;
  margin-right: 10px;
  font-size: 13px;
  position: absolute;
  left: 25px;
  top: 5px;
}

.etext {
  width: 100%;
  border: none;
  border-bottom: 1px solid #eee;
  margin-bottom: 8px;
  font-size: 14px;
  padding: 3px 80px 3px 68px;
}

.etext:focus {
  outline: none;
  border: none;
  border-bottom: 1px solid #aaa;
}

.rbtn {
  position: absolute;
  right: 30px;
  font-size: 14px;
  line-height: 1.4;
  cursor: pointer;
  width: 20px;
  text-align: center;
  padding: 2px;
  z-index: 99999;
}
.rbtn.hide {
  display: none;
}
.rbtn.cc {
  right: 60px;
}
.rbtn:hover {
  font-weight: 600;
}
.ccblock,
.bccblock {
  display: none;
}
.ccblock.show,
.bccblock.show {
  display: block;
  position: relative;
}

span.slabel {
  font-size: 10px;
  padding: 3px 3px;
  border-radius: 3px;
  color: #fff;
}
span.slabel.bCreated {
  background-color: #535453;
}
span.slabel.bNew {
  background-color: #989898;
}

.mainContent .table > :not(caption) > * > * {
  padding: 2px 5px;
  font-size: 13px;
  color: rgba(0,0,0,0.87);
  letter-spacing: 0.5px;
  font-family: "Ubuntu", sans-serif;
}

.css-apqrd9-MuiTableBody-root .TableCent {
  border-collapse: "separate";
  border-spacing: "0px 4px";
  display: "flex" !important;
  justify-content: "center" !important;
  align-items: "center" !important;
}

button.closeModal {
  background-color: #ccc;
    border: 2px solid #ccc;
    height: 26px;
    left: auto;
    line-height: 1;
    position: absolute;
    right: 0;
    top: 0;
    width: 26px;
}
#profileImage {
  width: 30px;
  /* height: 150px; */
  border-radius: 50%;
  background: #512da8;
  font-size: 35px;
  color: rgb(218, 28, 28);
  text-align: center;
  line-height: 150px;
  margin: 20px 0;
}

.table#mailbox {
  position: relative;
  width: 100%;
  max-width: 100%;
  table-layout: fixed;
  white-space: nowrap;
}
.table#mailbox > :not(caption) > * > * {
  padding: 2px 5px;
  border-bottom-width: 0px;
  vertical-align: middle;
}

.table#mailbox > :not(caption) > * {
  padding: 0px;
  border-bottom-width: 1px;
  /* height: 38px; */
}

td.subject {
  width: 65%;
  margin-right: 0%;
  max-width: 55%;
  overflow-x: hidden;
  cursor: pointer;
}
.mailContent {
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  margin-bottom: 0px;
}

.deleteIcon {
  margin-left: 10px;
  margin-top: 11px;
}
.deleteIcon svg{
  height: 20px;
}
.profileImage {
  height: 30px;
  width: 30px;
  margin-left: 7px;
  border-radius: 50%;
  text-align: center;
  margin-top: 0px;
  background-color: darkGrey;
}
.profileImage1 {
  height: 30px;
  width: 30px;
  margin-left: 7px;
  border-radius: 50%;
  text-align: center;
  margin-top: 3px;
  /* background-color: darkGrey; */
}

.profileImageText {
  color: black;
  font-size: 11px;
  text-align: center;
  margin-top: 8px;
  letter-spacing: 0px;
}

.mainContentUnderDiv {
  width: 100%;
  height: 40px;
  background-color: #f0efef;
  margin-top: -10px;
  display: flex;
}
.headerCheckbox {
  height: 40px;
  width: 20px;
  margin-left: 10px;
  margin-top: 5px;
}
.arrowDropDownIcon {
  margin-left: 4px;
  margin-top: -27px;
  cursor: pointer;
}

.startborderoutline {
  height: 25px;
  color: grey;
  margin-left: 5px;
  margin-top: 1px;
  display: block;
  width: 25px;
   
  cursor: pointer;
}
.rowHeader {
  color: black;
  width: 15%;
  max-width: 15%;
  overflow-x: hidden;
  font-weight: 500;
  color: #000;
  font-size: 12px;
  cursor: pointer;
}
.rowHeader span:last-child {
  width: 10%;
  max-width: 10%;
  display: block;
  float: left;
}
.rowHeader span:first-child {
  
  width: auto;
  max-width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 0px;
  float: left;
  display: block;
  font-weight: 500;
  /* color: #000; */
}

.moreverticon {
  cursor: pointer;
  margin-left: 10px;
  text-align: center;
  margin: 5px 0 0;
}
.gold .MuiSvgIcon-root {
  fill: gold;
}
.red .MuiSvgIcon-root {
  fill: #f35d4d;
}
.blue .MuiSvgIcon-root {
  fill: #8091fa;
}
.green .MuiSvgIcon-root {
  fill: #4dc48e;
}
.grey .MuiSvgIcon-root {
  fill: grey;
}

.attachmentbox {
  display: inline-block;
  width: 100px;
}
.bid_menu_mail .css-6hp17o-MuiList-root-MuiMenu-list {
  padding: 5px;
  font-size: 14px;

  color: #555;
}
@media (max-width: 768px) {
  .fstd {
    width: 6% !important;
  }
  td.subject {
    width: 47%;
  }
}
@media (max-width: 576px) {
  .fstd {
    width: 7% !important;
  }
  td.subject {
    width: 40%;
  }
}
@media (max-width: 520px) {
  td.subject {
    width: 30%;
  }
}
@media (max-width: 400px) {
  .fstd {
    width: 7% !important;
  }
  td.subject {
    width: 20%;
  }
}
.mailFrom {
  display: none;
}

@media (max-width: 768px) {
  .mailFrom {
    display: block;
    margin-bottom: 4px;
  }
  td.hiddenSm {
    display: none;
  }
}

td.date {
  text-align: right;
  width: 9%;
}
.hiddenIcon {
  display: none;
}
@media (max-width: 1024px) {
  .hiddenIcon {
    display: block;
  }
}

.topleft {
  width: 23%;
  height: 38px;
  display: block;
}
.toprightBlock .cbtn.addBtn.mr {
  margin-right: 0px;
}
.middleBlock,
.userSearchBox {
  width: 62%;
  height: auto;
  float: right;
  text-align: right;
  display: block;
}
.userSearchBox {
  width: 100%;
}

.toprightBlock {
  width: auto;
  float: right;
  display: block;
  height: auto;
}

@media (max-width: 1024px) {
  .topleft {
    width: 50%;
  }
  .middleBlock {
    width: 100%;
    /* background-color: #eee; */
    padding: 3px;
    margin: 5px 0;
    display: none;
  }

  .middleBlock.show {
    display: block;
  }
  .toprightBlock {
    width: 250px;
  }
  [type="search"].tableSearch.wdsrch {
    width: 200px !important;
  }
}

@media (max-width: 576px) {
  .toprightBlock {
    width: 100%;
    margin-top: 10px;
  }
  .toprightBlock .cbtn.addBtn {
    width: 200px;
    background: #1a1a1a;
    margin-top: 0px;
    float: left;
  }
  .topleft {
    width: 100% !important;
    display: flex;
    justify-content: space-between;
  }
  .searchtoggle {
    width: 50px;
    float: right;
  }
  .cbtn.saveBtn.wd {
    width: 100%;
    grid-row: 3/4;
    margin-left: 0px !important;
  }
  [type="search"].tableSearch.wdsrch {
    width: 100% !important;
    grid-row: 1/2;
    /* border-radius: 0px; */
  }
  .asgn {
    width: 100% !important;
    margin-left: 0px !important;
  }
  .middleBlock .userSearchBox {
    display: grid;
    grid-gap: 8px;
  }
  .multiSelectContainer.userDropdown.btm {
    margin-bottom: 0px !important;
  }
}
.drop {
  z-index: 999;
  color: #444;
  background-color: #fff;
  max-height: 150px;
  overflow-y: hidden;
  position: absolute;
  top: 90%;
  font-size: 16px;
  padding: 0px 0px;
  border: 1px solid #eee;
  box-shadow: 2px 2px 0 0px rgba(73, 73, 73, 0.027);
  display: none;
}
.drop:hover {
  overflow-y: scroll;
}
.drop h6 {
  font-size: 14px;
  line-height: 1.8;
  padding: 5px;
  margin: 0 0 0 0;
}
.drop h6:hover {
  background-color: #f3f3f3;
}
.drop.hide {
  display: none;
}

.mail-date.lg {
  display: inline-block;
}
.mail-date.sm {
  display: none;
}

.mail-header-left h4 {
  max-width: 100%;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media (max-width: 760px) {
  .mail-date.lg {
    display: none;
  }
  .mail-date.sm {
    display: inline-block;
  }
}

.formgroupCompose .react-multi-email input,
.formgroupCompose .react-multi-email.focused input,
.formgroupCompose .react-multi-email input:focus,
.formgroupCompose .react-multi-email.focused input {
  border: none;
  outline: none;
  min-width: 100px;
  width: auto;
}
.formgroupCompose .react-multi-email div.data-labels {
  width: auto;
  float: left;
  padding: 0px;
  min-height: 22px;
}

.formgroupCompose .react-multi-email div.data-labels > div {
  display: inline-block;
  padding: 2px 5px;
  background-color: #eee;
  border-radius: 15px;
  width: auto;
  position: relative;
}
.replyBlockContainer.mtop
  .formgroupCompose
  .react-multi-email
  div.data-labels
  > div {
  position: relative;
  top: 3px;
}
.formgroupCompose .react-multi-email div.data-labels > div > div {
  float: left;
  max-width: 150px;
  text-overflow: ellipsis;
  margin-right: 3px;
  overflow: hidden;
  word-wrap: unset;
  white-space: nowrap;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 1.6;
}
.formgroupCompose .react-multi-email div.data-labels > div > span {
  height: 18px;
  width: 18px;
  margin-top: 2px;
  line-height: 18px;
  border-radius: 50%;
  display: block;
  background-color: #888;
  color: #fff;
  float: right;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}
.formgroupCompose .react-multi-email {
  padding-bottom: 6px;
}

.formgroupCompose .react-multi-email + .drop {
  display: none;
}
.formgroupCompose .react-multi-email.focused + .drop,
.formgroupCompose .react-multi-email + .drop:hover {
  display: block;
}
@media (max-width: 460px) {
  .formgroupCompose .react-multi-email.pl {
    padding-left: 40px !important;
  }
}
@media (max-width: 460px) {
  .formgroupCompose .react-multi-email div.data-labels > div > div.mw {
    max-width: 120px !important;
  }
}

.tableHeading.mailbox{
  width: auto;
  float: left;
  margin-right: 15px;
}

.mailboxFilter{
  background-color: #313131;
  color:#fff;
  font-size: 12px;
  padding: 2px;
  margin-top:5px;
  border-radius:4px
}
select.mailboxFilter option{
background-color: #fff;
    color: #333;
}

.refresh.actionElement{
  margin-left: 13px;
}

.refresh.actionElement img{
  height: 14px;
}

.multiSelectContainer ul.optionContainer{
  max-height: 130px;
}


.addFolderIcon{
  margin:5px 10px;
  position: relative;
  top:8px;
}



