.header_wrapper {
  background-color: #1b1b1b;
  position: fixed;
  width: 100%;
  z-index: 999;
  height: 65px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
}

.header_wrapper .samplicity_logo {
  height: 50px;
}

.recaptcha_card h2 {
  line-height: 2.25rem;
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 20px;
}

.recaptcha_card button {
  padding: 10px 20px;
  margin: 10px 0px 40px 0px;
  cursor: pointer;
}

.recaptcha_card button a {
  text-decoration: none;
}

.recaptcha_card {
  padding: 20px;
  position: relative;
  /* top: 10%; */
  top: 15px;
  left: 20%;
  width:60%;
  text-align: left;
}

.recaptchablock{
  margin:auto;
  position: relative;
}
