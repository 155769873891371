.mainContent .table.customTable.salesReport td:nth-child(7) {
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 0px;
  font-family: "Ubuntu", sans-serif;
}

.table.customTable.salesReport .thead td.MuiTableCell-root span {
  width: 100%;
  white-space: nowrap;
  display: block;
}
.mainContent .table.customTable.salesReport thead td {
  padding-right: 0;
}

.table.customTable.salesReport .thead td.MuiTableCell-root span.cellHeading {
  min-width: 50px !important;
  width: auto;
  max-width: 88%;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  position: relative;
  margin: 0px;
  float: left;
}
.mainContent .table.customTable.salesReport thead > :not(caption) > * > * {
  padding-right: 2px;
}

.table.customTable.salesReport .thead .MuiButtonBase-root .MuiSvgIcon-root {
  font-size: 22px;
  width: 16px;
  height: 16px;
  position: absolute !important;
  right: -14px;
  top: 2px;
  color: rgb(27, 27, 27);
  float: left;
  margin: 0;
}
.table.customTable.salesReport td span.actionButton {
  padding: 0px 3px;
}

.table.customTable.salesReport
  td
  span.actionButton
  svg.css-i4bv87-MuiSvgIcon-root {
  height: 16px;
  width: 16px;
}
