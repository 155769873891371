p.legend {
  font-size: 12px;
  color: #555;
  margin-bottom: 3px;
}
p.legend span {
  width: 10px;
  height: 10px;
  display: block;
  margin: 3px 5px 0 0;
  float: left;
  border-radius: 10px;
}

.mt-25 {
  margin-top: 25px;
}
table.dbTable,
table.awrTable {
  width: 100%;
}
table.dbTable th {
  font-size: 11px;
  border-bottom: 1px solid #bbb;
  font-weight: 500;
  color: #000;
  letter-spacing: 0.3px;
}
table.dbTable th:first-child {
  padding-left: 8px;
}
table.dbTable td {
  font-size: 13px;
  border-bottom: 1px solid #f5f5f5;
  font-weight: 500;
  color: #555;
  padding: 4px 2px;
  letter-spacing: 0.3px;
  vertical-align: middle;
}
table.dbTable td:first-child {
  padding-left: 8px;
}
.text-center {
  text-align: center;
}
.br-1 {
  border-right: 1px solid #eee;
}

table.awrTable td,
table.awrTable th {
  width: 10%;
  text-align: center;
}
table.awrTable td {
  padding: 4px 8px;
}
table.awrTable td:first-child,
table.awrTable th:first-child {
  width: 30%;
  text-align: left;
}
table.awrTable td p.small {
  margin: 0 0 4px;
  letter-spacing: 0.3px;
}

.dashboard .daterangepicker td.start-date.active,
.dashboard .daterangepicker td.end-date.active,
.dashboard .daterangepicker td.active:hover {
  background-color: #333;
}

.view {
  border: none;
  outline: none;
  padding: 2px;
  font-size: 13px;
}

.calendarIcon {
  width: 13px;
  float: left;
}
.right-pane {
  position: relative;
}

.button-container {
  /* width: 75px; */
  color: #fff !important;
  float: right;
  width: auto;
  background: #1a1a1a;

  border: none;
  border-radius: 4px;
}

/*-----------------max width 1170px----------------*/
@media (max-width: 1170px) {
  .dashboard .card-header .row .col:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
/*-----------------max width 576px----------------*/
@media (max-width: 576px) {
  .right-pane {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-direction: column-reverse;
    gap: 10px;
    width: 100%;
  }
  .right-pane.right-pane2 {
    display: block;
  }
  .dropdownContainer.dropdownContainer2 {
    width: auto;
  }
  .right-pane.right-pane2 {
    display: block;
  }
  .dropdownContainer.dropdownContainer2 {
    width: auto;
  }
  .cbtn {
    margin-right: 0;
  }
  .cbtn.cbtn2 {
    margin-right: 10px;
  }
}
  


@media (min-width: 577px) and (max-width: 703px) {
  .right-pane {
    width: auto;
  }
}
