@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap");

body {
  font-family: "Ubuntu", sans-serif;
  letter-spacing: 0.5px;
  line-height: 1.6;
  font-weight: 400;
  background-color: #f9fcfe;
  overflow-y: auto !important;
  padding-right: 0 !important;
}

.App {
  font-family: "Ubuntu", sans-serif;
}

a {
  color: #1e7cbf;
  text-decoration: none;
}

p {
  font-family: "Ubuntu", sans-serif;
}

.modal {
  margin-top: 30px;
}

.react-confirm-alert-body {
  z-index: 99999;
}
.react-confirm-alert-overlay {
  z-index: 99999;
}
/* .modal-body{
  max-height: 60vh;
  overflow-y: scroll;
} */

/************** Custom scrollbar******************/

.card ::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.card ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgb(214, 214, 214);
  border-radius: 6px;
}

/* Handle */
.card ::-webkit-scrollbar-thumb {
  background: #d6d6d6;
  border-radius: 6px;
}

/* Handle on hover */
.card ::-webkit-scrollbar-thumb:hover {
  background: #a7a7a7;
}

/************************* mainContainer **********************/
.main_container {
  /* background-color: ; */
  margin: auto;
  margin-top: 15px;
  border-radius: 2px;
  /* border:1px solid blue; */
  background-color: #ffffff;
  width: 97%;
  height: auto;
  overflow-y: visible;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.main_container:after {
  display: table;
  content: "";
  clear: both;
}
/* @media (max-width: 991px) {
  .main_container {
    margin-top: 65px;
  }
} */

.main_container hr {
  margin: 10px 0 5px;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
  clear: both;
}

.user_serachbox {
  width: 100%;
  height: 38px;
  display: block;
  padding: 10px 20px 5px;
}

H3.tableHeading {
  font-family: "Ubuntu", sans-serif;
  font-weight: 500;
  font-size: 20px;
  border-radius: 0;
  line-height: 30px;
  color: #1a1a1a;
}

.headingIcons {
  float: left;
  height: 30px;
  border-radius: 0;
  margin-right: 10px;
}

.cbtn {
  font-size: 14px;
  color: #fff !important;
  border-radius: 1px;
  height: 30px;
  line-height: 20px;
  padding: 5px 15px;
  float: right;
  margin-right: 10px;
  border: none;
  border-radius: 4px;
}

.cbtn.addBtn {
  width: auto;
  background: #1a1a1a;
}

button.cbtn.addBtn:disabled {
  cursor: not-allowed;
}
.cbtn.addBtn img {
  float: left;
  height: 16px;
  margin-top: 2px;
}

.right-pane {
  width: 50%;
  float: right;
}

.cbtn.saveBtn {
  width: auto;
  background: #1a1a1a;
}

.cbtn.closeBtn {
  width: auto;
  background: #999;
}

[type="search"].tableSearch {
  outline-offset: 0px;
  -webkit-appearance: textfield;
  background: url("assets/LoginIcons/search.svg") no-repeat;
  background-position: 10px 50%;
  background-size: 15px;
  float: right;
  padding: 4px 10px 4px 35px;
  border-radius: 1px;
  width: 190px;
  border: 1px solid #999;
  height: 28px;
}

.mainContent {
  width: 100%;
  padding: 0px 15px 10px;
  min-height: 100px;
  overflow-x: auto;
  overflow-y: auto;
}
.mainContent.mainContent2 {
  white-space: nowrap;
}
/************************* mainContainer *********************/
.formlabel {
  width: 100%;
  font-size: 12px;
  color: #555;
  margin-bottom: 5px;
  line-height: 1;
}

.formtext {
  width: 100%;
  height: 28px;
  padding: 3px 10px;
  border: 1px solid #aaa;
  color: #999;
  border-radius: 1px;
  margin-bottom: 1px;
  font-size: 14px;
}
.form-group {
  position: relative;
}

.form-group .formtext {
  margin-bottom: 5px;
}
.errmsg {
  font-size: 11px;
  line-height: 1;
  width: 100%;
  color: #f00;
  margin: 0px;
  padding: 0px;
  top: 0px;
  position: relative;
}

.col .form-group .errmsg {
  font-size: 10px;
  line-height: 1;
  width: 100%;
  color: #fff;
  margin: 0px;
  padding: 2px;
  top: auto;
  bottom: 0px;
  background-color: #900;
  left: 0px;
  position: absolute;
}
div.col {
  position: relative;
}
div.col .errmsg {
  font-size: 10px;
  line-height: 1;
  width: 90%;
  color: #fff;
  margin: 0px 5%;
  padding: 2px;
  top: auto;
  bottom: 0px;
  background-color: #900;
  left: 0px;
  position: absolute;
}
div.col .errmsg.errmsg2 {
  top: 50px;
  bottom: auto;
}
.mainContent.pdbtm {
  padding: 0px 15px 30px;
}
/***************************************************************************************/
.notification .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  height: 30px;
  width: 30px;
}

.notification .MuiBadge-badge.MuiBadge-standard {
  font-size: 10px;
  top: 2px;
  right: 2px;
  padding: 0 3px;
  min-width: 18px;
  height: 18px;
}

.actionElement {
  cursor: pointer;
}

.MuiList-root .MuiTypography-root {
  font-size: 14px;
}

/******************************** Switch toggle ********************/
.switch.btn-outline-success,
.switch .btn.btn-outline-success.btn:active {
  border-radius: 20px !important;
  color: #1a1a1a !important;
  height: 24px;
  border-color: #1a1a1a;
  box-shadow: none;
}
.switch .btn.btn-outline-success.btn,
.switch .btn.btn-outline-success.btn:hover,
.switch .btn.btn-outline-success.btn:active {
  border: none;
}
.switch.btn-outline-danger,
.switch .btn.btn-outline-danger {
  border-radius: 20px !important;
  color: #555555 !important;
  height: 24px;
  border-color: #555555;
  box-shadow: none;
}
.switch .btn.btn-outline-danger:hover,
.switch .btn.btn-outline-danger,
.switch .btn.btn-outline-danger:active {
  border: none;
}
.switch .switch-handle {
  border-radius: 50px !important;
  border: 5px solid #ffffff;
  padding-left: 0px !important;
  width: 22px;
  position: relative;
  left: 12px;
  top: -2px;
}
.switch.btn-outline-danger .switch-handle {
  background-color: #555555;
  border: 3px solid #fff !important;
  position: relative;
  left: 12px;
}

.switch.btn-outline-success .switch-handle {
  background-color: #1a1a1a;
  border: 3px solid #fff !important;
  position: relative;
  right: 12px;
  left: auto;
}

.btn-outline-success:hover,
.btn-outline-danger:hover {
  background-color: #fff;
}
.switch.btn-outline-success:hover .switch-handle {
  background-color: #1a1a1a;
}
.switch.btn-outline-danger:hover .switch-handle {
  background-color: #999;
}
.switch-on.btn-xs {
  padding-right: 25px !important;
  font-weight: normal !important;
  line-height: 1;
  font-size: 12px;
  letter-spacing: 0.5px;
}

.switch-off.btn-xs {
  padding-left: 25px !important;
  font-weight: normal !important;
  line-height: 1;
  font-size: 12px;
  letter-spacing: 0.5px;
}

/*************************************** Card *************************************/
.dashboard .col {
  padding-right: 4px;
  padding-left: 4px;
  width: 33.33%;
}
.dashboard .card {
  border-radius: 3px;
  margin-bottom: 10px;
}
.dashboard .card-header {
  padding: 0px 13px;
  background-color: #fff;
}

.dashboard .card-header .col {
  padding: 8px 5px;
}
.dashboard .card-header .row .col:last-child {
  max-width: 180px;
  display: block;
}
.dashboard .card-body {
  padding: 10px 0px;
  background-color: #fff;
  height: 235px;
  max-height: 235px;
  overflow-y: scroll;
}

.dashboard .card-body:hover {
  overflow-y: scroll;
}

.dashboard .card-body.mx-15 {
  padding: 10px 15px;
}

.dashboard .card-body canvas {
  max-height: 280px;
}
h5.cardHeading {
  font-size: 14px;
  margin-bottom: 0;
  line-height: 1.5;
  font-weight: 600;
}
.bl1 {
  border-left: 1px solid #eee;
}

.dashboard .col.bl1 .form-control {
  padding: 0;
  text-align: right;
  font-size: 12px;
  border: none;
  background-color: transparent;
  width: 155px;
  float: left;
  background-image: url(./assets/BidsIcons/arrow-down.png);
  background-position: 145px 5px;
  background-repeat: no-repeat;
  background-size: 8px;
  padding-right: 15px;
}
.dashboard .col.bl1 .form-control:focus {
  border: none;
  outline: none;
  box-shadow: none;
}
.dashboard .card-footer {
  padding: 2px 4px;
  background-color: #fff;
}

.dashboard .search {
  font-size: 13px;
  padding: 2px 4px;
  border: 1px solid #bbb;
  float: right;
}

/******************************************* Spinner Loader *****************************************/
.loader {
  width: 30px;
  height: 30px;
  margin-left: 10px;
  position: absolute;
  top: 3px;
}

.LoaderContainer {
  width: 100%;
  /* height: 200px; */
  /* margin: 100px auto 80px; */
  position: relative;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 20vh;
}

.closeToaster {
  width: 20px;
  height: 20px;
  border: 1px solid #1a1a1a;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  right: -8px;
  top: -8px;
  left: auto;
  padding: 0px 3px;
  line-height: 1;
  font-size: 14px;
}

.main_container .react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 12px;
}

.main_container .react-datepicker-popper[data-placement^="bottom"] {
  padding-top: 0;
}
.main_container .react-datepicker__header {
  padding: 3px;
}

.main_container [dir="ltr"] .ck.ck-dropdown .ck-dropdown__arrow {
  z-index: 0;
}
.main_container .react-datepicker__day,
.main_container .react-datepicker__day-name {
  margin: 0px;
}

.daterangepicker .drp-calendar.left {
  padding: 5px 0 0px 5px;
}

/*****************************************Common *****************************/
select option {
  text-transform: capitalize;
}

.ReactModalPortal .ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 999999;
  overflow-y: auto;
}

label.uploadPic {
  cursor: pointer;
}

/************************** Modal*******************************************/

.dropdownContainer {
  width: calc(100% - 75px);
  float: right;
  display: block;
  position: absolute;
  height: 40px;
}

.multiSelectContainer.userDropdown {
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  margin-bottom: 5px;
}
.multiSelectContainer ul.optionContainer > li {
  padding: 1px 8px;
  font-size: 13px;
  color: #999;
}

.userDropdown .multiSelectContainer li:hover {
  background: #eee;
  color: #555;
}

.userDropdown .multiSelectContainer li.option {
  padding: 1px 10px;
  color: #999;
  font-size: 14px;
  line-height: 1.5;
}

.multiSelectContainer li.option.highlightOption {
  background: #93d4ff;
  color: #fff;
}
.userDropdown .searchWrapper {
  border: none;
  border-image: initial;
  background: white;
  z-index: 5;
  margin-top: 0px;
  width: 100%;
  height: 28px;
  border: 1px solid #999;
  border-radius: 2px;
  max-height: 28px;
  padding: 0px 5px;
  position: relative;

  overflow: hidden;
}
.userDropdown .chip {
  align-items: center;
  background: #919191;
  border-radius: 15px;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  line-height: 19px;
  margin-bottom: 3px;
  margin-right: 3px;
  padding: 1px 6px;
  position: relative;
  margin-top: 4px;
  max-width: 60px;
  overflow: hidden;
  word-wrap: unset;
  white-space: nowrap;
  padding-right: 20px;

  background: #919191;
  border-radius: 15px;
  color: #fff;
  clear: unset;
  display: block;
  font-size: 12px;
  line-height: 19px;
  margin-bottom: 3px;
  margin-right: 3px;
  padding: 1px 6px;
  position: relative;
  margin-top: 4px;
  max-width: 60px;
  overflow: hidden;
  word-wrap: unset;
  white-space: nowrap;
  padding-right: 20px;
  float: left;
}
.userDropdown .chip .closeIcon {
  cursor: pointer;
  float: right;
  height: 18px;
  margin-left: 5px;
  width: 18px;
  position: absolute;
  left: auto;
  background-color: #919191;
  right: 0px;
  border: 3px solid #919191;
  top: 2px;
}
.updateUser .searchWrapper .searchBox#search_input {
  display: block;
}

.multiselect-container:active .searchBox#search_input,
.multiselect-container:focus .searchBox#search_input,
.searchWrapper:active .searchBox#search_input,
.searchWrapper:hover .searchBox#search_input,
.searchBox#search_input:active,
.searchBox#search_input:focus {
  display: block;
}

/**********************React Select Dropdown************************/

.css-1s2u09g-control {
  background-color: #ffffff;
  color: #555;
  cursor: default;
  display: block;
  font-size: inherit;
  padding: 00px;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  max-height: 28px;
}
.formCont .css-1s2u09g-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  border-color: #aaa;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 28px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: none;
  transition: none;
  box-sizing: border-box;
}
.formCont .css-1pahdxg-control:hover {
  border-color: #aaa;
}
.formCont .css-1pahdxg-control,
.css-1pahdxg-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: #aaa;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 0 0 1px #2684ff00;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  max-height: 28px;
  min-height: 28px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: none;
  transition: none 100ms;
  box-sizing: border-box;
}

.formCont .css-tlfecz-indicatorContainer,
.formCont .css-1gtu0rj-indicatorContainer,
.formCont .css-1hb7zxy-IndicatorsContainer {
  padding: 2px 2px;
}
.formCont .css-qc6sy-singleValue {
  color: #999;
  font-size: 13px;
}
.formCont .css-1pahdxg-control .css-6j8wv5-Input {
  margin: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
  visibility: visible;
  color: hsl(0, 0%, 20%);
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: inline-grid;
  grid-area: 1/1/2/3;
  grid-template-columns: 0 min-content;
  box-sizing: border-box;
  font-size: 14px;
}

.css-6j8wv5-Input:active,
.css-6j8wv5-Input:focus,
.css-1pahdxg-control {
  outline: none;
  border: none;
}
.css-14el2xx-placeholder {
  color: #999 !important;
  grid-area: 1/1/2/3;
  margin-left: 2px;
  margin-right: 2px;
  box-sizing: border-box;
  font-size: 14px !important;
}

.formCont .css-26l3qy-menu {
  top: 100%;
  background-color: hsl(0, 0%, 100%);
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  margin-bottom: 2px;
  margin-top: 0px;
  position: absolute;
  width: 100%;
  z-index: 1;
  box-sizing: border-box;
}
.formCont .css-6j8wv5-Input {
  margin: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
}

.formCont .css-4ljt47-MenuList {
  max-height: 160px;
  overflow-y: auto;
  padding-bottom: 1px;
  padding-top: 1px;
  position: relative;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
}
.formCont .css-11unzgr {
  max-height: 160px;
}

.formCont .css-yt9ioa-option,
.formCont .css-1n7v3ny-option {
  padding: 2px 12px;
}

/*****************************Multiselect Place Holder ****************************/
.searchBox::placeholder {
  font-size: 0.95em;
}
/*-----------------multiSelectContainer input----------------*/
.multiSelectContainer input {
  margin-top: 0 !important;
  font-size: 15px;
}
.css-1d8n9bt {
  padding: 0px 8px !important;
}
.css-ackcql {
  padding: 0px !important;
}
@media (max-width: 1024px) {
  .dashboard .card-body.card-body2 {
    overflow-y: scroll;
  }
}
@media (max-width: 576px) {
  .user_serachbox {
    width: 100%;
    height: auto;
    padding: 10px 20px 5px;
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .cbtn.addBtn {
    width: 100%;
    background: #1a1a1a;
    margin-top: 10px;
  }
  [type="search"].tableSearch {
    width: 100%;
  }
  .MuiTablePagination-root .MuiToolbar-root {
    background: #f0efef;
  }
  .mainContent .css-16c50h-MuiInputBase-root-MuiTablePagination-select {
    margin-left: 0px;
    margin-right: 0px;
  }
  .mainContent
    .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar
    .MuiTablePagination-actions {
    margin-left: 0px;
  }
  .mainContent .css-zylse7-MuiButtonBase-root-MuiIconButton-root {
    padding: 2px;
  }
}

.TpgDh {
  z-index: 0 !important;
}
.formtext.multiSelect .css-319lph-ValueContainer {
  display: block !important;
  font-size: 14px;
}
.formtext.multiSelect .css-1d8n9bt {
  display: block !important;
}
.css-g1d714-ValueContainer {
  font-size: 14px;
}
.css-b62m3t-container {
  padding: 0px;
  border: none;
}
.formCont .css-1s2u09g-control {
  background: transparent !important;
}
.css-319lph-ValueContainer {
  color: #999;
}
.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
  padding-left: 0px !important;
}

.expand-mail-container {
  width: 100%;
  height: 30px;
  border: 1px solid #dadada;
  border-top: none;
}

.expand-mail {
  width: 30px;
  height: 16px;
  background-color: #eee;
  display: block;
  padding: 0px 5px;
  border-radius: 10px;
  text-align: center;
  line-height: 0;
  letter-spacing: -1px;
  font-size: 28px;
  cursor: pointer;
  margin-left: 15px;
}

.expand-mail.hide {
  display: none;
}
.expand-mail-button {
  width: 30px;
  height: 16px;
  background-color: #eee;
  display: block;
  padding: 0px 5px;
  border-radius: 10px;
  text-align: center;
  line-height: 0;
  letter-spacing: -1px;
  font-size: 28px;
  cursor: pointer;
  margin: 5px 0;
}

.trimmedContent {
  display: none;
}
.trimmedContent.show {
  display: block;
}
.mailContentBlock .jodit-container:not(.jodit_inline) {
  border-bottom: none;
}

.mailContentBlock .jodit-add-new-line:after {
  background-color: transparent;
}

.ReplyBlock .gmail_attr,
.ReplyBlock blockquote.gmail_quote {
  height: 0px;
  overflow: hidden;
}

.ReplyBlock .gmail_attr.show,
.ReplyBlock blockquote.gmail_quote.show,
.ReplyBlock .gmail_quote.show .gmail_quote {
  height: auto;
  overflow: unset;
}
.mainContent .iXRfeU,
.mainContent .drxpNH {
  max-height: unset !important;
}

.main_container .rmsc .dropdown-container {
  border-color: #aaa;
  border-radius: 2px;
  height: 28px;
}

.main_container .rmsc .dropdown-heading {
  height: 28px;
  display: flex;
  justify-content: space-between;
}
/* .main_container .rmsc .dropdown-content {
  height: 200px !important;
} */

.main_container .dropdown-heading-value,
.main_container .dropdown-search-clear-icon.gray {
  margin-top: 0;
}

.main_container .rmsc .dropdown-container .dropdown-heading-dropdown-arrow {
  margin: 0px;
  vertical-align: top;
  /* position: absolute;
  right: 0; */
}

.main_container .rmsc .dropdown-container {
  margin-top: 0px;
}
.main_container .rmsc .dropdown-container .options {
  max-height: 100px;
}
.main_container .rmsc .search input {
  height: 25px;
  padding: 0 8px;
  font-size: 12px;
}
.main_container
  .rmsc
  .dropdown-container
  .dropdown-content
  .select-item.selected {
  background: #eee;
}

.main_container .rmsc .dropdown-container .dropdown-content .select-item {
  font-size: 13px;
  padding: 2px 5px;
}
.main_container .rmsc .dropdown-heading .dropdown-heading-value {
  font-size: 14px;
}

.main_container .rmsc .dropdown-container:focus-within {
  box-shadow: #000 0 0 0 1px;
  border-color: #000;
}

.dSZDRP {
  max-height: unset !important;
}

/* .main_container .rmsc .dropdown-heading .dropdown-heading-value {
  flex: unset !important;
} */
.css-3iigni-container .css-1insrsq-control .css-319lph-ValueContainer span {
  display: inline-flex;
}
.css-b62m3t-container .css-1s2u09g-control .css-319lph-ValueContainer span {
  display: inline-flex;
}
.css-b62m3t-container .css-1pahdxg-control .css-319lph-ValueContainer span {
  display: inline-flex;
}
.css-b62m3t-container .css-1s2u09g-control span {
  display: inline-flex;
}
.css-b62m3t-container .css-1d8n9bt span {
  display: inline-flex;
}
.switch.btn-outline-success .switch-handle {
  background-color: #00a31b !important;
}
.switch.btn-outline-danger .switch-handle {
  background-color: #ff3838 !important;
}
.form-control:focus {
  box-shadow: none !important;
  border-color: #212529 !important;
}
/* .modal-body {
  min-height: auto !important;
  max-height: 60vh !important;
  overflow-y: auto !important;
}
.modal-body.scrollYRemove {
  min-height: unset !important;
  max-height: unset !important;
  overflow-y: unset !important;
} */
.fs14 {
  font-size: 14px !important;
}
.rmsc .options {
  max-height: 160px !important;
}

.react-datepicker-wrapper{
  width:100% !important;
}