.mail-subject{
    padding:5px 20px;
}
.mail-subject h4{
    font-size:16px;
}
.status-badge{
    font-size:12px;
    padding: 2px 3px;
    display: inline-block;
    margin:0px 3px;
    background-color: #ddd;
    border-radius: 3px;
    width: auto;
}
.badge-bidding{
    background-color: #aaa;
} 

.mail-body{
    padding:5px 20px;
}

.mail-item{    
    border-bottom: 1px solid #eee;
    padding-top:8px;
    min-height: 62px;
    height: auto;
}



.mail-content-header{
    width:100%;
    height: 40px;
}

.mail-header-left{
    width:75%;
    float:left;    
    cursor: pointer;
}
.mail-header-right{
    width:25%;
    float:left
}

.mailInitial{
    width:32px;
    height:32px;
    background-color: #ddd;
    font-size: 12px;
    border-radius: 50%;
    line-height: 32px;
    text-align: center;
    display: block;
    margin-right: 10px;
    float: left;
}

.mail-content-header .headerTop{
    width:100%;
    height:32px;    
  overflow: visible;
}
.mail-content-header .headerBottom{
    height: 20px;
    display: block;
    position: relative;
    top:-5px
}

.mail-content-header .content-summary{
    width: 100%;
    padding-left:30px;
    position: relative;
    overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  font-size: 13px;
  margin-bottom: 0px;
  line-height: 20px;
}

.mail-item.open .headerBottom{
    display: none;
}
.mail-item .mail-content-body{
  height:0px; overflow: hidden;
}
.mail-item.open .mail-content-body{
  height:auto; overflow: unset;
}

@media (max-width:576px){
  .mail-item.open .mail-content-body{
    overflow: auto;
    width: 100%;
  }
}

.mail-content-header h4{
    font-size:16px;
    line-height: 20px;    
    margin-bottom: 0px;
}

.mail-content-header p.toemail{
    font-size:12px;
    line-height: 1;
    margin-bottom: 0;
}
.mail-content-body{
    padding-left: 30px;
}

.mail-content-body p{
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 1.4;
}

.mail-header-right{
    text-align:right;
    
}

.attachment{
    margin:0px 10px;
    height:14px
}
.attachment img{
    height:14px
}

.mail-date{
    font-size:12px
}
.mail-header-right .dropdown{
    float: right;
    position: relative;
    display: none;
}

.mail-item.open .mail-header-right .dropdown{
    display: block;
}
.mail-header-right #dropdown-basic {
    width: 40px;
    height: 30px;
    background: transparent;
    border: none;
    margin-top: -4px;
    padding: 0px 3px;
    position: ralative;
    
}

.mail-header-right .dropdown-menu.show{
    
    padding: 0px
}
.mail-header-right .dropdown-menu.show a.dropdown-item {
    font-size: 14px;
    padding: 3px 15px;
    text-decoration: none!important;
    color:#555;
}
.mail-attachment{
    padding: 5px 5px;
    font-size: 14px;
}
.mail-attachment h4{
    font-size:14px
}
.mail-action{
    padding:5px 20px 15px
}
.mail-action-btn{
    width:auto;
    font-size: 14px;
    color: #fff !important;
    border-radius: 1px;
    height: 30px;
    line-height: 20px;
    padding: 5px 15px;
    float: left;
    margin-right: 10px;
    margin-bottom:5px;
    border: none;
    border-radius: 4px;
    background-color: black;
}

.ReplyBlock{
    max-width:100%;
    padding:20px 20px;
    border:1px solid #eee;
    border-radius:10px;
    margin:5px 20px 10px;
    position: relative;
}
span.Close{
    position: absolute;
    right:30px;
    top:auto;
    left: auto;
    bottom: 20px;
    cursor:pointer
}
.replyBlockContainer{
    display:none;
    position: relative;
}

.replyBlockContainer.show{
    display:block
}
.mailContentBlock{
    width:100%;
    padding: 0px;
   
    border: 1px solid #d9d9d9;
    border-bottom: 0px;
}

label.attachment {
    display: block;
    border: 0px dotted black;
    border-radius: 3px;
    width: auto;
    height: 30px;
    cursor: pointer;
    font-size: 14px;
    line-height: 30px;
  }  
  label.attachment:hover{
    background-color: #f3f3f3;
  }
  
  input.attachInput {
    display: none;
  }

  .attachmentContainer{
    height: auto;
  }
  

  .attachmentItem{
    width:550px;
    padding:1px 0px;
    background-color: #f8f8f8;
    border:1px solid #eee;
    margin:2px 0;
    font-size: 13px; margin-left:10px;
    display: block;
  }
  .attachmentItem .attachmentContent .fileInfo{
    display: block;
    float: left;
    width:300px;
    max-width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 0px;
    padding-left: 10px;
   
  }
  .attachmentItem .attachmentContent .progress{
    width:180px;
    display: inline-block;
    margin: 0px;
    height:13px;
    font-size: 10px;
    position: relative;
    top:2px
  }
  .success.MuiSvgIcon-root, .error.MuiSvgIcon-root{
    height:16px;
  }
  .success.MuiSvgIcon-root{
    color:green
  }
  .error.MuiSvgIcon-root{
    color:red
  }

  .deleteAttachment.MuiSvgIcon-root{
    height: 16px;
    cursor:pointer;
    display: inline;
    float: right;
  }

  .replyBlockContainer .ck.ck-editor__main>.ck-editor__editable,  .replyBlockContainer .ck.ck-toolbar.ck-toolbar_grouping{
    border:1px solid #aaa;
  }

  .replyBlockContainer .ck.ck-editor__main>.ck-editor__editable {
    min-height: 70px;
    padding-bottom: 30px;
  }
  .replyBlockContainer .ck.ck-toolbar.ck-toolbar_grouping{
    position:absolute;
    z-index: 9999;
    top:-16px;    
    right:50px;
  }
  .replyBlockContainer .ck.ck-icon{
    font-size:9px;
  } 
  .mailContentBlock .ck.ck-toolbar>.ck-toolbar__items .ck.ck-button{
    margin-top:0px;
    margin-bottom:0px;
    height:14px;
    padding: 0px;
  }

  label.formatShow{
    display: block;
    border: 0px dotted black;
    border-radius: 3px;
    width: auto;
    height: 30px;
    cursor: pointer;
    font-size: 14px;
    line-height: 30px;
  }
  .mailContentBlock.show .ck.ck-toolbar.ck-toolbar_grouping{
    display: block;
  }

  .mail-content-body table {
    caption-side: bottom;
    border-collapse:unset;
  }
  .mail-content-body {
    font-size:13px;
    line-height: 1.4;
  }


  .mail-item p.toemail{
    display: none;
    width:auto;
    max-width:90%;
    float: left;
    margin-left: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

  }
  .mail-item.open p.toemail{
    display: block;
  }

  .headerPopupContainer{
    width:40px;
    position: relative;
    float: left;
    height: 20px;
  }

  .togglePopupBtn{
    padding: 1px;
    border: 1px solid #ddd;
    background-color: #ddd;
    border-radius: 2px;
    float: left;
    margin: 0px 5px;
    display: none;
  }
  .togglePopupBtn .css-i4bv87-MuiSvgIcon-root, .togglePopupBtn .css-vubbuv{
    width:10px;
    height: 10px;
    font-size: 18px;
    display: block;
  }
  .mail-item.open .togglePopupBtn{
    display: block;
  }
  .mail-item.open .togglePopupBtn:hover{
    display: block;
    border: 1px solid #999;
    background-color: #999;
  }

  .headerPopup{
    width:400px;
    height:auto;
    padding: 6px;
    background-color: #fff;
    border: 1px solid #999;
    position: absolute;
    left: 0;
    top:12px;
    display:none
    }
    @media (max-width: 768px){
      .headerPopup{
        width:auto;
        }
    }
    @media (max-width: 620px){
      .headerPopup{
        right: 0;
        left: unset;
        }
    }
    @media (max-width: 534px){
      .headerPopup{
        right: unset;
        left: 0;
        }
    }
 .mail-item.open .headerPopup.open {
      display: block;
    }

    table.headerDetailsTable {
      width: 100%;
      font-size: 12px;
    }
    table.headerDetailsTable td:first-child{
      width:auto;
      text-align: right;
      padding: 3px;
      color:#aaa    }
    table.headerDetailsTable td:last-child{
      width:auto;
      text-align: left;
      padding:2px  3px;
      color:#555555
    }



  


 