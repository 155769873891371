.flex {
  display: flex;
}
.items-center {
  align-items: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-end {
  justify-content: end;
}
.justify-evenly {
  justify-content: space-evenly;
}
.flex-direction {
  flex-direction: column;
}
.row-reverse {
  flex-flow: row-reverse;
}
.row-gap {
  row-gap: 14px;
}
.gap4 {
  gap: 4px;
}
.h-28 {
  height: 28px;
}
.fs-16 {
  font-size: 16px !important;
}
.border-0 {
  border: 0px;
}
.clear-both {
  clear: both;
}
.hover-none:hover {
  color: #fff;
}
.btn-bg-grey {
  background-color: rgb(153, 153, 153) !important;
}
.btn-bg-grey:hover {
  background-color: rgb(153, 153, 153);
}
.thead-bg-color {
  background: #f4f4f4;
}
.pointer {
  cursor: pointer;
}
.add-quota-container {
  padding: 15px;
}
.quota-heading h4 {
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: 500;
}

.add-Quota-btn {
  padding: 7px 9px;
  background: #1a1a1a;
  border: none;
  border-radius: 4px;
  color: #ffffff;
}

.add-Quota-btn a {
  text-decoration: none;
  color: #ffffff;
}
.quota-condition-wrapper {
  background: #f2f0f0;
  padding: 10px;
}
.quota-condition-wrapper .qcw_child {
  background-color: #ffffff;
  padding: 10px;
}
.quota-condition-wrapper .qcw_child .condition_box {
  width: 100%;
  min-height: 40px;
  height: auto;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #dadada;
}
.quota_fs {
  font-size: 15px;
}
.css-1s2u09g-control,
.css-1pahdxg-control {
  min-height: 28px !important;
}
.targetAudience_wrapper {
  padding: 15px;
}
.css-9gakcf-option {
  /* background-color: #eee !important; */
  color: #555;
}
.fileUpload .btnWidth_set {
  width: auto !important;
  border-radius: 4px !important;
}

.css-1hb7zxy-IndicatorsContainer {
  height: 28px;
}
.css-tlfecz-indicatorContainer {
  padding: 0px 8px;
}
a.zipcodebtn {
  text-decoration: none;
  padding: 4px 12px;
  border-bottom: 1px solid #555;
  color: #555;
}
.h-28 .dropdown-heading {
  height: 28px;
}
.rmsc .dropdown-heading {
  height: 28px !important;
}

.btnGroup-css {
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
}
.btnGroup-css:hover {
  border-color: hsl(0, 0%, 80%);
  background-color: hsl(0, 0%, 80%);
  color: #6c757d;
}
.btnGroup-css:focus {
  box-shadow: none !important;
  border-color: #212529 !important;
}
.select__indicators.css-1wy0on6 {
  height: 28px !important;
}
.col.csmulti.option-height .options {
  height: auto !important;
}
.px-1rem {
  padding: 0 0.7rem;
}
.css-1insrsq-control {
  background-color: transparent !important;
  border: none !important;
  height: 28px !important;
  min-height: 28px !important;
  margin-top: -3px !important;
}
/* .css-319lph-ValueContainer {
  padding: 0px !important;
} */
.css-jzldcf-Input {
  margin: 0px !important;
  padding: 0px !important;
}
.css-tlfecz-indicatorContainer {
  padding: 0px !important;
}
.targetDropdownHeight .dropdown-content .panel-content {
  height: 200px !important;
}
/* .rmsc .dropdown-content .panel-content {
  height: 200px !important;
} */

.errmsgFix {
  display: block !important;
  width: 100% !important;
  position: relative !important;
  top: -3px !important;
  color: #fff !important;
  line-height: 1 !important;
  font-size: 10px !important;
  padding: 2px !important;
  background-color: #900;
}
.mb--5px {
  margin-bottom: -5px !important;
}
.confirm-alert-message {
  color: #ff0000; /* Set your desired color value */
}
.react-confirm-alert-body {
  color: #ff0000;

  font-weight: 400;
  font-size: 14px;
}
.audience-log-btn {
  padding: 7px 9px;
  background: #1a1a1a;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  width: 90%;
  margin-top: 15px;
}
